.reward{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $background;
  z-index: 999;
  .content{
    max-width: 600px;
    padding: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    text-align: center;
    margin-left: 0 !important;
    margin-top: 0 !important;
    .title{
      margin-top: 20px;
      margin-bottom: 40px;
      font: Bold 38px/47px Montserrat;
    }
    .image{
      img{
        width: 250px;

      }
    }
  }
  &.fadeIn {
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
  }
}

@keyframes fadeIn {
  0% {opacity:1;top:-100vh}
  60% {opacity:1;top:0;}
}

@-moz-keyframes fadeIn {
  0% {opacity:1;top:-100vh}
  60% {opacity:1;top:0;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:1;top:-100vh}
  60% {opacity:1;top:0;}
}

@-o-keyframes fadeIn {
  0% {opacity:1;top:-100vh}
  60% {opacity:1;top:0;}
}

@-ms-keyframes fadeIn {
  0% {opacity:1;top:-100vh}
  60% {opacity:1;top:0;}
}

.course_points{
  position: fixed;
  top: 20px;
  right: 20px;
  border-radius: 230px;
  padding: 20px;
  color:#fff;
  background: #28a745;
  z-index: 11;
  font-weight: bold;
  i{
    margin-right: 10px;
  }
  &.slideDown {
    animation: slideDownPoints 2s;
    -webkit-animation: slideDownPoints 2s;
    -moz-animation: slideDownPoints 2s;
    -o-animation: slideDownPoints 2s;
    -ms-animation: slideDownPoints 2s;
  }
}

@keyframes slideDownPoints {
  0% {opacity:1;top:-100vh}
  60% {opacity:1;top:20px;}
}

@-moz-keyframes slideDownPoints {
  0% {opacity:1;top:-100vh}
  60% {opacity:1;top:20px;}
}

@-webkit-keyframes slideDownPoints {
  0% {opacity:1;top:-100vh}
  60% {opacity:1;top:20px;}
}

@-o-keyframes slideDownPoints {
  0% {opacity:1;top:-100vh}
  60% {opacity:1;top:20px;}
}

@-ms-keyframes slideDownPoints {
  0% {opacity:1;top:-100vh}
  60% {opacity:1;top:20px;}
}

@media (max-width:$breakpoint) {
  .reward{
    padding-left: 40px;
    .content{
      padding-top: 0 !important;
      .title{
        font-size:26px !important;
        margin-bottom: 20px;
      }
      .image{
        img{
          width: 175px;
        }
      }
    }
  }
}
