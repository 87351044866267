.theme_2 .leftBottom{
  margin-top: 200px;
  padding: 0 0 20px 40px;
  margin-left: auto;
  margin-right: 0;
  max-width:300px;
  position: relative;
  z-index: 1;
  font: 300 16px/22px Montserrat;
  .logo{
    width: 180px;
    margin-top: 40px;
    margin-bottom: 20px;
    margin-right: 20px;
    display: block;
  }
  div{
    display: inline-block;
    margin-right: 20px;
    &.name{
      font-weight: bold;
    }
  }
  .submenu{
    margin-top: 20px;
    a{
      display: block;
      color: $title_color;
    }
  }
  .profile{
    margin-bottom: 40px;
    margin-top: 40px;
    height: 80px;
    border-radius: 40px;
    box-shadow: -22px 0 33px 0 rgba(161, 189, 202, 0.36);
    background-color: #ffffff;
    padding: 5px 10px;
    img{
      width:75px;
      border-radius: 40px;
    }
    .name{
      font-size: 16px;
      font-weight: bold;
    }
  }
}
