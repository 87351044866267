.theme_2 .custom{
  .stress{
    h2{
      margin-bottom:30px
    }
    .choose{
      img{
        width: 50px;
        margin: 0 20px 40px;
        cursor: pointer;
      }
    }
    #felt{
      padding-left: 30px;
      img{
        width: 50px;
        position: relative;
        top: -12px;
      }
    }
    .btn{
      margin-top: -27px;
    }
  }
}
