.customalert.confirm{
  position: fixed;
  top: 50%;
  left: 50%;
  display: block;
  background: #fff;
  -webkit-box-shadow: 0px 3px 37px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 37px -4px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 37px -4px rgba(0,0,0,0.75);
  z-index: 1000;
  padding: 40px;
  width:500px;
  max-width: 100%;
  -webkit-transform: translate(-50%, -50%);
     transform: translate(-50%, -50%);
     h4{
       font-size: 21px;
     }
  &.hide{
    display: none;
  }
  .text{
    margin-bottom: 40px;
  }
  table{
    margin: 0 auto;
    width:auto !important;
  }
  .align_center{
    text-align: center;
  }
}
