input{
	&.no_bg{
		background: none;
		padding: 0 !important;
		border: none;
	}
}
input[type="checkbox"],
input[type="radio"]
{
  opacity:0;
  filter: alpha(opacity=0);
  position: absolute;
}
input[type="checkbox"] + label,
input[type="radio"] + label
{
	margin-bottom:5px;
	display: inline-block;
	margin-left: 33px !important;
	position:relative;
	top:-2px;
	padding-right: 10px;
  max-width: initial !important;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before
{
	 content: '';
    display:inline-block;
    width:20px;
    height:20px;
    background:none;
    border:1px solid #212529;
    margin-right:9px;
    position:absolute;
		margin-left: -33px;
		top:2px;
}
input[type="radio"] + label::after,
input[type="radio"] + label:before
{
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
}
input[type="checkbox"]:checked + label:before,
input[type="radio"]:checked + label:before
{
	content: '';
	font-size:20px;
	line-height:0.85;
	background: #212529;
	border-color: #212529;
}
label{
	cursor: pointer;

}
input[type="radio"]:checked + label::after,
input[type="checkbox"]:checked + label::after {
	opacity: 1;
	-webkit-transform: rotate(0) scale(1);
	-ms-transform: rotate(0) scale(1);
	transform: rotate(0) scale(1);
}
input[type="checkbox"]:checked + label:before,
input[type="radio"]:checked + label:before{
  background:#f9f9f9;
}

input[type="radio"] + label::after,
input[type="checkbox"] + label::after {
	top: 1px;
	left: -33px;
	visibility: visible;
	width: 20px;
	height: 10px;
	border-bottom: 20px solid #212529;
	opacity: 0;
	transition: all 0.4s;
	-webkit-transform: rotate(-45deg) scale(0);
	-ms-transform: rotate(-45deg) scale(0);
	transform: rotate(-45deg) scale(0);
	pointer-events: none;
	content: '';
	position: absolute;
}
input[type="radio"] + label::after{
  border-bottom: none;
  background: #212529;
  height: 20px;
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
	top: 22px;
  margin-left: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider_switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider_switch:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider_switch {
  background-color: #5dc2f5;
}

input:focus + .slider_switch {
  box-shadow: 0 0 1px #5dc2f5;
}

input:checked + .slider_switch:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider_switch.round {
  border-radius: 34px;
}

.slider_switch.round:before {
  border-radius: 50%;
}

/* RANGE SLIDER */
input[type=range] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  /*box-shadow: 0px 10px 10px rgba(0,0,0,0.25);*/
  background: #FFC200;
  border-radius: 1.3px;

}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 10px 10px rgba(0,0,0,0.25);
	border:none;
  height: 30px;
  width: 20px;
  border-radius: 20px;
  background: #FFC200;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #FFC200;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  /*box-shadow: 0px 10px 10px rgba(0,0,0,0.25);*/
  background: #FFC200;
  border-radius: 1.3px;

}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 10px 10px rgba(0,0,0,0.25);
	border:none;
  height: 30px;
  width: 20px;
  border-radius: 20px;
  background: #FFC200;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #FFC200;

  border-radius: 2.6px;
  /*box-shadow: 0px 10px 10px rgba(0,0,0,0.25);*/
}
input[type=range]::-ms-fill-upper {
  background: #FFC200;

  border-radius: 2.6px;
  /*box-shadow: 0px 10px 10px rgba(0,0,0,0.25);*/
}
input[type=range]::-ms-thumb {
  box-shadow: 0px 10px 10px rgba(0,0,0,0.25);
	border:none;
  height: 30px;
  width: 20px;
  border-radius: 20px;
  background: #FFC200;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #FFC200;
}
input[type=range]:focus::-ms-fill-upper {
  background: #FFC200;
}
/* .empty .rs-label{
	margin-left: -38px;
} */
.rs-label {
    position: relative;
    transform-origin: center center;
    display: block;
    width: 50px;
    height: 50px;
    background: transparent;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    padding-top: 7px;
    box-sizing: border-box;
    border: 2px solid #FFC200;
    margin-top: 20px;
    margin-left: -14px;
    left: attr(value);
    color: #FFC200;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 28px;
		/*
    &::after {
        content: "kg";
        display: block;
        font-size: 20px;
        letter-spacing: 0.07em;
        margin-top: -2px;
    }
		*/

}
