
.coachInterface{
  background: #f5f5f5;
  min-height: calc(100vh - 85px);
  .emails{
    td{
      border-top: 0;
    }
  }
  &.openSearch{
    .search{
      display: block !important;
    }
  }
  .pagination{
    margin-top:20px;
    margin-bottom: 0;
    text-align: center;
    display: block;
    .end,
    .start{
      position: relative;
      margin-right: 10px;
      margin-left: 10px;

      i{
        margin-right: -3px !important;
        margin-left: 0;
      }
    }
    i{
      cursor: pointer;
      margin-left: 10px;
      opacity: 0.9;
      font-size: 12px;
      position: relative;
      top: -1px;
      &.fa-chevron-left{
        margin-right: 10px;
        margin-left: 0;
      }
    }
    span:not(.showing_dots){
      cursor: pointer;
    }
    span.showing_dots{
      position: relative;
      top: -3px;
      display: inline-block;
      font-size: 20px;
      margin: 0 10px;
      display: none;
    }
    .pagination_go_to {
      margin: 0 5px;
      display: inline-block;
      &:hover{
        span{
          text-decoration: underline;
        }
      }
      &.active{
        font-weight: bold;
      }
    }
  }
  &.admin{
    .listVisible td{
      &.details .student_details{
        margin-top: 0;
      }
      &.left{
        padding-top: 40px;
      }
    }
  }
  &.coaches tr.active{
    background: #f6de7c;
  }
  .sleep-diary{
    .menu{
      text-align: left;
      margin-top: 20px;
      margin-bottom: 40px;
      span{
        padding: 10px 20px 12px 10px;
        border-radius: 20px;
        cursor: pointer;
        margin-right: 10px;
        i{
          position: relative;
          top: 3px;
        }
        &:hover,
        &.active{
          background: #fcbe59;
          color: #fff;
        }
      }
    }
    .this_week {
      .nights{
        h1{
          text-align: left;
          font:Bold 24px/28px Montserrat !important;
          margin-bottom: 0 !important;
        }
        .items{
          max-width: 900px;
          .item{
            .night{
              font-weight: bold;
            }
          }
        }
      }

    }
    .box{
      box-shadow: none !important;
      border: 1px solid #eaeaea;
      border-radius: 0 !important;
      max-width: 900px;
      .box{
        border:none;
      }
      &.bar{
        margin-right: 40px;
        margin-bottom: 40px;
        max-width: 900px;
      }
    }
    .weeks {
      h1{
        display: none;
      }
      .no_nights{
        margin-top: 20px;
        text-align: left;
      }
      .week{
        table {
          b,.number{
            font-weight: 500 !important;
          }
          table{
            width:auto;
            float: right;
          }
        }
        h2{
          font-weight: 500 !important;
        }
      }
      .line_chart {
        box-shadow: none;
        padding: 0;
        margin-bottom: 50px;
        .holderGraph{
          max-width: initial;
          margin:60px 0;
        }
        h2{
          display: none;
          text-align: left;
          font-weight: normal !important;
        }
      }
    }
  }
  .matrix{
    th{
      background: #fcbe59;
      color:#000;
      font-weight: 300;
      font-size: 14px;
    }
    tr:last-child{
      td{
        border-bottom: 0;
      }
    }
    .phone{
      display: none !important;
    }
    td{
      border-bottom: 1px solid #CCCCCC;
      padding: 10px 0 10px 10px;
      td{
        padding: 0;
        border:none;
        input[type="checkbox"] + label, input[type="radio"] + label{
          top: -13px;
        }
      }
    }
  }
  &.registrations{
    .download{
      width: auto !important;
    }
    td{
      &:nth-child(1){
        width: 40px !important;
      }
    }
    tr.open{
      background: #eeeeee;
    }
    tr.registrationDetails{
      background: #eeeeee;
    }
  }
  .registrationDetails{
    td{
      padding: 20px 55px !important;
      table{
        width: 400px !important;
        td{
          border:none !important;
          padding: 10px !important;
          &:nth-child(1){
            width: 275px !important;
          }
        }
      }
    }
  }
  .empty{
    h3{
      margin-bottom: 20px;
    }
  }
  .registration_data{
    table{
      width: 100%;
      td.no_border{
        border:none !important;
        padding-top: 35px;
      }
      td.right{
        background: #f9f9f9;
        vertical-align: top;
        td{
          border-bottom: 1px solid #fff;
        }
        padding-top: 0;
        width: 52%;
        padding-right: 20px;
        padding-left: 20px;
      }
      td.left{
        vertical-align: top;

        padding-top: 0;
        width: 48%;
      }
      td td{
        border-bottom:1px solid #f5f5f5;
        padding: 10px 0;
        &:nth-child(1){
          font-weight: bold;
          padding-right: 20px;
        }
        &:nth-child(2){

        }
      }
    }
  }
  &.admin{
    .list{
      margin-top: 40px;
    }
  }
  .studentDetailsInfoPersonal{
    .image{
      margin-top: -20px;
      margin-bottom: 20px;
      width: 150px;
      height: 150px;
      background-size: cover !important;
      border-radius: 150px;
    }
  }
  .alert{
    position: fixed;
    top: 100px;
    right: -100%;
    min-width: 300px;

    z-index: 12;
    transition: all 0.5s;
    &.alert-danger,
    &.alert-success{
      right: 0;
      transition: all 0.5s;
    }
  }
  .theIntervention{
    h2{
      display: inline-block;
      margin-right: 20px;
    }
    .filter{
      position: relative;
      top: -3px;
    }
  }
  &.openSearch{

    .theIntervention{
        .search{
          margin-top: 0;

          transition: all 0.5s;
          opacity: 1;
          input{

          }
        }
        .options{
          left: 24% !important;
          right: auto !important;
          .fa-search,
          .btn{
            display: none;
          }
        }
      }
    /*
    .listVisible{
      td.left{
        width:35%;
        .users{
          width:35%;
          td{
            border-bottom: 1px solid #eaeaea;
          }
          tr.active{
            background: none;
            left: 0;
            box-shadow: none;
          }
        }
      }
      td.right{
        width:65%;
      }
      .student_details{
        opacity: 0.1 !important;
        .filterd{
          width:35%;
          transition: all 0.5s;
        }
      }

      .theIntervention{
        .search{
          margin-top: -80px;
          opacity: 1;
        }
      }
    }
    */

  }
  .student_details{
    h2{
      margin-bottom: 40px;
    }
    .filterd{
      padding-left: 20px;
      font-size: 22px;
      width:22%;
      transition: all 0.5s;
    }
    .no_nights{
      text-align: left;
    }
    .navbarHolder{
      /* border-top:50px solid #f5f5f5; */
    }
  }

  .navbar{
    /* background: #fff; */
    /* position: fixed; */
    height: 85px;
    width:100%;
    z-index: 11;
    top: 0;
    h2{
      font-size: 20px;
      padding-left: 25px;
      color: #505050;
    }
    .select{
      cursor: pointer;
      margin-right: 10px;
    }
    select{
      margin-right: 10px;
      background: none;
      border:none;
      font-size: 20px;
      padding:0;
      color: rgba(0,0,0,0);
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
      position: absolute;
      outline: 0 !important;
    option{
      color:#505050;
    }
      &:hover,
      &:active,
      &:focus{
        border:0 !important;
        outline: 0 !important;
      }
    }
  }
  .list{
    padding: 40px 40px 0;
  }
  .theIntervention{
    margin-bottom: 20px;
    width:100%;
    margin-left:0;
    position: relative;
    .search{
      margin-top: -40px;
      position: absolute;
      top: 3px;
      right: 0;
      transition: all 0.5s;
      opacity: 0;
      background: #f5f5f5;
      display: none;
      input{
        margin-right: 10px;
        padding: 10px;
        width:400px;
        max-width: 400px;

      }
      i{
        font-size: 20px;
      }

    }
    .options{
      text-align: right;
      .btn{
        padding-left: 14px;
        padding-right: 14px;
        width:41px;
        height:41px;
        i{
          margin-left: 0;
        }
      }
      .fa-search{
        margin-left: 20px;
        top: 3px;
        position: relative;
      }
    }
  }
  .holder{
    width:100%;

    td.left,
    td.details{
      vertical-align: top;
    }
    td.details{
      .navbarHolder{
        top: 0;
        /* position: fixed; */
        transition: all 0.5s;
      }
      .student_details{
        opacity: 0;
        width:0;
      }
    }

    .users{
      & > div:nth-child(1) > div:nth-child(1){
        overflow-x: hidden !important;
      }
      table{
        width:100%;
        margin-bottom: 20px;
        thead{
          border-bottom:1px solid #eaeaea;
        }
      }
      width:100%;

      tr{
        transition: all 0.5s;
        position: relative;
        left: 0;
        &:last-child{
          td{
            border-bottom: 0;
          }
        }

        &.details {
          .saved{
            opacity: 0.5;
            pointer-events: none;
          }
          .talen {
            label{
              font-size: 1em;
              color:#000;
            }
          }

          .role {
            padding-top: 10px;
            padding-bottom: 10px;
            .form-check{
                padding-left: 0;
            }
            label{
              font-size: 1em;
              color:#000;
            }
            .error{
              margin-bottom: 20px;
            }
          }
          .success{
            text-align: center;
            padding: 10px 20px;
            margin-bottom: 20px;
            margin-top: -10px;
            color:#fff;
            background:#28a745;
            i{
              margin-left: 10px;
              color:#fff;
            }
          }
          label{
            font-weight: normal !important;
          }
          .marge_top{
            margin-top: 30px;
          }
          .set_new_password{
            display: flex;


          }
          .error{
            margin-top: 10px;
            background: $red;
            color:#fff;
            padding: 10px;

          }
          .align_right{
            text-align: right;
          }
          td{
            padding: 0;
            .content{
              margin-bottom: 20px;
              background: #fff;
              /*border-left: 8px solid #f6de7c;;*/
              padding: 40px 40px 40px;
              .students{
                margin-bottom: 30px;

                th{
                  cursor:pointer;
                }
                td:last-child{
                  width:150px;
                }
                .betaald{
                  // /*background: #28a745;*
                  color:#28a745;
                  border-radius: 20px;
                  cursor: pointer;
                  &.niet{
                    color: $red;
                  }
                }
                tr{
                  td{
                    padding: 10px;
                  }
                  &:nth-child(odd){
                    td{
                      background: #eaeaea;

                    }
                  }
                }
              }
              .flex{
                display: flex;
                .right{
                  padding-left: 70px;
                  width:calc(100% - 170px);
                }
              }
              .close{
                cursor: pointer;
              }
              .image{
                width:150px;
                height:150px;
                background: #eaeaea;
                background-size: cover !important;
                border-radius: 500px;
                margin-bottom: 40px;
                position: relative;
                &:hover{
                  .options{
                    opacity: 1;
                  }
                }
                input[type="file"] {
                  display: none;
                }
                .options{
                  top: 50%;
                  left: 50%;
                  position: absolute;
                  opacity: 0;
                  transform: translate(-50%, -50%);
                  width: 100px;
                  text-align: center;
                  span{
                    margin: 0 5px;
                  }
                }
              }
              .tabs{
                display: flex;
                margin-bottom: 20px;
                .tab{
                  border-bottom:1px solid #fff;
                  padding: 5px 20px;
                  cursor: pointer;
                  &.active{
                    border-bottom:1px solid #000;
                  }
                }

              }
              .tabContent{
                padding: 30px;
                background: #f9f9f9;
                .red{
                  i{
                    position: relative;
                    top: 2px;
                  }
                }
                .students_holder{
                  .fa-file-download{
                    float: right;
                    position: relative;
                    top: -31px;
                    font-size: 20px;
                    margin-right: 6px;
                    cursor: pointer;
                  }
                }
                .registration_data{
                  table{
                    margin-bottom: 0;
                    td{
                      vertical-align: top;
                      padding: 5px 0;
                      width:50%;
                      border-bottom: 0;
                      &.align_right{
                        text-align: right;
                        padding-top: 20px;
                      }
                      input{
                        margin-bottom: 10px;
                      }
                      &:nth-child(2){
                        padding-left: 20px;
                      }
                      &:nth-child(1){
                        font-weight: bold;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      th,
      td{
        padding: 15px;
        border-bottom:1px solid #eaeaea;
        &.userActions{
          i{
            font-size: 22px;
            margin-left: 10px;
          }
        }
      }
    }
    td.left{
      .theIntervention{
        margin-bottom: 20px;
        width:100%;

      }
    }
  }
  .listVisible{
    .theIntervention{
      h2{
        font-size: 20px;
        display: none;
      }
      .filter{
        position: fixed;
        top: 144px;
        left: 49px;
        z-index: 11;
        background: #f5f5f5;
        color:#545b62;
        &:hover{
          background: #545b62;
          color:#fff;
        }
        i{
          position: relative;
          top: 1px;
        }
      }
      .options{
        position: fixed;
        right: 64px;
        top: 121px;
        z-index: 11;

      }
      .search{
        margin-top: -120px;
        position: fixed;
        top: 42%;
        left: 40%;
        right: auto;
        transition: all 0.1s;
        opacity: 0;
      }
    }
    td.left{
      width:22%;
      transition: all 0.5s;
      padding-top: 123px;
      .coachName,
      .userActions{
        display: none;
      }
      .users{
        border:none;
        position: fixed;
        width: calc(20% + 14px);
        td{
          border:none;
        }
        tr{
          &.active{
            background: #fff;
            box-shadow: -9px 0px 8px rgba(0, 0, 0, 0.03);
            transition: all 0.5s;
            transition: all 0.5s;
            font-weight: bold;
          }
          td:nth-child(2){
            padding-right: 20px;
          }
        }
      }
    }
    .detailsTable {
        width: 100%;
    }
    td.details{

      width:100%;
      transition: all 0.5s;
      .student_details{
        /* background: #fff; */
        margin-top: 10px;
        opacity: 1;
        width:100%;
        min-height: calc(100vh - 230px);
        /*box-shadow: 6px 0px 22px rgba(0,0,0,0.1);*/
        /* padding: 50px 30px 40px 50px; */
        h4{
          margin-bottom: 40px;
        }
        .fields{
          margin-top: 20px;
          .field{
            padding: 5px 0;
            &.name{
              font-size: 26px;
            }
            a{
              color:#212529;
            }
            i{
              border:1px solid #545b62;
              color:#545b62;
              padding: 10px;
              border-radius: 40px;
              margin-right: 10px;
            }
          }
        }
        .navbarHolder{
          /* position: relative; */
          margin-bottom: 20px;
          /* top: 48px; */
          width:100%;
          left: 0;
          /* padding: 0 40px; */
          background: none;
          z-index: 10;
          transition: all 0.5s;
          table{
            width:100%;
            td:nth-child(2){
              /* background: #f6f6f6; */
            }
          }
          .navbar{
            position: relative;
            /* padding-left: 40px; */
            /* background: #f6f6f6; */
            width:calc(100% - 50px);
          }
          .nav-item{
            padding:10px 20px;
            cursor: pointer;
          }
          .active{
            background: #fff;
            border-radius: 20px;

          }
          .close {
            float: right;
            margin-top: 50px;
            /* padding-right: 6px; */
            i {
                font-size: 1.2em;
                margin-right: 6px;
            }
          }
        }
        .studentDetailsDynamicContent {
            padding: 50px 30px 40px 50px;
            background-color: #fff;
            /* margin-top: -20px; */
        }
      }
    }
  }
  .listHidden{
    max-width: 1200px;
    margin: 80px auto 80px;
    td.active{
      background: #28a745;
      color:#fff;
    }
    td.included{
      background: #46c563;
      color:#fff;
    }
    td.left{
      width:100%;
      transition: all 0.5s;
      .userActions{
        text-align: right;
      }
    }
    .details{
      width:0;
      transition: all 0.5s;
    }
  }

  .select-switch-holder{
    /* position: fixed; */
    margin-top: -48px;
    width: 100%;

    z-index: 100;
    .anotherHolder{
      padding: 40px 0 15px;
      background: #fff;
      width: calc(73% - 88px);
    }
    select{
      width: 100%;
    }
  }
  .the_content_holder{
    padding-top:10px;
    .part .center{
      margin: 0;
    }
    .item{
      border-bottom:1px solid #f6f6f6;
      margin-bottom: 10px;
      .heading{
        cursor: pointer;
        i{
          float: right;
          margin-right: 20px;
          margin-top: 12px;
          font-size: 22px;
          transform: rotate(0);
          transition: all 0.5s;
        }
      }
      &.hideContent {
        .heading{
          h5{
            background: none;
            color: #212529;
            font-size: 16px;
            transition: all 0.5s;
          }
          i{
            transform: rotate(180deg);
            transition: all 0.5s;
          }
        }
        .the_content{
          display: none;
        }
        h5{
          margin-bottom: 10px;
        }
      }
    }
    h5{
      background: #fcbe59;
      color: #fff;
      display: inline-block;
      padding: 10px 20px;
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 300;
      transition: all 0.5s;
      margin-bottom: 20px;
    }
    .the_content{
      border:none;
      margin-bottom: 20px;
      .part{
        margin-bottom: 20px;
        background: #f5f5f5;
        padding: 20px;
        &:last-child{
          margin-bottom: 0;
        }
        input[type="range"]{
          background: none;
        }
        .min_max{
          width: 100%;
          td:nth-child(2){
            text-align: right;
          }
        }

        input[type="radio"] + label::after, input[type="checkbox"] + label::after {
          top: 3px;
          border-color:#fcbe59;
          background:#fcbe59;
        }
      }
      .question{
        font-weight: bold;
        margin-bottom: 10px;
      }
      select:disabled{
        color:#212529;
        border:none;
        background: none;
        padding: 0;
        margin-top: 5px;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        margin-left: -5px;
        option{
          padding: 0;
        }
      }
      input[type="text"]:disabled {
        color:#212529;
        border:none;
        background: none;
        padding: 0;
      }

      .list{
        padding: 0;
        ul{
          padding-left: 0;
          margin-bottom:0;
          li{
            div{
              display: inline-block;
            }
            input.other{
              margin-left: -16px;
            }
          }
          label{
            font-size: 16px;
            color:#212529;
          }
        }
      }
      .center{
        padding: 0;

      }
    }
  }
  #user_details_list:hover{
    .youCanScroll{
      display: none;
    }
  }
  .youCanScroll{
    position: fixed;
    bottom:20px;
    left: 20%;
    font-size: 20px;
    padding: 6px 11px;
    background: #eaeaea;
    &.down{
      transform:rotate(180deg);
    }
  }

  .filter {
    background-color: #efefef;
    padding: 10px;
    margin-bottom: 10px;
  }
}
