.pagination {
    .results {
        text-align: left;
    }
    li {
        display: inline-block;
        margin-right: 5px;
        a {
            color: $dark_bleu;
            padding: 5px 10px;
            border: 1px solid $dark_bleu;
            text-decoration: none;
            &:hover {
                background-color: $dark_bleu;
                color: white;
            }
        }
    }
    margin-bottom: 5px !important;
    display: inline-block !important;
    .paginatingButtons {
        display: inline-block !important;
    }
    .pagingLength {
        display: inline-block !important;
        width: auto !important;
    }
}