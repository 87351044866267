.modal-body {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  padding: 20px !important;

  .image_holder {
    position: relative;
    background: #eaeaea;
    border-radius: 250px;
    width: 100px;
    height: 100px;
    background-size: cover !important;

    input[type='file'] {
      display: none;
    }

    .options {
      top: 50%;
      left: 50%;
      position: absolute;
      opacity: 0;
      transform: translate(-50%, -50%);

      .btn {
        width: 30px; 
        height: 30px;
        padding-top: 0px;
        margin-bottom: 5px;
        i {
          font-size: 13px;
          margin-left: -1px;
        }
      }
    }
    &:hover {
      /*background-image: none !important;*/
      .options{
        opacity: 1;
      }
    }
    

  }

}

