$black:#000;
$light_bleu:#4fbdf4;
$text_color: $black;
$dark_bleu: #253761;
$orange: #FFC200;
$red: #FFC200;
$background: #FFE7D2;

$title_color: $black;
$max-width:688px;
