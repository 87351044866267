.dashboard{
  .lessoncontent.front .right{
    width:100%;
    padding: 0;
    .center{
      padding: 0;
    }
    .content{
      padding-left: 0;
      width:100%;
      margin-top: 0;
    }
  }
}
