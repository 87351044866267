.base_theme .dashboard .holder .right{
  .points{
    margin-top: 40px;
  }
  .badges{
    h1{
      text-align: center;
    }
    .intro{
      display: flex;
      margin-bottom: 80px;
      .b_l{
        width:70%;
        font-size: 26px;
        padding-right: 40px;
        line-height: 1.5;
      }
      .b_r{
        width:30%;
        text-align: center;
        .b_t{
          span{
            font-size: 50px;
            font-weight: bold;
            margin:0 5px;
          }
          margin-bottom: 5px;
          margin-top: 20px;
        }
      }
    }
    .items{
      .badge{
        text-align: center;
        display: inline-block;
        width: 150px;
        height: 230px;
        margin: 5px;
        padding: 5px 5px 0;
        vertical-align:top;
        position: relative;
        font-size: 18px;
        font-weight: normal;
        white-space:initial;
        word-break: break-word;
        &:not(.finished){
          img{
            filter: grayscale(1);
            opacity: 0.3;
          }
        }
        img{
          display: block;
          margin: 20px auto;
          max-width: 80%;
        }
      }
    }
  }
}
@media (max-width:$breakpoint) {
  .base_theme .dashboard .holder .right {
    .badges{
      .intro {
        margin-bottom: 40px;
        .b_r {
          .b_t {
            margin-top: 10px;
            span{
              font-size: 30px;
            }
          }
        }
        .b_l{
          font-size: 18px;
        }
      }
      .items{
        background: #fff;
        border-radius: 10px;
        padding-bottom: 40px;
        padding-top: 10px;
        .badge{
          width:33%;
          margin:0;
          height: auto;
          img{
            max-width: 70%;
          }
          .title{
            font-size: 14px;
          }
        }
      }
    }
  }
}
