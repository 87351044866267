.coach_overview_table {
   th {
      writing-mode: vertical-lr;
      text-align: right;
      border-right: 1px solid;
   }
   td {
      border-right: 1px solid;
   }
   tfoot {
      th {
         text-align: left;
      }
   }
}
