.student_details {
  .journal{
    h2{
      margin-bottom: 40px;
      font-size: 24px;
    }
    form{
      h2{
        margin-bottom: 20px;
      }
      [contenteditable]:empty:before {
        display: block; /* For Firefox */
      }
      .textarea{
        min-height: 150px;
        margin-bottom: 20px;
      }
      table{
        width: 100%;
        td:nth-child(2){
          text-align: right;
        }
      }
    }
    .journal_items{
      .items{
        .item{
          background: #e8eff2 0 0 no-repeat padding-box;
          border-radius: 10px;
          padding: 20px;
          margin: 0 0 20px;
          cursor: pointer;
          table{
            margin-bottom: 20px;
            td:nth-child(1){
              padding-right: 60px;
            }
            i{
              margin-right: 10px;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}