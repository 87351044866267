.ssi-profile {
   strong {
      display: block;
      margin-bottom: 10px;
   }
   .ssi-profile-lesson {
      margin-left: 10px;
   }
   .ssi-profile-label {
      color: rgba(0,0,0,0.8);
      font-size: 16px;
   }
   .btn-ssi-select {
      border-color: #ccc;
   }
   @media (max-width:492px) {
      .btn-ssi-select {
         margin-bottom: 10px;
      }
   }
}

.item_alt_ssi_pp {
   width: 100% !important;
   max-width: 480px !important;
   .title {
      /* width: 100% !important; */
   }
}
