body{
  background: #fff !important;

}
.menuIsVisible{
  #nav-icon{
    display: block;
  }
}
.base_theme{ //// basetheme class nog graag naar body misschien maar via js
  color:$title_color !important;
  font: normal 18px/26px Europa;
  h1{
    font-weight: bold;
  }
  .finished .Typewriter__cursor{
    display: none !important;
  }
  h1.big{
    font: Bold 38px/47px Montserrat !important;
  }
  b, strong {
      font-weight: 700 !important;
      color: $title_color !important;
  }
  #menu_left{
    background: #fff !important;
  }
  #nav-icon{
    right: -200px !important;
  }
  #menu_left,
  .menu_left_inner{
    width: 400px !important;
  }
  a {
    color: rgba(0, 0, 0, 0.8);
    text-decoration: underline;
  }

}
@media (max-width:$breakpoint) {
  body.menuIsVisible{
    .overlay{
      z-index: 2;
    }
  }
  .base_theme{
    h1.big{
      font: Bold 28px/32px Montserrat !important;
    }
    #menu_left,
    .menu_left_inner{
      width: 80% !important;
    }
    .form-control{
      font-size: 16px !important;
    }
    #loginContainer
    {
      box-shadow:none !important;
    }
  }

  
}

.modal-footer {
    .btn-secondary {
        border-radius: 60px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        background-color: #fff;        
        color: #CCC;
        font-family: Europa;
        font-size: 17px;
        font-style: bold;
        line-height: 24px;
    }
    .btn-secondary:hover {
        background-color: #fff;
        color: #CCC;
        border: 1px solid rgba(0, 0, 0, 0.20);
    }
    .btn-primary {
        border-radius: 60px;
        border: 1px solid #000;
        background-color: #000;        
        color: #fff;
        font-family: Europa;
        font-size: 17px;
        font-style: bold;
        line-height: 24px;
    }
    .btn-secondary:hover {
        background-color: #000;
        color: #fff;
        border: 1px solid #000;
    }
  }
