.sleep-diary{
  .lessoncontent.front .right{
    margin-left: 0;
    min-height: auto;
  }
  .extra_text{
    width: 91%;
    margin: 40px auto;
    text-align: justify;
    ul{
      padding-left: 1em;
    }
  }
  .info{
    .component{
      margin-bottom: 30px;
      .text.koptekst{
        font: Bold 24px/28px Montserrat !important;
      }
    }
  }
  .introduction{
    text-align: right;
    width: 91%;
    margin: 140px auto 0;
    h1 {
      text-align: left;
    }
    .text{
      margin: 40px auto;
      text-align: justify;
    }
  }
  .min_max{
    font-style: italic;
  }
  h1{
    font:Bold 34px/42px Montserrat !important;
  }
  .menu_tooltip{
   color: #253761 !important;
   background-color: $background !important;
   font-weight: 600;
   opacity: 1;
     &.place-top {
     &:after {
       border-top-color: $background !important;
       border-top-style: solid !important;
       border-top-width: 6px !important;
     }
     }
  }
  .legend{
    text-align: center;
    margin-bottom: 40px;
    margin-top: -20px;
  }
  .step_1 .question.box{
    table{
      margin: 0 auto;
    }
  }
  .step_5{
    &.question{
      div{
        margin-top: 20px;
        label{
          font-size: initial;
          color:initial;
          margin-bottom: 0;
          top: 0;
        }
      }
    }
  }
  .choose_date{
    width:100%;
    i{
      cursor: pointer;
      font-size: 24px;
      color:$background;
    }
    td:nth-child(1){
      padding-right: 40px;
    }
    td:nth-child(3){
      padding-left: 40px;
      text-align: right;
    }
  }
  .time_select{
    padding: 0;
    margin-left: 0 !important;
    div{
      /*padding: 0 !important;*/
      margin: 0 !important;
      &[class$="-ValueContainer"]{
        padding: 10px !important;
      }
    }
    input[type="text"]{
      border:none !important;
    }
  }
  .middle{
    background: $background;
  }
  .high{
    background: #cff6f2;
  }
  .low{
    background: #F7A0A4;
  }
  .weeks{
    .intro{
      margin-bottom: 40px;
      text-align: center;
    }
    /*
    .in_a_row{
      .box{
        padding-left: 0px;
        margin-right: 10px;
      }
      td{
        vertical-align: top;
        h2{
          font-size: 20px;
        }
        &:nth-child(2){
          .box{
            margin-right: 0;
            margin-left: 10px;
          }
        }
      }
    }
    */
    .box.bar{
      h2{
        margin-bottom: 10px;
        text-align: center;
      }
    }
    .line_chart{
      .holderGraph{
        margin-bottom: 60px;
      }
      h2{
        margin-bottom: 40px;
        text-align: center;
      }
      .legend{
        text-align: center;
        margin-bottom: 0;
        .sleep,.awake{
          display: inline-block;
          margin: 0 5px;
          .colorBox{
            width: 26px;
            height: 22px;
            background: #FDBD59;
            margin: 0 10px;
            border-radius: 5px;
            display: inline-block;
          }
          .text{
            position: relative;
            top: -6px;
          }
        }
        .awake .colorBox{
          background: #253761;
        }
      }
    }
    .week{
      .nr_nights{
        background: $background;
        height: 69px;
        width: 69px;
        display: inline-block;
        line-height: 69px;
        text-align: center;
        border-radius: 69px;
        margin-left: 20px;
        font-size: 22px;
        i{
          margin-right: 5px;
        }
      }
      .top_table{
        td:nth-child(2){
          text-align: right;
          table{
            width: auto;
            float: right;
          }
        }
      }
      .awakeTime{
        height: 40px;
        margin-top: 10px;
        background: #253761;
      }
      .sleepTime{
        height: 40px;
        margin-top: 10px;
        background: #FDBD59;
      }
      .box{
        box-shadow: none !important;
        padding:0 !important;
        margin-bottom: 0 !important;
        margin-top: 40px !important;
        padding-top: 40px !important;
        border-top: 1px solid !important;
        border-radius: 0 !important;
        h2{
          font-size: 20px !important;
          margin-bottom: 0 !important;
        }
        .holderGraph{
          margin-top: 20px;

        }
      }
      .fa-chevron-up{
        transition: all 0.5s;
        cursor: pointer;
        &.open{
          transition: all 0.5s;
          transform:rotate(180deg);
        }
      }
      .nights{
        .night{
          margin-top: 40px;
          padding-top: 40px;
          border-top:1px solid;

        }
      }
      table{
        width:100%;
        .number{
          font-size: 20px;
          margin-bottom: 10px;
          font-weight: bold;
        }
        span{
          height: 69px;
          width: 69px;
          display: inline-block;
          line-height: 69px;
          text-align: center;
          border-radius: 69px;
          margin-left: 20px;
          font-size: 22px;
        }
        td{
          &.chevron{
            width: 20px;
            padding-left: 0;
            text-align: right !important;
            font-size: 20px;
            vertical-align: bottom !important;
          }
          &:nth-child(2){
            text-align: right;
            vertical-align: top;
          }
          &:nth-child(3){
            text-align: right;
            vertical-align: top;
            width: 40px;
            span{
              margin-left: 0;
            }
          }
          &:nth-child(4){
            width: 20px;
            padding-left: 0;
            text-align: right;
            font-size: 20px;
            vertical-align: bottom;
          }
        }
      }
    }
  }
  .slider {
    font-size: 18px;
    .min_max{
      width:100%;
      td:nth-child(2){
        text-align: right;
      }
    }
  }
  select{
    margin-left: 10px;
    padding: 5px;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    border:none !important;
  }
  .menu{
    text-align: center;
    i{
      font-size: 24px;
      cursor: pointer;
      padding: 10px;
      margin-right: 0;
      border-radius: 45px;
      line-height: 26px;
      width: 45px;
      height: 45px;
      &.active{
        background: $background;
      }
    }
  }
  h1{
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px !important;
  }
  .no_nights{
    text-align: center;
  }
  .wizard{
    padding-bottom: 40px;
    .empty_must{
      border: 5px solid #e66d71 !important;
      border-bottom-color: rgb(230, 109, 113);
      border-bottom-style: solid;
      border-bottom-width: 5px;
      border-radius: 0 !important;
    }
    .intro{
      text-align: center;
      span{
        font-size: 20px;
        margin-top: 10px;
        display: block;
      }

    }

    .fill{
      margin-top: 40px;
      .quarter.empty{
        opacity: 0.5;
      }
      &.sleep{
        .quarter{
          cursor: auto !important;
          &.sleep,
          &.out_of_bed,
          &.awake{
            cursor: pointer !important;
          }
        }
      }
      &.nap{
        .out_of_bed,
        .sleep,
        .awake{
          cursor: auto !important;
        }
      }
      &.light{
        .nap,
        .sleep,
        .out_of_bed,
        .awake{
          cursor: auto !important;
        }
      }
      .hour{
        table{
          width:100%;
          td:nth-child(2){
            text-align: right;
          }
        }
        .quarters{
          display: flex;
          height: 35px;
          background: #f9f9f9;
          .quarter{
            width: 25%;
            border: 1px solid #fff;
            cursor: pointer;
            text-align: center;
            line-height: 35px;
            &.sleep{
              background: $orange;
              border: 1px solid #fff;
              color:#fff;
            }
            &.light{
              background: #cff6f2;
              border: 1px solid #fff;
            }
            &.nap{
              background: $light_bleu;
              border: 1px solid #fff;
              color:#fff;
            }
            &.awake{
              background: $dark_bleu;
              border: 1px solid #fff;
              color:#fff;
            }
            &.out_of_bed{
              background: #F7A0A4;
              color:#fff;
              border: 1px solid #fff;
            }
          }
        }
      }
    }
    .step_1{
      .instruction{
        margin: 20px 0 0 !important;
      }
      h4{
        margin-bottom: 20px;
        font-size: 20px;
      }
      table{
        margin: 0 !important;
      }
      display: flex;
      flex-direction: row;
      .question{
        margin-bottom: 0;
        width: 50%;
        &:nth-child(1){
          margin-right: 15px;
        }
        &:nth-child(2){
          margin-left: 15px;
        }
        span{
          font-size: 20px;
          display: block;
          margin: 10px 0;
        }
        div{
          margin: 10px;
          /*font-size: 20px;*/
          select{
            margin: 0 5px;
          }
        }

        text-align: left;
      }

    }
    .steps{
      margin-top: 40px;
      width:100%;
      td:nth-child(2){
        text-align: right;
      }
    }
  }
  .settings{
    .intro{
      margin-bottom: 40px;
      text-align: center;
    }
    .switch{
      top: 3px;
      position: relative;
    }
    .questions{
      margin-top: 40px;
      .question{
        margin-top: 20px;
        td:nth-child(1){
          padding-right: 15px;
        }
        label{
          font-size: initial;
          color:initial;
          margin-bottom: 0;
          top: 0;
        }
      }
    }

  }
  .this_week{
    .nights{
      margin-bottom: 40px;
    }

  }
  .weeks,
  .this_week{
    .nights{
      /*
      background: #f5f5f5;
      padding: 20px 30px 50px;
      margin-top: 40px;
      .item:first-child{
        .night{
          margin-top: 0;
        }
      }
      */
      h2{
        margin-bottom: 40px;
        font:Bold 24px/28px Montserrat !important;
        text-align: center;
      }
      table{
        width:100%;
      }
      .score{
        float: right;
        border-radius: 60px;
        width: 60px;
        height: 60px;
        line-height: 60px;
        margin-right: 15px;
        text-align: center;
        font-size: 18px;
        i{
          margin-right: 5px;
        }
      }
      .scheme_holder{
        margin: 12px 0 12px;
        table{
          width: 118%;
          margin-left: -9%;

          td{
            text-align: center;
            width:calc(100% / 5);
            &:nth-child(4){
              font-size: 16px;
            }
          }
        }
        .scheme{
          height: 40px;
          display: flex;
          flex-direction: row;
          align-items: stretch;

          .quarter{
            border:1px solid #f9f9f9;
            flex: 1;
            &.sleep{
              background: $orange;
              border:1px solid $orange !important;
            }
            &.light{
              background: #cff6f2;
              border:1px solid #cff6f2 !important;
            }
            &.nap{
              background: $light_bleu;
              border:1px solid $light_bleu !important;
            }
            &.awake{
              background: $dark_bleu;
              border:1px solid $dark_bleu !important;
            }
            &.out_of_bed{
              background: #F7A0A4;
              border:1px solid #F7A0A4 !important;
            }
          }
        }
      }
      .item{
        padding: 6px 0 2px;
        &.empty{
          height: 122px;
          color:#666;
          padding-top: 36px;
          .night{
            margin-bottom: 5px;
          }
        }
        &:last-child{
          margin-bottom: 0;
        }
        .empty_night{
          height: 34px;
        }
        &.open {
          background: #f9f9f9;
          width: calc(100% + 20px);
          padding-left: 10px;
          padding-right: 10px;
          margin-left: -10px;
          border-bottom:2px solid #fff;
          i.fa-chevron-up{
            transition: all 0.5s;
            transform: rotate(180deg);
          }
        }
        border-bottom:2px solid #f9f9f9;
        /*
        &:nth-child(even){
          background: #f9f9f9;
          .scheme_holder{
            .quarter{
              border:1px solid #eaeaea;
            }
          }
        }
        */
        .details{
          padding: 10px 0 20px;

        }
        .night{
            font-weight: 500;
            margin-bottom: 12px;
            /*font-size: 20px;*/

            span{
              font-weight: normal;
            }

            i{
              float: right;
              transition: all 0.5s;
              cursor: pointer;
            }
        }
      }
    }
  }
  .this_week{
    .holderGraph{
      margin-top: 40px;
    }
    .intro{
      text-align: center;
      margin-bottom: 40px;
    }
  }
  .box{
    margin-top: 30px;
    box-shadow: 0 5px 35px #e9e9e9;
    box-shadow: 0 5px 35px #A2BDCA33;
    border-radius: 10px;
    background: #FFFFFF 0 0 no-repeat padding-box;
    margin-bottom: 40px;
    padding: 40px;
    h1{
      font: Bold 32px/39px Montserrat;
    }
    h2{
      font: Bold 24px/28px Montserrat !important;
    }

    &.in_short {
      display: inline-block;
      height:278px;
      margin-bottom: 10px;
      width: calc(50% - 25px);
      vertical-align: top;
      margin-top: 0;
      &.best_night{
        margin-left: 45px;
        .night{
          margin-bottom: 15px;
          margin-top: -15px;
          text-align: center;
        }
      }
      h2{
        margin-bottom: 20px;
        text-align: center;
        font-weight: normal !important;
        font-size: 20px !important;
      }
      span{
        font-size: 28px;
        width: 122px;
        padding: 20px;
        border-radius: 400px;
        height: 122px;
        text-align: center;
        line-height: 81px;
        margin: auto;
        display: block;
      }
    }
  }

}
@media (max-width:768px) {
  .sleep-diary {
    .box{
      h2 {
        font: Bold 20px/24px Montserrat !important;

      }
    }
    .wizard{
      .quarter {
        font-size: 11px;
      }
      #cph_grade_sleep,
      #cph_feel{
        &.box{
          padding: 20px 10px;
        }
      }
      .choose_date {
        td:nth-child(1),
        td:nth-child(3){
          width:50px;
        }
        i{
          color:#fff;
          font-size: 28px;
        }
      }
      .fill{
        background: #fff;
        width: calc(100% + 40px);
        padding: 40px 20px;
        margin-left: -20px;
      }
      .intro{
        span{
          margin-top: 20px;
        }
      }
      .step_1{
        .box{
          padding: 40px 20px;
        }
        @media (max-width:575px) {
          display: block;
          .box{
            width:100%;
            margin-left: 0 !important;
            margin-right: 0 !important;
          }
        }
      }
    }
    .menu i{
      margin-left: 5px;
      margin-right: 5px;
      &.active{
        background: #fff;
      }
    }
    .weeks{
      .box.bar{
        padding: 20px;
        h2{
          text-align: left;
        }
      }
      .week{
        table table{
          width:auto;
        }
      }
      .top_table{
        table{
          float: none !important;
        }
      }
      .to_block{
        display: block;
        padding-bottom: 10px;
        br{
          display: none;
        }
        table{
          margin-top: 5px;
        }
        .nr_nights{
          margin-left: 0;
        }
      }
      .week table span,
      .nr_nights{
        line-height: 50px !important;
        font-size: 16px !important;
        width:50px !important;
        height: 50px !important;
        margin-left: 10px;
      }
    }
    .box{
      padding: 20px;
    }
    .this_week,
    .weeks{
      .nights{
        .items{
          background: #fff;
          border-radius: 10px;
          padding-top: 10px;
          padding-bottom: 10px;
          .item{
            padding-right: 20px;
            padding-left: 20px;
            &:last-child{
              border-bottom: none;
            }
            &.empty{
              padding-top: 22px;
            }
            .details{
              tr,td{
                display: block;
              }
              td:nth-child(2){
                padding-top:20px;
                .score{
                  float: left;
                }
              }
            }
            &.open{
              width: 100%;
              margin-left: 0;
              .quarter{
                border-color:#fff;
              }
            }

          }
        }
      }
    }
  }
}
