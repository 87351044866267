.base_theme .leftBottom{
  padding: 0 40px 20px 40px;
  max-width: 300px;
  position: fixed;
  z-index: 1;
  bottom: 0;
  color: #000;
  font-family: Europa;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 171.429% */
  .logo{
    width: 180px;
    margin-top: 40px;
    margin-bottom: 20px;
    margin-right: 20px;
    display: block;
  }
  div{
    display: inline-block;
    margin-right: 10px;
  }
  .submenu{
    margin-top: 20px;
    a{
      display: block;
      color: $title_color;
    }
  }
  .profile{
    margin-bottom: 40px;
    margin-top: 40px;
    height: 80px;
    border-radius: 40px;
    box-shadow: -22px 0 33px 0 rgba(161, 189, 202, 0.36);
    background-color: #ffffff;
    padding: 5px 10px;

    img{
      width:75px;
      border-radius: 40px;
    }
    .name{
      font-size: 16px;
      font-weight: bold;
    }
  }
  .profileName {
    padding-right: 10px;
  }
}
@media (max-width:850px) {
  .base_theme .lessoncontent.front .leftBottom{
    max-width: 200px !important;
    padding-left: 30px !important;
    img{
      max-width: 100%;
    }
  }
}
@media (max-width:$breakpoint) {
  .base_theme .leftBottom{
    display: none;
  }
}
