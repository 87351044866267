.student_details {
  h4 {
    margin-bottom: 5px;
  }
  /*
  textarea {
    width: 100%;
    height: 150px;
    background-color: #fff;
    border: 0px;
    -webkit-box-shadow: 1px 6px 23px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 6px 23px -10px rgba(0,0,0,0.75);
    box-shadow: 1px 6px 23px -10px rgba(0,0,0,0.75);
    margin-bottom: 20px;
    padding: 8px;
  }
  input[type=text] {
    width: 100%;
    background-color: #fff;
    border: 0px;
    -webkit-box-shadow: 1px 6px 23px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 6px 23px -10px rgba(0,0,0,0.75);
    box-shadow: 1px 6px 23px -10px rgba(0,0,0,0.75);
    margin-bottom: 20px;
    padding: 8px;
  }
  */
  .options li {
    list-style: none;
  }
}
