.data {
  background: #f5f5f5;
  min-height: calc(100vh - 85px);
  .download {
    cursor: pointer;
  }
  .navbar{
    background: #fff;
    position: fixed;
    height: 85px;
    width: 100%;
    z-index: 11;
    top: 0;
    h2 {
      font-size: 20px;
      padding-left: 25px;
      color: #505050;
    }
  }
  .container{
    padding: 80px 40px 80px;
    max-width: 1000px;
  }
  .download_group{
    margin-bottom: 10px;
    border-bottom:1px solid #eaeaea;
    padding-bottom: 10px;
    &.open{
      h5 i{
        transition: all .5s;
        transform: rotate(180deg);
      }
      .download_options{
        display: block;
      }
    }
    h5{
      position: relative;
      padding: 10px 0;
      margin-bottom: 0;
      cursor: pointer;
      i{
        transition: all .5s;
        transform: rotate(90deg);
        border: none;
        font-size: 18px;
        margin-right: 15px;
        pointer-events: none;
      }
    }

    .download_options{
      display: none;
      .download_option{
        border: 1px solid #eaeaea;
        padding: 20px;
        margin:10px 40px;
        &.child{
          margin-left: 100px;
        }
        .btn{
          float: right;
          margin-top: -10px;
        }
        &:hover{
          background: #eaeaea;
        }
      }
    }
  }

}
