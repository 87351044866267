.loadScreen{
  width:100%;
  height:100vh;
  img{
    -webkit-transform: translate(-50%, -50%);
       transform: translate(-50%, -50%);
       top: 50%;
       left: 50%;
       position: fixed;
  }
}
body{

}
