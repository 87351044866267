.base_theme.theme_2{
  .dashboard.lessoncontent.front{
    .holder{
      background: #f8f8f8;
      .left{
        background: #f8f8f8;
      }
    }
  }
}
