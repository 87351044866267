body {
    background-color: #f5f5f5;
    font-family: 'Europa', sans-serif;
}
.alert-danger {
    color: #495057;
    background-color: #fefcfb;
    border-color: #bf4444;
}
.bg-light {
    background-color: #fff !important;
}
.btn {
    border-radius: 2.25em;
    padding-left: 1.75em;
    padding-right: 1.75em;
}
.btn-primary {
    background-color: #4fbdf4;
    border-color: #4fbdf4;
}
.btn-primary:hover {
    background-color: #5dc2f5;
    border-color: #5dc2f5;
}
.btn-profile {
    background-color: #4fbdf4;
    border-color: #4fbdf4;
    padding-left: 0.4em;
    padding-right: 0.4em;
    float: right;
}
.btn-profile:hover {
    background-color: #5dc2f5;
    border-color: #5dc2f5;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    background-color: #5dc2f5;
    border-color: #5dc2f5;
}
.card {
    border-radius: 15px;
}
.card-header {
    text-align: center;
    color: #666;
    background-color: #fff;
}
.card-header:first-child {
    border-radius: 15px 15px 0px 0px;
}
.card-body {
    background-color: #fafafa;
    border-radius: 0px 0px 15px 15px;
}
.dropdown-menu {
    border: 0;
    border-radius: 0;
    box-shadow: 0 6px 19px rgba(0,0,0,0.19);
    margin-top: 3px;
}
.dropdown-item {
    margin-top: 7px;
    color: #a8acae;
    font-size: 14px;
}
.form-control {
    border-top: 0;
    border-left: 0;
    border-right: 0px;
    border-bottom: 2px #efefef solid;
    border-radius: 0;
    padding-left: 0px;
    padding-right: 0px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 0.92rem;
    font-weight: 200;
    color: #000;
}

.form-control:focus {
    color: initial;
    background-color: initial;
    border-color: #80bdff;
    outline: initial;
    box-shadow: initial;
}
label {
    font-size: 0.7rem;
    color: #aaa;
}
.modal-backdrop {
    background-color: #fff;
 }
 .modal-content {
     border-radius: 0;
     border: 0;
     box-shadow: 0 6px 19px rgba(0,0,0,0.19);
 }
 .modal-footer {
     border-top: 0;
 }
 .modal-header h5 {
     font-size: 14px;
     color: #313537;
 }
.navbar {
    height: 80px;
    border-bottom: 1px solid #ccc;
}

