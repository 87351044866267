.progress-bar-lessons-desktop {
   height: 12px;
   width: calc(100% - 98px);
   background-color: $orange;
   border-radius: 10px;
   margin-top: 60px;
   margin-bottom: 60px;
   margin-left: 28px;
   .progress {
      height: 100%;
      background-color: #1d4097;
      border-radius: 10px 0px 0px 10px;
      transition: width 1s ease;
   }
   .finishFlag {
      display: inline-block;
      float: right;
      margin-right: -85px;
      margin-top: -29px;
      img {
         width: 70px;
      }
   }
   .rocket {
      display: inline-block;
      transition: margin-left 1s ease;
      img {
         margin-top: -32px;
         width: 50px;
      }
   }
   .progress-tooltip {
      font-weight: bold;
      color: #1d4097;
      background-color: #fff;
      border: 2px solid $orange;
      border-radius: 7px;
      visibility: visible !important;
      opacity: 100 !important;
   }
   .progress-tooltip::after {
      /* background-color: $orange; */
      /* border: 2px solid $orange; */
   }
   .__react_component_tooltip {
      visibility: visible !important;
      opacity: 100 !important;
   }
}
@media (max-width: 768px) {
   .onlyondesktop {
      display: none;
   }
}
