.registration_texts {
   table {
      width: 100%;
   }
   .contentEditable {
      background-color: #fff;
      border: 1px solid #000;
      padding: 1px 2px 1px 2px;
   }
   .mce-content-body {
      background-color: #fff;
      border: 1px solid #000;
      padding: 1px 2px 1px 2px;
   }
}