html{
  overflow-y: scroll;
  overflow-x: hidden;
}
body{
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  &.lessonMenuVisible,
  &.menuIsVisible{
    overflow: hidden;
  }
  &.shake{
    animation: shake 0.75s 2 cubic-bezier(.36,.07,.19,.97) both;
    backface-visibility: hidden;
    overflow: hidden;
  }

}
.alert-danger{
  background: #e76c70 !important;
  color: #fff !important;
  border-color:#e76c70 !important;
}
.loginScreen{
  /*font: 300 16px/24px Montserrat;*/
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: $background;
  /* background-image: url("../images/login_bg.png"); */
  background-repeat: no-repeat;
  background-position: -144px bottom;
  background-size: 3  0%;
  overflow: hidden;
  .shapes .shape {
    position: absolute;
    animation: cloud_1 64s infinite linear;
    &:nth-child(1) {
      top: 722px;
      left: 204px;
      width: 441px;
      height: 440px;
      background: url("../images/course/standard/svg/Path 3132.svg");
      mix-blend-mode: initial;
    }
    &:nth-child(2) {
      top: 560px;
      left: -8px;
      width: 271px;
      height: 274px;
      background: url("../images/course/standard/svg/Path 3134.svg");
      mix-blend-mode: initial;
    }
    &:nth-child(3) {
      top: 357px;
      left: -2px;
      width: 361px;
      height: 159px;
      background: transparent url("../images/course/standard/svg/Path 3133.svg") 0 0 no-repeat padding-box;
      mix-blend-mode: initial;
    }
    &:nth-child(4) {
      top: 40px;
      left: 80px;
      width: 415px;
      height: 382px;
      background: transparent url("../images/course/standard/svg/Path 3135.svg") 0 0 no-repeat padding-box;
      mix-blend-mode: initial;
    }
    &:nth-child(5) {
      top: 122px;
      left: 404px;
      width: 441px;
      height: 420px;
      background: url("../images/course/standard/svg/Path 3132.svg");
      mix-blend-mode: initial;
    }
    &:nth-child(6) {
      top: 560px;
      left: 700px;
      width: 271px;
      height: 274px;
      background: url("../images/course/standard/svg/Path 3134.svg");
      mix-blend-mode: initial;
    }
    &:nth-child(7) {
      top: 57px;
      left: 850px;
      width: 361px;
      height: 159px;
      background: transparent url("../images/course/standard/svg/Path 3133.svg") 0 0 no-repeat padding-box;
      mix-blend-mode: initial;
    }
    &:nth-child(8) {
      top: 300px;
      left: 900px;
      width: 415px;
      height: 382px;
      background: transparent url("../images/course/standard/svg/Path 3135.svg") 0 0 no-repeat padding-box;
      mix-blend-mode: initial;
    }
    &:nth-child(9) {
      top: 600px;
      left: 1050px;
      width: 361px;
      height: 159px;
      background: transparent url("../images/course/standard/svg/Path 3133.svg") 0 0 no-repeat padding-box;
      mix-blend-mode: initial;
    }
    &:nth-child(10) {
      top: -70px;
      left: 1500px;
      width: 415px;
      height: 382px;
      background: transparent url("../images/course/standard/svg/Path 3135.svg") 0 0 no-repeat padding-box;
      mix-blend-mode: initial;
    }
    &:nth-child(11) {
      top: 260px;
      left: 1200px;
      width: 271px;
      height: 274px;
      background: url("../images/course/standard/svg/Path 3134.svg");
      mix-blend-mode: initial;
    }
    &:nth-child(12) {
      top: 500px;
      left: 1700px;
      width: 415px;
      height: 382px;
      background: transparent url("../images/course/standard/svg/Path 3135.svg") 0 0 no-repeat padding-box;
      mix-blend-mode: initial;
    }
    &:nth-child(13) {
      top: 800px;
      left: 1450px;
      width: 361px;
      height: 159px;
      background: transparent url("../images/course/standard/svg/Path 3133.svg") 0 0 no-repeat padding-box;
      mix-blend-mode: initial;
    }
    &:nth-child(14) {
      top: 500px;
      left: 1600px;
      width: 415px;
      height: 382px;
      background: transparent url("../images/course/standard/svg/Path 3135.svg") 0 0 no-repeat padding-box;
      mix-blend-mode: initial;
    }
    &:nth-child(15) {
      top: 60px;
      left: 1900px;
      width: 271px;
      height: 274px;
      background: url("../images/course/standard/svg/Path 3134.svg");
      mix-blend-mode: initial;
    }
  }
  .vector2 {
    margin-left: calc(50% - 300px);
    margin-top: 60px;
    }
    .vector3 {
        margin-left: calc(50% - 470px);
    }
    .vector4 {
        float: right;
    }
    .vector5 {
        margin-left: calc(50% + 50px);
        margin-top: -30px;
    }
    .veactor6 {
        position: absolute;
        bottom: 0;
        left: 25%;
    }
  #loginContainer{
    width: 470px;
    max-width: 90%;
    top: 430px;
    left:50%;
    text-align: center;
    /* box-shadow: -22px 0 22px #F6CDA0; */
    border-radius: 32px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    position: absolute;
    transform: translate(-50%, -50%);
    padding: 30px;
    padding-top: 70px;
    z-index: 99;
    min-height: 280px;
    input {
        border-radius: 10px;
        border: 1px solid #efefef;
        width: 300px;
        margin: auto;
        height: 60px;
        padding-left: 20px;
    }
    div {
        margin-top: 30px;
    }
    button {
        width: 300px;
        img {
            float: right;
            width: 30px;
            height: 30px;
        }
        height: 60px;
        font-size: 17px;
    }
    button[type="submit"] {
        background-color: #000;
        border-color: #000;
    }
    .btn-login {
        background-color: #000;
        border-color: #000;
    }
    .btn-create-account {
        margin-top: 20px;
        border: 1px solid #000;
    }
    .forgotPassword {
        text-align: center;
        color: rgba(0, 0, 0, 0.60);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
    }
  }
  .languageswitchheader {
    position: absolute;
    right: 65px;
    top: 60px;
    width: 142px;
    height: 50px;
    flex-shrink: 0;
    margin: auto;
    text-align: center;
    /* 
    padding: 17px;
    font-size: 1.2em;
    cursor: pointer;
    .active {
      font-weight: bold;
    } */
    border-radius: 32px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    
    div {
        margin-top: 4px;
        display: inline-block;
        width: 65px;
        height: 40px;
        flex-shrink: 0;
        border-radius: 40px;
        
        line-height: 37px;
        
    }
    .active {
        background: #FFC200;
        border: 1px solid rgba(0, 0, 0, 0.20);
    }
  }
  
}

.forgotPassword{
  cursor: pointer;
  opacity: 0.3;
  font-size: 0.8em;
}
.date{
  .showDate{
    i{
      margin-right: 5px;
      &:nth-child(2){
        margin-left: 20px;
      }
    }
  }
}

@keyframes shake {
  10%, 90% {
    margin-left: -1px;
  }

  20%, 80% {
    margin-left: 2px;
  }

  30%, 50%, 70% {
    margin-left: -4px;
  }

  40%, 60% {
    margin-left: 4px;
  }
}
.noPadding{
  padding:0 !important;
}
.cursor{
  cursor: pointer;
}
.center{
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 20px;
}
.hide{
  display: none;
}
[contenteditable]:empty:before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  color:#999;
}
[contenteditable]:focus {
  outline: 0 solid transparent;
}
p:last-child{
  margin-bottom: 0;
}
.clearfix:after {
  content: "";
  clear: both;
  display: block;
}

.columns{
  &.grid-2{
    .column{
      float: left;
      width:48%;
      &:nth-child(2){
        margin-left: 2%;
      }
      &:nth-child(1){
        margin-right: 2%;
      }
    }
  }
  &.grid-3{
    .column{
      float: left;
      width:32%;
      &:nth-child(3),
      &:nth-child(2){
        margin-left: 1%;
      }
      &:nth-child(1){
        margin-right: 1%;
      }
    }
  }
  &.grid-4{
    .column{
      float: left;
      width:24%;
      &:nth-child(4),
      &:nth-child(3),
      &:nth-child(2){
        margin-left: 1%;
      }
      &:nth-child(1){
        margin-right: 1%;
      }
    }
  }
}
.overlay{
  background: rgba(0,0,0,0.2);
  width:100%;
  height:100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: none;
  cursor: pointer;
}
.customalert.overlay{
  display: block;
  &.hide{
    display: none;
  }
}
@keyframes cloud_1 {
  20%,
  60% {
    margin-left: -40px;
    transform: scale(0.87);
  }

  40%,
  80% {
    margin-left: 40px;
    transform: scale(1.15);
  }
}

@media (max-width:1024px) {
  .loginScreen {
    background-image: none;
  }
}

@media (max-width:680px) {
    .homeScreenVectors {
        display: none;
    }
  }