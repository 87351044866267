.linkbox{
  font-weight: bold;
  margin-right: 0;
  cursor: pointer;
  td:nth-child(2){
    text-align: left !important;
    opacity: 0.5;
  }

  .image{
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-size: cover !important;
    margin-right: 15px;
    display: block;
  }
  .btn-primary{
    margin-left: -10px;
    height: auto;
    /*max-width: 80%;*/
    /* background: $orange !important;
    border-color:$orange !important; */
    background: none;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    color: rgba(0,0,0,0.8);
    font-family: Europa;
    font-size: 14px;
    font-style: bold;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
  }
  .btn-primary:hover {
    background: none;
    border: none;
    color: rgba(0,0,0,0.5);
    }
    .btn-primary:    {
        background: none;
        border: none;
        color: rgba(0,0,0,0.5);
        }
}
.base_theme .profile{
    max-width: 800px;
    padding-top: 20px;

    .personalia {
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        .profile-top {
            padding: 30px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.20);
        }
        .profile-bottom {
            padding: 30px;
            .nameGridContainer {
                display: grid;
                grid-template-columns: auto auto auto;
                padding: 10px;
                padding-top: 0px;
                .nameGridItem {
                    padding: 5px;
                    padding-left: 0px;
                    label {
                        color: #000;
                        font-family: Europa;
                        font-size: 14px;
                        font-style: bold;
                        line-height: 20px;
                        margin-bottom: 0px;
                    }
                    .nameInput {
                        border-radius: 10px;
                        border: 1px solid rgba(0, 0, 0, 0.20);
                        height: 60px;
                        background: #fff;
                        input {
                            height: 58px;
                            display: inline-block;
                            width: calc(100% - 45px);
                        }
                        input:hover {
                            background: #fff;
                        }
                        img {
                            float: right;
                            margin-right: 20px;
                            margin-top: 20px;
                        }
                        padding: 0;
                    }
                }
            }
            .emailGridContainer {
                display: grid;
                grid-template-columns: auto auto;
                padding: 10px;
                padding-top: 0px;
                .emailGridItem {
                    padding: 5px;
                    padding-left: 0px;
                    label {
                        color: #000;
                        font-family: Europa;
                        font-size: 14px;
                        font-style: bold;
                        line-height: 20px;
                        margin-bottom: 0px;
                    }
                    .emailInput {
                        border-radius: 10px;
                        border: 1px solid rgba(0, 0, 0, 0.20);
                        height: 60px;
                        background: #fff;
                        input {
                            height: 58px;
                            display: inline-block;
                            width: calc(100% - 45px);
                            background: #fff;
                        }
                        input:hover {
                            background: #fff;
                        }
                        img {
                            float: right;
                            margin-right: 20px;
                            margin-top: 20px;
                        }
                        padding: 0;
                    }
                }
            }
            .genderContainer {
                padding: 10px;
                label {
                    color: #000;
                    font-family: Europa;
                    font-size: 14px;
                    font-style: bold;
                    line-height: 20px;
                    margin-bottom: 0px;
                }
                .block {
                    display: block;
                }
                .genderContainerItem {
                    border-radius: 10px;
                    border: 1px solid rgba(0, 0, 0, 0.20);
                    display: inline-block;
                    padding: 16px;
                    margin-right: 5px;
                }
            }
            .languageContainer {
                padding: 10px;
                label {
                    color: #000;
                    font-family: Europa;
                    font-size: 14px;
                    font-style: bold;
                    line-height: 20px;
                    margin-bottom: 0px;
                }
                .block {
                    display: block;
                }
                .languageContainerItem {
                    border-radius: 10px;
                    border: 1px solid rgba(0, 0, 0, 0.20);
                    display: inline-block;
                    padding: 16px;
                    margin-right: 5px;
                }
            }
        }
        .ssi-profile {
            padding: 20px;
        }
    }
    /*margin:0 auto;*/
    h1{
      text-align: center;
    }
    input[type='file']{
      display: none;
    }
    input,textarea,select{
      border:none !important
    }
    .logOut{
      margin-top: 20px;
    }
    .nameHolder{
      position: relative;
      textarea{
        padding: 6px 20px;
        height: calc(100% + 20px);
        overflow: hidden;
        width: 100%;
        left: 0;
        line-height: 1;
        color: inherit;
        position: absolute;
        border: none;
        outline: none;
        background: transparent;
        resize: none;
        text-rendering: geometricPrecision
      }
      .dummy{
            visibility: hidden;
      }
    }
    .flatpickr-input{
      /*visibility: hidden;*/
    }
    select{
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
    }
    select,
    input[type='text']{
      border:none;
      margin-left: 10px;
      padding: 5px;
      &:hover{
        background: #ecf2f5;
      }
    }
    .field{
      margin-bottom: 4px;
    }
    .image_name{
      margin-bottom: -10px;
      .btn{
        margin-top: 20px;
        margin-bottom: 20px;
        img {
            width: 30px;
            height: 30px;
        }
        color: #CCC;
        font-family: Europa;
        font-size: 17px;
        font-style: bold;
        font-weight: 700;
        line-height: 24px;
        border-radius: 60px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        padding-left: 10px;
        padding-right: 10px;
        float: right;
        cursor: pointer;
        margin-top: 40px;
      }
      .name{
        font-size: 24px;
        font-weight: bold;
        line-height: 40px;
        padding-right: 30px;
        display: inline-block;
        top: -50px;
        &:hover{
          .handle{
            display: inline-block;
          }
        }
        position: relative;
        .handle{
          display: none;
          margin-left: 10px;
          position: absolute;
          cursor: pointer;
          /* right: -20px;
          top: -28px; */
        }
      }
      .editName{
        display: none;
        .btn-primary{
          margin-top: 20px;
          i{
            margin-left: 0;
            margin-right: 10px;
          }

        }
      }
      .edit{
        .name{
          display: none;
        }
        .editName{
          display: block;
        }
      }
      .image_holder{
        position: relative;
        background: #eaeaea;
        border-radius: 500px;
        width: 120px;
        height: 120px;
        background-size: cover !important;
        margin-right: 20px;
        display: inline-block;
        .options{
          top: 50%;
          left: 50%;
          position: absolute;
          opacity: 0;
          transform: translate(-50%, -50%);
          .btn {
            width: 20px;
            height: 20px;
            border-radius: 20px;
            border: 1px solid rgba(0, 0, 0, 0.20);
            background: rgba(255, 231, 210, 0.50);
            right: -20px !important;
            top: -28px !important; 
          }
        }
        &:hover {
          /*background-image: none !important;*/
          .options{
            opacity: 1;
          }
        }
      }
    }
    .preferences{
      margin-top: 40px;
      .option{
        margin-bottom: 5px;
        min-height: 42px;
        .switch{
          float: right;
          top: -13px;
        }
      }
    }
    .notificationAbout{
      padding:20px;
      border:1px solid #212529;
      margin-top:40px;
      .btn{
        margin-top:20px;
      }
    }
  }
  @media (max-width:$breakpoint) {  
    .base_theme .profile{
      background: #fff;
      padding: 30px 20px 40px;
      box-shadow: 0px 5px 35px #A2BDCA33;
      border-radius: 10px;
      .image_name{
        width:100%;
        td{
          width:100%;
          display: block;
          .image_holder{
            margin:0 auto;
          }
          &:nth-child(2){
            padding-top: 40px;
          }
        }
      }
      .personalia {
        .profile-bottom {
            padding: 10px;
            .nameGridContainer {
                display: block;
                  .nameGridItem {
                    width: 100%;
                  }
            }
            .emailGridContainer {
                display: block;
                  .emailGridItem {
                    width: 100%;
                  }
            }
            .genderContainer {
                .genderContainerItem {
                    margin-bottom: 10px;
                    width: 100%;
                }
            }
            .languageContainer {
                .languageContainerItem {
                    margin-bottom: 10px;
                    width: 100%;
                }
            }
        }
      }
      .image_name {
        .name {
            top: 0px;
        }
        .btn {
            margin-top: -13px;
            margin-right: -13px;

        }
      }
      
    }
  }

  @media (max-width:510px) { 
    .base_theme .profile{ 
    .image_name {
       /*  .name {
            top: 0px;
        } */
        .btn {
            margin-top: -145px;
        }
      }
    }
  }
