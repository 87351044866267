body{
  background: #f5f5f5 !important;
}
.admin .overview_registrations{
  tr{
    td:nth-child(1){
      width:100px;
    }
    td:nth-child(2){
      width:200px;
    }
    td:nth-child(4){
      width:200px;
    }
    td:nth-child(5){
      width:100px;
    }
  }
}
.admin.coaches.overview{
  .coaches_holder{
    display: flex;
    flex-wrap: wrap;
    .coach{
      padding: 20px;
      background: #fff;
      margin: 0 20px 20px;
      flex-grow: 1;
      width: calc(33% - 40px);
      .name{
        font-size: 20px;
      }
    }
  }
}
.admin .the_intervention_header{
  background: #f5f5f5;
  padding-top: 60px;
  margin-top: -60px;
  padding-bottom: 40px;
  text-align: center;
  .image{
    border-radius: 200px;
    width: 100px;
    margin: 0 auto 40px;
    height: 100px;
    background-size: cover !important;
    background: #fff;
  }
}
.admin.platform{
  .intervention_edit{
    padding-top: 0;
    margin-top: -20px;
  }
  .navbar {
    margin-bottom: 40px;
  }
}
.admin.platform-translations{
  .intervention_edit{
    padding-top: 0;
    .navbar {
      margin-bottom: 20px;
    }
    .custom-select{
      background: none;
      margin-bottom: 20px;
    }
  }
  .no_results{
    background: #fff;
    margin-top: 10px;
    padding: 20px;
  }
  .language_selector{
    position: relative;
    h2{
      cursor: pointer;
    }
    select{
      padding: 10px 20px;
      background: none;
      border:1px solid;
      border-radius: 10px;
      margin-left: 10px;
      height: 41px;
    }

  }
}
.coachInterface.admin{
  .total{
    margin-bottom: 0;
    top: -49px;
    position: relative;
    display: inline-block;
  }
  .holder_searched_for{
    margin-right: 71px;
  }
  .interventions_selection{
     select{
        padding: 6px !important;
        height: 31px !important;
     }
    .error{
      margin-bottom: 20px;
    }
    label{
      color:#000 !important;
      font-size: 1rem;
    }
    input[name='max_students']{
      width:200px;
    }
    td{
      padding-right: 40px;
      padding-top: 10px !important;
      select{
        border-bottom:0;
        position: relative;
        top: -5px;
      }
      &:nth-child(1){
        width:40%;
      }
      &:nth-child(2){
        width:20%;
        padding-left: 40px !important;
        input[type="checkbox"] + label{
          top: -19px;
        }
      }
    }
  }

  input[type="radio"] + label::after, input[type="checkbox"] + label::after {
    top: 2px;
  }
  .students_holder{
    .interventions{
      margin-bottom: 20px;
      background: #f6de7c !important;
      padding: 10px;
      border: none !important;
    }
  }
  &:not(.margeTop) .list{
    margin-top: 0 !important;
  }
  &:not(.margeTop).openSearch{
    .holder{
      margin-top: 60px;
    }
  }
  &:not(.margeTop) .listHidden{
    max-width: 1140px;
    padding-top: 0;
    padding-right: 15px;
    padding-left: 15px;
    &{
      margin-top: 80px;
    }

  }
}
.coachInterface.platform{
  padding-top: 0;
  .container{
    padding-top: 0;
  }
  .settings_container{
    max-width:initial;
    .form-control{
        background: #f5f5f5 ;
    }
  }
}
.admin{
  .menu_left_inner .pointer{
    display: flex;
    .icon{
      width: 30px;
    }
    .menu-left-link{
      position: relative;
      left: 0;
      text-align: left;
      width:calc(100% - 50px);
    }
  }
  .pagination{
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
    display: block;
    font-weight: 100;
    .end,
    .start{
      position: relative;
      margin-right: 10px;
      margin-left: 10px;

      i{
        margin-right: -3px !important;
        margin-left: 0;
      }
    }
    i{
      cursor: pointer;
      margin-left: 10px;
      opacity: 0.9;
      &.fa-chevron-left{
        margin-right: 10px;
        margin-left: 0;
      }
    }
    span:not(.showing_dots){
      cursor: pointer;
    }
    span.showing_dots{
      position: relative;
      top: -3px;
      display: inline-block;
      font-size: 20px;
      margin: 0 10px;
      display: none;
    }
    .pagination_go_to {
      &:hover{
        span{
          text-decoration: underline;
        }
      }
      &.active{
        font-weight: bold;
      }
    }
  }
  &.coachInterface.coaches{
    .holder_searched_for{
      margin-right: 77px;
      margin-top: -18px;
    }
  }
  .holder_searched_for{
    position: absolute;
    right: 0;
    margin-right: 30px;
    margin-top: -5px;
    z-index: 4;
    .btn{
      margin-right: 20px;
    }
  }
  .coachInterface .theIntervention .search{
    select{
      margin-right: 20px;
      background: #fff;
      padding: 10px;
      border:1px solid #eaeaea;
      height: 48px;
      width: 200px;
      max-width: 200px;

    }
    input{
      border:1px solid #eaeaea;
      width: 200px;
      max-width: 200px;
      outline: none;
    }
  }
}
.coachInterface,
.dashboard.admin{
  .main_options{
    padding-top: 20px;
    padding-left: 10px;
    padding-bottom: 50px;
    position: relative;
    z-index: 3;
    display: inline-block;
    i{
      margin: 0 10px;
      cursor: pointer;
      padding: 10px;
      background: #fff;
      font-size: 20px;
      border-radius: 50px;
      &:hover,
      &.active{
        background: #4fbdf4;
        color:#fff;
      }
      &.fa-users{
        width:40px;
        &:before{
          margin-left: -2px;
          position: relative;
        }
      }
    }
  }
}
.dashboard.admin{
  .table_options{
    width:100%;
    td:nth-child(2){
      text-align: right;
      position: relative;
      z-index: 100000;
    }
  }
  .overview_students {
    width:100%;
    margin-top: -80px;
    position: relative;
    padding-bottom: 80px !important;
    z-index: 2;
    .testaccount{

    }
    .total{
      top: -30px;
      position: relative;
      margin-bottom: 30px;
    }
    .details {

      .emails{
        .emailHeader:not(.pointer){
          padding-top: 20px;
        }
        .resendMail{
          cursor: pointer;
          margin-right: 10px;
        }
        .resend{
          color:#28a745;
        }
        .pagination{
          margin-bottom: 10px;
          margin-top: 40px;
        }
        table{
          margin-bottom: 0;
          .pointer{
            td:nth-child(1){
              width:75px;
            }
            td:nth-child(2){
              width: 200px;
            }
          }
          thead,
          th{
            padding-top: 0;
            border-bottom: 0 !important;
            border-top: 0 !important;
          }
          td{
            padding: 5px;
            border: 0 !important;
            span{
              margin-right: 10px;
            }
          }
        }
      }
      .interventions{
        label{
          color:#000 !important;
          font-size: 1rem;
        }

        table{
          width: auto;
          td:nth-child(1){
            padding-right: 40px;
          }
        }

        thead,
        th{
          padding: 0;
          border-bottom: 0 !important;
        }
      }
      .log{
        .fa-file-download{
          float: right;
          position: relative;
          top: -31px;
          font-size: 20px;
          margin-right: 6px;
          cursor: pointer;
        }
        .pagination{
          margin-bottom: 10px;
          margin-top: 20px;
        }
        table{
          margin-bottom: 0;
          th{
            padding-top: 0;
          }
          td,
          th{
            border-top:0;
            border-bottom:0;
          }
          td{
            width:30%;
            padding: 5px;

          }

          td:nth-child(1){
            width:10%;
          }

        }
      }
      .saved{
        opacity: 0.5;
        pointer-events: none;
      }
      .talen {
        label{
          font-size: 1em;
          color:#000;
        }
      }

      .role {
        padding-top: 10px;
        padding-bottom: 10px;
        .form-check{
            padding-left: 0;
        }
        label{
          font-size: 1em;
          color:#000;
        }
        .error{
          margin-bottom: 20px;
        }
      }
      .success{
        text-align: center;
        padding: 10px 20px;
        margin-bottom: 20px;
        margin-top: -10px;
        color:#fff;
        background:#28a745;
        i{
          margin-left: 10px;
          color:#fff;
        }
      }
      label{
        font-weight: normal !important;
      }
      .marge_top{
        margin-top: 30px;
      }
      .set_new_password{
        display: flex;


      }
      .error{
        margin-top: 10px;
        background: $red;
        color:#fff;
        padding: 10px;

      }
      .align_right{
        text-align: right;

      }
      td{
        padding: 0;
        .content{
          margin-bottom: 20px;
          background: #fff;
          /*border-left: 8px solid #f6de7c;;*/
          padding: 40px 40px 40px;
          .students{
            margin-bottom: 30px;
            th{
              cursor:pointer;
            }
            td:last-child{
              width:150px;
            }
            .betaald{
              // /*background: #28a745;*
              color:#28a745;
              border-radius: 20px;
              cursor: pointer;
              &.niet{
                color: $red;
              }
            }
            tr{
              td{
                padding: 10px;
              }
              &:nth-child(odd){
                td{
                  background: #eaeaea;

                }
              }
            }
          }
          .flex{
            display: flex;
            .right{
              padding-left: 70px;
              width:calc(100% - 170px);
            }
          }
          .close{
            cursor: pointer;
          }
          .image{
            width:150px;
            height:150px;
            background: #eaeaea;
            background-size: cover !important;
            border-radius: 500px;
            margin-bottom: 40px;
            position: relative;
            &:hover{
              .options{
                opacity: 1;
              }
            }
            input[type="file"] {
              display: none;
            }
            .options{
              top: 50%;
              left: 50%;
              position: absolute;
              opacity: 0;
              transform: translate(-50%, -50%);
              width: 100px;
              text-align: center;
              span{
                margin: 0 5px;
              }
            }
          }
          .tabs{
            display: flex;
            margin-bottom: 20px;
            .tab{
              border-bottom:1px solid #fff;
              padding: 5px 20px;
              cursor: pointer;
              &.active{
                border-bottom:1px solid #000;
              }
            }

          }
          .tabContent{
            padding: 30px;
            background: #f9f9f9;
            .red{
              border-color:$red;
              i{
                position: relative;
                top: 2px;
              }
            }
            .registration_data{
              .error{
                text-align: center;
                margin-bottom: 20px;
                margin-top: 0;
              }
              table{
                margin-bottom: 0;
                td{
                  vertical-align: top;
                  padding: 5px 0;
                  width:50%;
                  border-bottom: 0;
                  &.align_right{
                    text-align: right;
                  }
                  input{
                    margin-bottom: 10px;
                  }
                  &:nth-child(2){
                    padding-left: 20px;
                  }
                  &:nth-child(1){
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.openSearch {
      .holder{
        margin-top: 44px;
      }
      .theIntervention .options{
        left: 0 !important;
      }
    }

    .active{
      td{
        background: #f6de7c;
         /*color:#fff;*/


      }
    }
    .details{

      .content{
        margin-bottom: 20px;
        background: #fff;

        /*border-left: 8px solid #f6de7c;;*/
        padding: 20px 40px 40px;

        .flex{
          display: flex;
        }
        .align_right{
          text-align: right;
        }
        .left{
          width:30%;

        }
        h4{
          margin-bottom: 20px;
          margin-left: 40px;
          font-size: 20px;
        }
        .right{
          margin-top:40px;
          margin-left:40px;
          margin-bottom:20px;
          width:70%;
          .content{
            background: #f5f5f5;
            padding: 40px;
          }
          .btn{
            margin-top: 10px
          }
          .select_dummy{
            line-height: 1.2;
            background: #eaeaea !important;
          }
          .select_dummy,
          select{
            margin-top: 10px;
            background: none;
            padding: 10px;
            border:1px solid;
            border-radius: 10px;
            display: inline-block;
            box-sizing: border-box;
            height: 41px;
          }
        }
        .image{
          margin-top: 20px;
          margin-bottom: 20px;
          width: 150px;
          height: 150px;
          background-size: cover !important;
          border-radius: 150px;
        }
        a{
          color:#000;
        }
        .field {
          i{
            border: 1px solid #545b62;
            color: #545b62;
            padding: 10px;
            border-radius: 40px;
            margin-right: 10px;
          }
          margin-bottom: 10px;
        }
      }
      td{
        border-bottom: none;
        &:nth-child(1){
          padding: 0;
        }
      }
    }
    .theIntervention .options {
      display: table-cell;

      .fa-search{
        background: none;
        font-size: 16px;
        &:hover{
          color:#000;
        }
      }
    }

  }
  .modal-header{
    .close{
      top:18px;
    }
    h5{
      font-size: 20px;
    }
  }
 .modal-footer{
   button{
     position: relative;
     top: initial;
     right: initial;
   }
 }
  button{
    position: absolute;
    top: 5px;
    right: 36px;
  }
  .intervention_links{
    padding-bottom: 60px;
    .fa-spin,
    .fa-chart-bar{
      margin-right: 20px;
    }
  }
  &.stats{
    margin-top: 0;
    padding-top: 60px;

  }
  .overview_students .theIntervention .options .fa-search{
    /*top: -53px;*/
    position: relative;
    z-index: 100;
    top: 11px;
  }

  .coachInterface.openSearch .theIntervention .search
  {
    /*top: -68px;*/
    z-index: 100000;
  }
  .overview_students{
    margin-top: -15px;
    .align_center{
      text-align: center;
      font-size: 30px;
    }
    .no_results{
      /*margin-top: -40px;*/
      background: #fff;
      padding: 20px;
    }
  }
  .stats{
    margin-top: -20px;
    .intervention .details table.coaches{
      width:100%;
      th,
      td{
        padding: 5px 10px;
        text-align: left;
      }
      tr{
        &:nth-child(odd){
          td{
            background: #f5f5f5;
          }
        }
      }
    }

    .bar_chart{
      .inline_block{
        width:calc(100% - 40px) !important;
        .content{
          width:100%;
        }
      }

    }
    .small_pie{
      width:200px;
      margin: 0 auto;
      canvas{
        height: 300px !important;
      }
    }
    .bigger{
      font-size: 28px;
    }
    table.standard{
      width:calc(100% + 20px);
      margin-left: -10px;
      tr:last-child{
        td{
          border-bottom: 0;
        }
      }
      td{
        border-bottom:1px solid #D5D5D5;
        padding: 5px 10px;
        &:nth-child(2){
          text-align: right;
          width: 125px;
        }
        &:nth-child(1){
          padding-right: 15px;
        }
      }
    }
    table.in_row{
      margin: 0 auto;
      td{
        padding-right: 20px;
        vertical-align: top;
        text-align: center;
      }
    }
    .intervention{
      padding-bottom: 60px;
      margin:0 auto;
      .align_right{
        text-align: right;
        margin-top: 20px;
        .fa-file-download{
          cursor: pointer;
        }
      }
      .details{
        margin-top: -20px;

        .two_td{
          width:100%;
          margin-bottom: 40px;

          &.one_td{
            width:auto;
          }
          td{
            vertical-align: top;
            max-width: 50%;
            &:nth-child(1){
              padding-right: 40px;
            }
            &:nth-child(2){
              padding-left: 40px;
            }
          }
        }
        table.standard{
          width:100%;
          td:nth-child(even){
            background: #f5f5f5;
            text-align: center;
            &:nth-child(1){
              padding-right: 0;
              width:50%;
            }
            &:nth-child(2){
              padding-left: 10px;
              text-align: left;
              width:50%;
            }
          }
        }
        h4{
          font-size: 24px;
          margin-bottom: 20px;
          margin-top: 40px;
        }
      }
    }
    h1{
      position: absolute;
      top: 114px;
      margin-left: 154px;
    }
    display: flex;
    .nav_stats{
      /*position: fixed;*/
      width:175px;
      ul{
        margin-top: 40px;
        /*background: #fff;*/
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        li{
          padding: 0 0 5px;
          border-bottom:1px solid #f5f5f5;
          cursor: pointer;
          transition: all 0.5s;
          position: relative;
          &:before{
            content:'';
            width: 0;
            height: 0;
            background: #f5f5f5;
            position: absolute;
            top: 4px;
            left:-20px;
            border-radius: 40px;
            transition: all 0.5s;
          }
          &:first-child{
            cursor: auto;
          }
          &:not(:first-child){
            &:hover,
            &.active{
              // /*border-bottom:1px solid #000;*
              padding-left: 30px;
              transition: all 0.5s;

              &:before{
                content:'';
                width: 20px;
                height: 20px;
                background: #4fbdf4;
                top: 4px;
                left:0;
                border-radius: 40px;
                transition: all 0.5s;
              }
            }
          }
        }
      }
    }
    .all_content{
      /*margin-top: -75px;*/
      padding-top: 20px;
      padding-bottom: 40px;
      padding-left: 80px;
      width: calc(100% - 175px);
    }
    .logins.box{
      columns: 2;
      margin-top: 40px;
      .login{
        padding-right: 40px;
      }
    }
    h3{
      margin-top: 0;
      margin-bottom: 50px;
      font-size: 30px;
    }
    .show_details{
      border-radius: 400px;
      height: 150px;
      width:150px;;
      margin: 0 20px 20px 0;
      display: inline-block;

      border-left:0 !important;
      border-top:5px solid #eaeaea !important;
      border-bottom:5px solid #4fbdf4;
      background: none !important;
      transition: all 0.5s;
      cursor: pointer;
      &:hover{
        background: #eaeaea !important;

      }
      i{
        font-size: 40px !important;
      }
      .content{
        padding-top: 40px;
      }
      .label{
        margin-top: 10px;
      }
    }
    .box{
      padding: 20px 30px;
      background: #fff;
      border-radius: 10px;
      margin-top: 15px;
      margin-bottom: 40px;
    }

    .details{
      margin-top: 20px;

      .inline_block{
        display: inline-block;
        vertical-align: top;
        margin-top: 20px;
        margin-right: 40px;
        width:calc(50% - 40px);
        .age{
          columns: 3;
          margin-top: 40px;
          td:nth-child(1){
            text-align: center;
          }
          td:nth-child(2){
            background: #f5f5f5;
            text-align: center;
          }
        }
        .content{
          i{
            font-size: 30px;
            position: relative;
            top: 5px;
          }
        }
        h4{
          padding-left: 20px;
        }
      }
      h4{
        font-size: 20px;
      }
      .content{
        margin-bottom: 30px;
        margin-top: 0px;
        padding: 20px 30px;
        background: #fff;
        border-radius: 10px;


      }
      .asterix{
        margin-top: 1.5em;
      }
    }
    .highlight_round{
      border-radius: 400px;
      height: 150px;
      width:150px;;
      margin: 0 20px 20px 0;
      display: inline-block;

      border-top:5px solid #4fbdf4;
      border-left:5px solid #fff;
      background: #fff;
      color: #4fbdf4;
      text-align: center;
      vertical-align: top;
      position: relative;
      &.yellow{
        border-top:5px solid #fabc5a;
        color: #fabc5a;
      }
      &.orange{
        border-top:5px solid #ff8d7a;
        color: #ff8d7a;
      }
      &.light_blue{
        border-top:5px solid #9ebac7;
        color: #9ebac7;
      }
      &.pink{
        border-top:5px solid #ef5ea3;
        color: #ef5ea3;
      }
      &.dark_bleu{
        border-top:5px solid #495D86;
        color: #495D86;
      }
      .center{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width:100%;
      }
      .label{
        font-size: 14px;
        height: 40px;
        line-height: 1.2;
        color:#000;
      }
      i{
        font-size: 30px;

      }
      .content{
        font-size: 24px;
        margin-bottom: 0;
        margin-top: 10px;
        color:#000;
        line-height: 1.2;
      }
    }
    .interventions{
      margin-top: -30px;
      .intervention{
        background: #fff;
        border-radius: 10px;
        margin-bottom: 40px;
        margin-top: 80px;
        position: relative;
        padding: 40px 20px 0;
        .show_details_btn{
          margin-left: 50%;
          margin-bottom: 40px;
          transform: translate(-50%, 0);
        }
        h4{
          font-size: 20px;
          margin-bottom: 20px;
          margin-top: 20px;
        }
        .details{
          padding-bottom: 20px;
          padding-top: 0;
          margin-top: 30px;
          padding-left: 29px;
          table.standard{
            width:100%;
            td:nth-child(even){
              background: #f5f5f5;
            }
          }
        }
        .hide_details_btn{
          margin-bottom: 40px;
          margin-left: 50%;
          transform: translate(-50%, 0);
        }
        .show_details{
          border-right:5px solid #fff;
          height: auto;
          width: auto;
          .content{
            padding-top: 0;
          }
          &:hover{
            border-right:5px solid #eaeaea !important;
            transition: all 0.5s;
          }
        }
        .title{
          font-size: 22px;
          top: -50px;
          margin-bottom: 20px;
          position: absolute;
          background: #fff;
          padding: 8px 17px 20px;
          border-radius: 10px;
          left: 0;
        }
        .image{
          width: 175px;
          height: 175px;
          background-size: cover !important;
          border-radius: 200px;
          display: inline-block;
          /*border-top: 16px solid #F9F9F9;*/
          margin-right: 20px;
        }

        .highlight_round{

          width:130px;
          height:130px;
        }

      }
    }
  }

  .card_holder{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    transition: transform .3s ease-in-out,opacity .3s ease-in-out;
    flex-direction: row;

    .card{
      border-radius: 10px;
      position: relative;
      margin: 0 40px 40px 0;
      width: calc(33% - 40px);
      overflow: hidden;
      background: #fff;
      border:none;
      transition: box-shadow .3s,transform .3s;
      cursor:pointer;
      transition: all 0.5s;
      .stats{
          margin-left: 0px;
          margin-bottom: 8px;
          position: relative;
          opacity: 0.5;
          line-height: 0.92;
        .fa{
          margin-bottom: 20px;
          margin-right: 5px;
        }
        .fa-check{
          margin-left: 10px;
        }
      }
      .image{
        background-size: cover !important;
        height: 150px;

      }
      .content{
        padding: 15px 30px 25px;
        h2{
          font-size: 1.4rem;
          font-weight: 900;
          line-height: 1.27;
          word-wrap: break-word;
          margin: 1rem 0 34px;
        }
        .actions{
          opacity: 0.4;
          text-align: right;
          font-size: 22px;
          .more{
            text-align: left;
            float:left;
            display: none;
          }
        }
      }
      &:hover{
        transition: all 0.5s;
        margin-top:-20px;
        -webkit-box-shadow: 1px 6px 23px -10px rgba(0,0,0,0.75);
        -moz-box-shadow: 1px 6px 23px -10px rgba(0,0,0,0.75);
        box-shadow: 1px 6px 23px -10px rgba(0,0,0,0.75);
        .actions{
          .more{
            display: block;
          }
        }
      }
    }
  }

}
.logoff.topright{
  position: fixed;
  right:20px;
  top:20px;
  cursor: pointer;
  font-size: 22px;
  z-index: 1000;
}

.saveStatus{
  background: #28a745;
  color:#fff;
  position: fixed;
  z-index: 100;
  right: 88px;
  top: 20px;
  padding: 0.375rem 1.75em 10px;
  border-radius: 2.25em;
}
.saveStatus.not_saved{
  color:#505050;;
  background: #ffc107;
}
