.base_theme .dashboard {

  &.page {
    .holder {
        .right {
            .content {
                .page {
                    .component {
                        padding-top: 20px;
                    }
                }
            }
        }
    }
  }

  overflow: hidden;
  &.journal{
    .openQuestion {
        display: table;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.20);
        border-radius: 10px;
        margin-bottom: 20px;
        .icon {
            width: 45px;
            display: table-cell;
            vertical-align: top;
            padding-left: 12px;
            padding-top: 17px;
            height: 100%;
        }
        .input {
            min-height: 54px;
            display: table-cell;
            width: calc(100% - 46px);
            /* border: none !important; */
            border-left: 1px solid rgba(0, 0, 0, 0.20);
            padding-top: 15px;
            padding-bottom: 5px;
        }

    }
    .openQuestion:hover {
        border: 1px solid rgba(0, 0, 0, 0.60) !important; 
    }
    .btn {
        display: flex;
        height: 60px;
        padding: 15px 20px 15px 20px;
        border-radius: 32px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        color: #CCC;
    }
    .btn-primary {
        display: flex;
        height: 60px;
        padding: 15px 20px 15px 20px;
        border-radius: 32px;
        background: #000;
        border: #000;
        color: #fff;
        float: right;
    }
    h2{
      margin-bottom: 40px;
      font-size: 24px;
    }
    form{
      h2{
        margin-bottom: 20px;
      }
      [contenteditable]:empty:before {
        display: block; /* For Firefox */
      }
      .textarea{
        min-height: 150px;
        margin-bottom: 20px;
      }
      table{
        width: 100%;
        td:nth-child(2){
          text-align: right;
        }
      }
    }
    .journal_items{
      margin-top: 80px;
      margin-bottom: 40px;
      .items{
        .item{
          background: #e8eff2 0 0 no-repeat padding-box;
          border-radius: 10px;
          padding: 20px;
          margin: 0 0 20px;
          cursor: pointer;
          table{
            margin-bottom: 20px;
            td:nth-child(1){
              padding-right: 60px;
            }
            i{
              margin-right: 10px;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  &.chat {
    .holder {
      .left {
        background: #FFE7D2;
      }
      .right{
        .content{
          max-width: 688px;
        }
      }
    }
  }
  .confettiHolder {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999;

    &.fadeOut {
      top: 100%;

      .confettiOverlay {
        opacity: 0;
      }

      .popup {
        top: -100%;
        transform: rotate(360deg);
      }
    }

    .confettiOverlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: #ffecd6;
      transition: all 0.5s;
      opacity: 1;

      &.animate {
        transition: all 0.5s;
        opacity: 0;
      }
    }

    .popup {
      background: #fff;
      padding: 30px;
      position: fixed;
      top: calc(50% - 20px);
      text-align: center;
      left: 50%;
      z-index: 1000;
      box-shadow: 0 0 22px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      max-width: 500px;
      transition: all 0.5s;
      transform: rotate(0deg) translate(-50%, -50%);

      &.animate {
        top: -50%;
        transform: rotate(360deg);
        transition: all 0.5s;
      }

      h1 {
        margin-bottom: 30px !important;
        position: relative !important;
      }

      h2 {
        font-size: 24px;
      }

      .btn {
        margin-top: 30px;
      }
    }
  }

  &.more {
    .pages {
      ul {
        padding-left: 0;

        li {
          list-style: none;
          font-size: 20px;
          margin-bottom: 15px;

          img.chevron {
            margin-right: 20px;
            width: 15px;
            position: relative;
            top: -1px;
          }
        }
      }
    }
  }

  &.page .page .right {
    padding-left: 0 !important;
  }

  &.goal,
  &.goal-edit,
  &.goals {
    .holder {
      .left {
          background-color: #FFE7D2;
        .content {
          .menu {
            /* box-shadow: -22px 0 22px #A1BDCA !important; */
          }
        }
      }
    }
  }

  .shapes.phone {
    display: none;
  }

  &.goal,
  &.goal-edit,
  &.goals {
    .shapes .shape {
      &:nth-child(1) {
        top: 722px;
        left: 204px;
        width: 192px;
        height: 213px;
        transform: matrix(-1, 0.07, -0.07, -1, 0, 0);
        background: url("../../../images/course/standard/svg/Path 3107.svg");
        mix-blend-mode: initial;
      }

      &:nth-child(2) {
        top: 560px;
        left: -8px;
        width: 271px;
        height: 274px;
        background: url("../../../images/course/standard/svg/Path 3109.svg");
        mix-blend-mode: initial;
      }

      &:nth-child(3) {
        top: 357px;
        left: -2px;
        width: 361px;
        height: 159px;
        background: transparent url("../../../images/course/standard/svg/Path 3106.svg") 0 0 no-repeat padding-box;
        mix-blend-mode: initial;
      }

      &:nth-child(4) {
        top: -24px;
        left: -80px;
        width: 415px;
        height: 382px;
        transform: matrix(-1, 0.07, -0.07, -1, 0, 0);
        background: transparent url("../../../images/course/standard/svg/Path 3108.svg") 0 0 no-repeat padding-box;
        mix-blend-mode: initial;
      }
    }
  }

  &.stress {
    .shapes .shape {
      &:nth-child(1) {
        top: 610px;
        left: 127px;
        width: 415px;
        height: 382px;
        background: transparent url("../../../images/course/standard/svg/Path 3093.svg") 0 0 no-repeat padding-box;
        mix-blend-mode: initial;
        transform: scale(0.8);
      }

      &:nth-child(2) {
        top: 560px;
        left: -8px;
        width: 192px;
        height: 213px;
        background: url("../../../images/course/standard/svg/Path 3105.svg");
        mix-blend-mode: initial;
        transform: scale(0.8);
      }

      &:nth-child(3) {
        top: 307px;
        left: -40px;
        width: 361px;
        height: 159px;
        background: transparent url("../../../images/course/standard/svg/Path 3094.svg") 0 0 no-repeat padding-box;
        mix-blend-mode: initial;
        transform: scale(0.8);
      }

      &:nth-child(4) {
        top: -24px;
        left: 180px;
        width: 271px;
        height: 274px;
        transform: matrix(-1, 0.07, -0.07, -1, 0, 0);
        background: transparent url("../../../images/course/standard/svg/Path 3104.svg") 0 0 no-repeat padding-box;
        mix-blend-mode: initial;
        transform: scale(0.8);
      }
    }
  }

  &.goals {
    .holder {
      .right {
        .content{
          max-width: 688px;
        }
        .illustration {
          img {
            width: 220px !important;
            margin-top: -90px !important;
          }
        }

        .notification {
          font: 300 20px/24px Sfizia;
          margin-top: 40px;

          img {
            margin-right: 15px;
            width: 15px;
            position: relative;
            top: -3px;
          }
        }

        .items {
          margin-left: -20px;
          display: flex;
          flex-wrap: wrap;
          .item {
            background: #FFFFFF 0 0 no-repeat padding-box;
            /* box-shadow: 8px 0 20px #d8e4e9;
            box-shadow: 8px 0 20px #A1BDCA58; */
            border-radius: 20px;
            border: 1px solid rgba(0,0,0,0.2);
            padding: 20px;
            font: 300 20px/24px Europa;
            width: calc(50% - 40px);
            /*float: left;*/
            margin: 0 20px 20px;
            position: relative;
            z-index: 1;
            text-align: center;

            &.closed {
              .text {
                opacity: 0.4;
              }

              .unlock {
                font-style: italic;
                line-height: 1.5;
                margin-top: 0px;
                margin-bottom: 10px;
                position: relative;
                font-size: 14px;
                font-family: Europa;
                opacity: 0.4;
                img {
                  margin: 0 auto;
                  display: block;
                }
              }
            }

            .bg {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0.3;
              z-index: -1;
            }

            /* &:nth-child(1n) .bg {
              background: url("../../../images/course/standard/svg/Path 3107.svg") center center no-repeat;
            }

            &:nth-child(2n) .bg {
              background: url("../../../images/course/standard/svg/Path 3109.svg") center center no-repeat;
            }

            &:nth-child(3n) .bg {
              background: transparent url("../../../images/course/standard/svg/Path 3106.svg") 0 0 no-repeat padding-box;
              opacity: 0.3;
            }

            &:nth-child(4n) .bg {
              background: transparent url("../../../images/course/standard/svg/Path 3108.svg") 0 0 no-repeat padding-box;
              opacity: 0.11;
            } */

            .btn_holder {
              text-align: center;
              margin-top: 20px;
              font: Bold 17px/20px Sfizia;
            }
          }
        }
      }
    }
  }

  &.goal-edit {
    .right {

      .content {
        padding-left: 0 !important;
        margin-left: 100px;
        #title {
          margin-bottom: 0;
        }
      }
    }
  }
  &.goal #goal_24 {
    .items {
      width: 46%;
      margin-right: 4%;
      float: left;

      .item {
        width: 100%;
        .relaxLevel{
          position: relative;
          top:-8px;
          padding-bottom: 12px;
          i{
            margin-right: 0;
          }
          span{
            font-size: 26px;
            font-weight: 600;
            margin-right: 10px;
            margin-left: 10px;
          }
        }
      }
    }

    .customRight {
      margin-top: 80px;
      margin-left: 4%;
      width: 46%;
      float: right;

      ul {
        padding-left: 1em;
      }

      h2 {
        margin-bottom: 20px;
        font-size: 24px;
      }

      .tip {
        span {
          font-weight: bold;
        }
      }
    }
  }

  &.goal {
    .right {
      .content {
        max-width: 688px !important;

        .items {
          margin-top: 80px;
          table{
            width: auto !important;
          }
          h2 {
            margin-bottom: 40px;
            font-size: 24px;
          }
          .holderItems{
            margin-left: -2%;
            .item {
              background: #e8eff2  0% 0% no-repeat padding-box;
              /*box-shadow: 8px 0 20px #A1BDCA58;*/
              border-radius: 10px;
              padding: 20px 20px 0;
              width: 46%;
              margin: 0 2% 20px;
              float: left;
              .dateTime{
                td:nth-child(1){
                  padding-right: 20px;
                }
              }
              td{
                text-align: left !important;
                padding-bottom: 20px;
                i{
                  margin-right: 10px;
                  font-size: 18px;
                }
              }

              .btn {
                background: #e8eff2;

                i {
                  color: #c5d6df;
                }
              }

              table {
                width: 100% !important;

                td:nth-child(2) {
                  text-align: right;
                }
              }
            }
          }
        }
      }
    }
  }

  &.stress {
    .borders {
      position: relative;

      .border {
        background: #A1BDCA;
        height: 1px;
        width: calc(100% + 80px);
        position: absolute;
        border: none !important;
        margin-left: -60px;

        &:nth-child(1) {
          top: 100px;
        }

        &:nth-child(2) {
          top: 200px;
        }

        &:nth-child(3) {
          top: 300px;
        }

        &:nth-child(4) {
          top: 400px;
        }

        &:nth-child(5) {
          top: 490px;
        }
      }
    }

    #holderGraph {
      height: 0;
      opacity: 0;

      &.show {
        opacity: 1;
        height: auto;
      }
    }

    #change,
    #felt,
    #options {
      opacity: 0;

      &.show {
        opacity: 1;
        transition: all 0.5s;
      }
    }

    .choose {
      h1 {
        text-align: left;
      }
      margin-top: 30px;
      /* box-shadow: 0 5px 35px #e9e9e9; */
      /*box-shadow: 0 5px 35px #A2BDCA33;*/
      border-radius: 10px;
      background: #FFFFFF 0 0 no-repeat padding-box;
      text-align: center;
      margin-bottom: 40px;
      padding: 40px 40px 0;

      img {
        width: 50px;
        margin: 0 20px 40px;
        cursor: pointer;
      }

      .btn {
        margin-bottom: 40px;
      }
    }

    .holderGraph {
      background: #FFFFFF 0 0 no-repeat padding-box;
      /* box-shadow: 0 5px 35px #e9e9e9; */
      /*box-shadow: 0 5px 35px #A2BDCA33;*/
      border-radius: 10px;
      opacity: 1;
      padding: 40px;
      position: relative;

      .gradientEffect {
        width: calc(100% - 70px);
        margin-left: 70px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.85) 100%);
        position: absolute;
        bottom: 50px;
        height: 90px;
      }
    }

    .holder {
      .left {
        background-color: #FFE7D2;

        .content {
          .menu {
            /* box-shadow: -22px 0 22px #f8d3d4;
            box-shadow: -22px 0 22px #E76D719A; */
          }
        }
      }
    }
  }

  .shapes {
    width: 100%;

    &.animate {
      .shape {
        //animation: flyInFromLeft 1s normal linear;
      }
    }

    .shape {
      animation: cloud_1 64s infinite linear;
      position: absolute;
      z-index: 0;
      opacity: 0.6;

      &:nth-child(1) {
        top: 622px;
        left: 44px;
        width: 441px;
        height: 410px;
        transform: matrix(-1, 0.07, -0.07, -1, 0, 0);
        background: url("../../../images/course/standard/svg/Path 3132.svg");
        transform: scale(0.8);
      }

      &:nth-child(2) {
        animation: cloud_2 64s infinite linear;
        top: 460px;
        left: -8px;
        width: 271px;
        height: 274px;
        background: url("../../../images/course/standard/svg/Path 3134.svg");
        transform: scale(0.8);
      }

      &:nth-child(3) {
        animation: cloud_3 64s infinite linear;
        top: 262px;
        left: -120px;
        width: 361px;
        height: 159px;
        background: transparent url("../../../images/course/standard/svg/Path 3133.svg") 0 0 no-repeat padding-box;
        transform: scale(0.8);
      }

      &:nth-child(4) {
        top: 18px;
        left: -6px;
        width: 207px;
        height: 226px;
        transform: matrix(-1, 0.07, -0.07, -1, 0, 0);
        background: transparent url("../../../images/course/standard/svg/Path 3135.svg") 0 0 no-repeat padding-box;
        transform: scale(0.8);
      }
    }
  }

  .holder {
    display: flex !important;
    width: 100%;

    .left {
      background-color: #FFE7D2;
      width: 28%;
      position: fixed;
      min-height: 100vh;
      z-index: 1;
      min-width: 250px;
      overflow: hidden;

      .content {
        position: absolute;
        height: 100%;
        right: 0;
        z-index: 1;

        .menu {
          margin-top: 95px;
          color: $title_color;
          -webkit-border-top-left-radius: 20px;
          -webkit-border-bottom-left-radius: 20px;
          -moz-border-radius-topleft: 20px;
          -moz-border-radius-bottomleft: 20px;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          padding: 20px;
          padding-left: 40px;
          /* box-shadow: -22px 0 22px #F6CDA0; */
          background-color: #ffffff;
          max-width: 250px;
          min-width: 250px;
          margin-left: auto;
          margin-right: 0;

          .logo {
            width: 127px;
            margin-bottom: 40px;
          }

          .phone {
            display: none;
          }

          .title {
            font: Bold 28px/34px Sfizia;
            line-height: 1.22;
            letter-spacing: normal;
            text-align: left;
            /*padding-left: 34px;*/
            margin-bottom: 30px;
          }

          .items {

            .menuItem {
                .Phone {
                    display: none;
                }
                .Desktop {
                    display: inline-block;
                }
                display: flex;
                width: 168px;
                height: 38px;
                padding: 6px 17px 8px 15.628px;
                justify-content: left;
                align-items: left;
                gap: 9.744px;
                flex-shrink: 0;
                border-radius: 32px;
                border: 1px solid rgba(0, 0, 0, 0.10);
                background: #FFF;
                color: #000;
                font-family: Europa;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                img {
                    width: 16px;
                }
                &:hover {
                    background: rgba(255, 231, 210, 0.50);
                }
                margin-bottom: 10px;
            }
            .active {
                background: #FFC200;
            }

            .newBadges,
            .newMessage {
              position: absolute;
              width: 35px;
              text-align: center;
              height: 35px;
              background: #253761;
              color: #fff;
              margin-top: -26px;
              right: -20px;
              border-radius: 40px;
              line-height: 1.85;
              font-weight: 300;
            }
            /*
            .newBadges{
              background: #e66d71 !important;
            }*/

            table {
              display: table !important;
              float: right;
              width: auto;
              border-collapse: collapse;
              tbody {
                width: 100%;
              }
              position: relative;
              width: 100%;
            }

            tr.spacer {
              td {
                height: 10px;
              }
            }

            tr:not(.spacer) {
              font: 300 14px/24px Europa;
              overflow: hidden;
              text-align: left;
              cursor: pointer;      

              td {
                height: 38px;
                padding: 5px 0;
                line-height: 100%;
                vertical-align: middle;
                border: 1px solid rgba(0, 0, 0, 0.10);
                border-style: solid solid solid none;                

                &:nth-child(2) {
                  /* -webkit-border-top-left-radius: 0;
                  -webkit-border-bottom-left-radius: 0;
                  -moz-border-radius-topleft: 0;
                  -moz-border-radius-bottomleft: 0;
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0; */
                  -webkit-border-top-right-radius: 32px;
                  -webkit-border-bottom-right-radius: 32px;
                  -moz-border-radius-topright: 32px;
                  -moz-border-radius-bottomright: 32px;
                  border-top-right-radius: 32px;
                  border-bottom-right-radius: 32px;
                  min-width: 110px;
                }

                &:nth-child(1) {
                    -webkit-border-top-left-radius: 32px;
                  -webkit-border-bottom-left-radius: 32px;
                  -moz-border-radius-topleft: 32px;
                  -moz-border-radius-bottomleft: 32px;
                  border-top-left-radius: 32px;
                  border-bottom-left-radius: 32px;
                  /* -webkit-border-top-right-radius: 0;
                  -webkit-border-bottom-right-radius: 0;
                  -moz-border-radius-topright: 0;
                  -moz-border-radius-bottomright: 0;
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0; */
                  width: 45px;
                  position: relative;
                  border-left-style: solid;
                }
              }

              span {
                padding-right: 20px;
              }

              &:nth-child(1) {
                img {
                  width: 16px;
                  top: 12px;
                }
              }

              &:nth-child(3) {
                img {
                  top: 12px;
                }
              }

              &:nth-child(7) {
                img {
                  top: 9px;
                }
              }

              &:nth-child(9) {
                img {
                  top: 7px;
                }
              }

              img {
                width: 16px;
                position: absolute;
                left: 20px;
                margin-right: 15px;
                top: 12px;

                &.phone {
                  display: none;
                }
              }

              &.active {
                background: $orange;
                td {
                  color: #fff;
                  font-weight: bold;
                  color: #000;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px; 
                  border: 1px solid $orange;
                }
              }
            }
          }
        }

        a {
          color: $title_color;
        }
      }
    }

    .right {
      background: #fff;
      padding-right: 40px;
      width: 100%;
      padding-bottom: 20px;
      margin-left: 28%;
      /* z-index: 9; */
      min-height: 100vh;

      .content {
        /*width:calc(100% - 80px);*/
        margin-left: 10%;
        margin-top: 90px;
        color: $title_color;
        max-width: $max-width;

        .top {
          max-width: 688px;
          margin-bottom: 20px;
          margin-top: 87px;
          width: 100%;

          td:nth-child(2) {
            text-align: right;

            img {
              width: 340px;
              margin-top: -132px;
            }
          }

          td:nth-child(1) {
            .illustration {
              width: 70%;
            }
          }
        }

        h1 {
          font: Bold 38px/47px Sfizia;
          line-height: 1.23;
          margin-bottom: 50px;
        }

        .goal {
            .btn-light {
                height: 60px;
                padding: 15px 20px 15px 20px;
                border-radius: 60px;
                border: 1px solid rgba(0, 0, 0, 0.20);
                background-color: #fff;
                color: #CCC;
                font-family: Europa;
                font-size: 17px;
                font-style: bold;
                line-height: 24px;
            }
            .btn-primary {
                height: 60px;
                padding: 15px 20px 15px 20px;
                border-radius: 60px;
                border: 1px solid #000;
                background-color: #000;
                color: #fff;
                font-family: Europa;
                font-size: 17px;
                font-style: bold;
                line-height: 24px;
            }
        }

        .stress {
          h1 {
            font: Bold 32px/39px Sfizia;
          }

          .choose.chosen {
            img {
              pointer-events: none;
              cursor: auto;

              &:not(.this) {
                filter: grayscale(100%);
                opacity: 0.5;
              }
            }
          }

          .imageScale {
            position: absolute;

            div {
              height: 100px;

              img {
                margin-top: 25px;
                width: 50px;
              }
            }
          }

          .graph {
            padding-left: 60px;
          }

          .slider {
            input {
              width: 100%;
            }

            table {
              width: 100%;
            }
          }
        }

        .chat {
          max-width: none;
          z-index: 0;
          position: relative;

          .btn {
            float: right;
          }

          .fa-chevron-left {
            padding: 20px;
            position: relative;
            margin-left: -20px;
            font-size: 24px;
          }

          &.extraInfo {
            .overlay{
              display: block;
              width: 72%;
              margin-left: 28%;
            }
            header {
              background: #fff;
              padding: 20px;
              border-bottom:0;
              z-index: 2000;
              table {
                width: 100%;

                .image {
                  width: 146px !important;
                  height: 146px !important;
                  transition: all 0.5s;
                  border-radius: 164px !important;
                  margin-top: 20px;
                }

                td:nth-child(3) {
                  vertical-align: top;
                  position: relative;

                  .aboutCoach {
                    display: block;
                    z-index: 1;
                    padding-top: 50px;
                    padding-right: 30px;
                    padding-left: 20px;
                    h2{
                      font:Bold 24px/28px Sfizia;
                      margin-bottom: 20px;
                    }
                  }

                  .coach {
                    visibility: hidden;
                    position: relative;
                    z-index: -1;
                  }
                }
              }
            }
          }

          header {
            position: fixed;
            z-index: 20;
            border-bottom: 1px solid #ccc;
            margin-bottom: 20px;
            padding-bottom: 20px;
            background: rgba(255,255,255,0.6);
            top: 0;
            padding-top: 95px;

            .aboutCoach {
              display: none;

              .fa-times {
                position: absolute;
                right: 20px;
                top: 0;
                font-size: 30px;
                z-index: 10;
              }
            }

            .coach {
              font-size: 19px;
              font-weight: bold;
            }
            margin-bottom: 20px;
            padding-bottom: 20px;
          }

          .illustration {
            position: fixed;
            right: 0;
            z-index: -1;
            top: 5%;
            opacity: 0.5;
            width: 55%;
            background-size: cover !important;
            height: 95%;
          }

          .chatContent {
            z-index: 1;
            padding: 20px 30px;

            td:nth-child(1) {
              display: none;
            }

            td:nth-child(3) {
              vertical-align: middle;
            }

            td:nth-child(2) {
              padding-right: 20px;

              .image {
                width: 64px;
                height: 64px;
                transition: all 0.5s;
                background-size: cover !important;
                border-radius: 64px;
              }
            }
          }

          .newMessage {
            margin-bottom: 20px;
          }

          .messages {
            position: relative;
            margin-top: 110px;
            z-index: 0;

            .message {
              border-radius: 10px;
              padding: 15px;
              color: #000;
              width: 85%;
              margin-bottom: 20px;
              /* box-shadow: 0 0 22px rgba(0,0,0,0.1); */

              .name {
                font-weight: bold;
              }

              .content {
                margin-left: 0;
                padding-left: 0;
                margin-top: 0;
              }

              .sendingTime {
                text-align: right;
                padding-top: 20px;
                font-size: 0.8em;
                color: #767676;
              }

              &.received {
                background: #FFECD5 0 0 no-repeat padding-box;
                border-radius: 20px 20px 20px 0;
                opacity: 1;

                .content {
                  color: #000;
                }

                .sendingTime {
                  /*text-align: left;*/
                }
              }

              &.sent {
                background: #E6EEF2 0 0 no-repeat padding-box;
                /*border: 1px solid #E6EEF2;*/
                border-radius: 20px 20px 0 20px;
                opacity: 1;
                margin-left: 15%;
              }
              .blue {
                color: #34B7F1;
              }
            }
          }

          .newMessageHolder {
            position: relative;

            .newMessage {
              margin-top: 40px;
              border-radius: 40px;
              border: 1px solid #ccc;
              padding: 7px 20px;
              z-index: 1;
              width: calc(100% - 85px);

              [contenteditable]:focus {
                outline: 0 solid transparent;
              }
            }

            .btn {
              z-index: 1;
              top: -2px;
              padding-left: 26px;
              position: absolute;
              right: 0;

              i {
                margin-left: 0;
              }
              background-color: #000;
              border: none;
            }
          }
        }

        .lessons {
            position: relative;
            .welkom {
                color: #000;
                font-family: Europa;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 40px;
            }
            .title {
                color: #000;
                -webkit-text-stroke-width: 1;
                -webkit-text-stroke-color: #000;
                font-family: Sfizia;
                font-size: 70px;
                font-style: normal;
                font-weight: 400;
                line-height: 70px;
                span {
                    display: block;
                    width: calc(100% - 220px);
                }
            }
          h2 {
            font: Bold 24px/40px Europa !important;
          }

          h1 {
            margin-bottom: 10px;
            position: absolute;
            z-index: 1;
            max-width: 688px;
            &.dummy{
              position: relative;
              opacity: 0;
            }
          }

          .intro {
            position: relative;
            z-index: 1;
            max-width: 500px;
            margin-top: 30px;
          }

          .illustration {
            position: absolute;
            top: 0;
            width: 200px;
            text-align: right;
            right: 0px;

            img {
              width: 100%;
              border-radius: 20px;
            }
          }

          .lessonsHolder {
            background: none;
            position: relative;
            border-radius: 20px;
            padding-top: 20px;
            margin-top: 20px;
            display: inline-block;
            width: 100%;
          }

          .items {
            /*max-width: 500px;*/
            margin-top: 40px;
            margin-bottom: 40px;
            position: relative;
            z-index: 1;

            .item {
              height: 60px;
              font: normal 18px/60px Europa;
              width: 100%;
              line-height: 60px;
              max-width: 688px;
              cursor: pointer;
              margin-bottom: 8px;

              &.closed {
                cursor: auto;
              }

              &.open,
              &.finished {
                opacity: 1;

                span {
                  color: $title_color;
                }
              }

              &.upcoming:not(.active) {
                span {
                  cursor: auto;
                }
              }

              img {
                margin-right: 20px;
                vertical-align: top;
              }
              .lessonTitle {
                display: inline-block;
                line-height: 24px;
                width: calc(100% - 82px);
                vertical-align: middle;
              }
              table {
                width: 100%;

                td {
                  vertical-align: middle;
                  overflow: visible;
                  text-align: left;
                }

                td:nth-child(1) {
                  width: 67px;
                  padding: 10px 20px 10px 0;
                  position: relative;

                  /* img {
                    position: absolute;
                    transform: translate(-15px, -50%);
                    margin-top: 1px;
                    top: 22px;
                  } */
                }

                span {
                  display: block;
                  color: #A1BDCA;
                  padding: 0;
                  margin-right: 30px;

                  td:nth-child(1) {
                    padding-bottom: 0;
                  }
                }

                .progress {
                  width: 10px !important;
                  height: 100%;
                  display: table-cell;
                  background: #edf2f5;
                  border-radius: 0;
                  opacity: 1;
                  position: relative;
                }
              }

              &.done {
                .progress {
                  background: $red;
                }
              }

              &.next {
                .progress {
                  background: linear-gradient(180deg, rgba(230,109,113,1) 0%, rgba(237,242,245,1) 54%, rgba(237,242,245,1) 100%);
                }
              }

              &.active {
                display: block;
                opacity: 1;
                background: $red;
                font: normal 18px/60px Europa;
                color: #000;
                border-radius: 60px;
                /* padding-left: 15px; */

                img {
                    width: 60px;
                  }

                table {
                  td {
                    &:nth-child(2) {
                      padding-bottom: 0;

                      span {
                        opacity: 1;
                        color: #000 !important;
                      }
                    }
                  }
                }
              }
            }
          }
          .items_alt {
            /*max-width: 500px;*/
            margin-top: 40px;
            margin-bottom: 40px;
            position: relative;
            z-index: 1;
            .item_alt {
              position: relative;
              width: 48%;
              min-width: 200px;
              border-radius: 20px;
              padding: 20px;
              height: 180px;
              /* box-shadow: 3px 3px 10px rgba(68, 68, 68, 0.4); */
              border-radius: 20px;
                border: 1px solid rgba(0, 0, 0, 0.20);


              display: inline-block;
              margin-bottom: 4%;
              background-color: white;
              .title {
                width: calc(100% - 160px);
                height: 100%;
                display: flex;
                align-items: center;
                font-weight: bold;
                font-size: 0.9em;
                font: normal 16px / 20px Europa;
                color: #000;
                z-index: 99;
              }
              .image {
                position: absolute;
                right: 34px;
                top: 34px;
                height: 112px;
                width: 112px;
                background-size: cover;
                z-index: 0;
              }
              .closed {
                opacity: 0.4;
              }
              .activated {
                position: absolute;
                top: 0px;
                right: 0px;
                width: 200px;

                .vink {
                  .image {
                    position: absolute;
                    right: -93px;
                    top: -16px;
                    z-index: 2;
                  }
                  .tekst {
                    position: absolute;
                    font-weight: bold;
                    font-color: $dark_bleu;
                    font-style: italic;
                    font-size: 10px;
                    background-color: $orange;
                    border-radius: 10px;
                    padding-left: 10px;
                    padding-right: 30px;
                    right: 11px;
                    top: 4px;
                    z-index: 1;
                  }
                }
                .vinkseq {
                  float: right;
                  margin-right: 20px;
                  margin-top: 100px;
                    /* .image {
                    position: absolute;
                    right: -93px;
                    top: -16px;
                    z-index: 2;
                  } */
                   img {
                    width: 60px;
                    height: 60px;
                   }
                  .tekst {
                    position: absolute;
                    font-weight: bold;
                    font-color: $dark_bleu;
                    font-style: italic;
                    font-size: 10px;
                    background-color: $orange;
                    border-radius: 10px;
                    padding-left: 10px;
                    padding-right: 30px;
                    right: 11px;
                    top: 4px;
                    z-index: 1;
                  }
                }
              }
            }
            .item_alt:hover {
              box-shadow: 3px 3px 15px rgba(124, 84, 84, 0.7);
            }
            .flip-card{

              border-radius: 20px;
              border:none;
              position: relative;
              width: 48%;
              min-width: 200px;
              border-radius: 20px;
              /* padding: 20px; */
              height: 180px;
              
              display: inline-block;
              margin-bottom: 4%;
              background-color: rgba(0,0,0,0);
              .btn{
                background: #fff !important;
                border:1px solid #fff;
                color:#fcbe59;
              }
              /* .front .btn{
                background: #fcbe59 !important;
                border:1px solid #fcbe59;
                color:#fff;
              } */
              &.front .inner {
                transform: rotateY(0deg);
                position: relative;
                border-radius: 20px;
                border:none;
                .front .text{
                  font-size: 20px;
                  font-weight: 500;
                  position: absolute;
                  top: calc(47%);    
                  left:50%;
                  transform: translate(-50%,-50%);
                }
                .back{
                  border-radius: 20px;
              border:none;
                  .btn{
                    opacity: 0;
                  }
                }
              }
              &.back .inner {
                transform: rotateY(180deg);
                .front{
                  .alignCenter{
                    opacity: 0;

                  }
                }
              }
              .inner {
                position: relative;
                height: 180px;
                width: 100%;
                text-align: center;
                transition: transform 0.6s;
                transform-style: preserve-3d;
                /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
                background-color: rgba(0,0,0,0);
                .btn{
                  margin-top: 20px;
                }
                .front, .back {
                  position: absolute;
                  width: 100%;
                  padding: 20px;
                  height: 100%;
                  -webkit-backface-visibility: hidden;
                  backface-visibility: hidden;
                  box-shadow: 8px 0px 20px #A1BDCA58;
                }
                .alignCenter{
                  width: calc(100% - 40px);
                  bottom: 20px;
                  position: absolute;
                }
                .front{
                  border-radius: 20px;
                  border:none;
                  background: #fff;
                  z-index: -1;
                  height: 180px;
                  box-shadow: 3px 3px 10px rgba(68, 68, 68, 0.4);
                }
                .back {
                  border-radius: 20px;
                  border:none;
                  background-color: #fcbe59;
                  transform: rotateY(180deg);
                  height: 180px;
                  box-shadow: 3px 3px 10px rgba(68, 68, 68, 0.4);
                  .flip-card-text {
                    max-height: 84px;
                  }
                }
              }
              

              .title {
                width: calc(100% - 140px);
                height: 100%;
                display: flex;
                align-items: center;
                font-weight: bold;
                font-size: 0.9em;
              }
              .image {
                position: absolute;
                right: 20px;
                top: 20px;
                height: 140px;
                width: 140px;
                background-size: cover;
              }
              .closed {
                opacity: 0.4;
              }
              .activated {
                position: absolute;
                top: 0px;
                right: 0px;
                width: 200px;

                .vink {
                  .image {
                    position: absolute;
                    right: -93px;
                    top: -16px;
                    z-index: 2;
                  }
                  .tekst {
                    position: absolute;
                    font-weight: bold;
                    font-color: $dark_bleu;
                    font-style: italic;
                    font-size: 10px;
                    background-color: $orange;
                    border-radius: 10px;
                    padding-left: 10px;
                    padding-right: 30px;
                    right: 11px;
                    top: 4px;
                    z-index: 1;
                  }
                }
                .vinkseq {
                  float: right;
                  margin-right: -20px;
                  margin-top: -20px;
                    /* .image {
                    position: absolute;
                    right: -93px;
                    top: -16px;
                    z-index: 2;
                  } */
                  .tekst {
                    position: absolute;
                    font-weight: bold;
                    font-color: $dark_bleu;
                    font-style: italic;
                    font-size: 10px;
                    background-color: $orange;
                    border-radius: 10px;
                    padding-left: 10px;
                    padding-right: 30px;
                    right: 11px;
                    top: 4px;
                    z-index: 1;
                  }
                }
              }
            
              
            }
          }
          .item_alt:nth-child(even) {
            float: right;
          }   
          .flip-card:nth-child(even) {
            float: right;
          }        
        }
      }
    }
  }
}
.base_theme .overlayT0 {
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  overflow: hidden;
}
@media (max-width:900px) {
  .base_theme {
    .stress {
      .choose {
        #options {
          text-align: center;

          img {
            width: 44px;
            margin: 0 9px 40px;
          }
        }
      }
    }
    .dashboard {
        .holder {
            .right {
                .content {
                    .lessons {
                        .illustration {
                            width: 150px;
                        }
                    }
                }
            }
        }
    }
  }
}
@media (max-width:$breakpoint) {
  .hideMenuMobile {
    overflow: hidden;

    .holder .left .content .menu {
      bottom: -100px !important;
      transition: all 0.5s;
    }
  }

  .base_theme .dashboard {
    &.journal{
      form{
        background: #fff;
        -webkit-border-top-left-radius: 0;
        -webkit-border-top-right-radius: 0;
        -webkit-border-bottom-right-radius: 10px;
        -webkit-border-bottom-left-radius: 10px;
        -moz-border-radius-topleft: 0;
        -moz-border-radius-topright: 0;
        -moz-border-radius-bottomright: 10px;
        -moz-border-radius-bottomleft: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 0 30px 30px;
        margin-top: -40px;
      }
      .add{
        padding: 40px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        background: #fff;
      }
      .journal_items{
        margin-bottom: 0;
        margin-top: 60px;
        .items{
          .item{
            background: #fff;

            .textarea{

            }
          }
        }
      }
    }
    .stress {
      .imageScale div{
        height: 50px !important;
        img{
          margin-top: 10px !important;
        }
      }
      .borders .border{
        &:nth-child(1){
          top:50px;
        }
        &:nth-child(2){
          top:100px;
        }
        &:nth-child(3){
          top:150px;
        }
        &:nth-child(4){
          top:200px;
        }
      }
      .choose {
        margin-bottom: 20px;
        td{
          vertical-align: middle;
        }
        #options {
          text-align: center;

          img {
            width: 38px;
            margin: 0 7px 20px;
          }
        }
        #felt{
          img{
            width: 30px;
            margin-bottom: 0;
            position: relative;
            top:-8px;
          }
        }
        #change{
          .btn{
            padding:0;
            margin-bottom: 4px;
          }
        }
      }
    }
  }

  .base_theme .dashboard {
    .confettiHolder {
      .popup {
        width: 80vw;
      }
    }

    &.lessoncontent {
      .menu {
        display: none;
      }
    }

    .titleHolder {
      td:nth-child(2) {
        display: none;
      }
    }

    &.page {
      .holder .left .content {
        .menu {
          box-shadow: 0 -22px 22px #eaeaea !important;
        }
      }

      .right {
        min-height: initial !important;
        /*background: none !important;*/
        background-color: #fff !important;
        /*box-shadow: 0 5px 35px #e9e9e9;*/
        /*box-shadow: 0 5px 35px #A2BDCA33;*/
        border-radius: 10px;

        .shapes {
          display: none;
        }

        .page {
          .right {
            background: #fff !important;
            padding-left: 20px !important;
          }
        }
      }
    }

    &.more {
      .right {
        .content {
          background: #fff;
          /* box-shadow: 0 5px 35px #e9e9e9; */
          /*box-shadow: 0 5px 35px #A2BDCA33;*/
          border-radius: 10px;
          padding: 30px 20px;

          .pages {
            padding-left: 20px;
          }
        }
      }
    }

    &.chat {
      .holder {
        .left {
          .menu {
            box-shadow: 0 -22px 22px #FFE5C8 !important;
          }
        }

        .right {
          padding: 0;
          min-height: 100vh;
          position: relative;
          z-index: 10;

          .content {
            margin-top: 0;
            background: none !important;
            .chat.extraInfo{
              .overlay{
                width: 100%;
                margin-left: 0;
              }
              header {
                table{
                  td{
                    display: block;
                    &:nth-child(2){
                      .image{
                        margin: 0 auto;
                        width: 140px !important;
                        height: 140px !important;
                      }
                    }
                  }
                }
                .aboutCoach {
                  padding-left: 0;
                  padding-right: 0;
                  h2{
                    font:500 20px/22px Sfizia !important;
                  }
                  .fa-times{
                    top: -191px;
                    right:10px;
                  }
                }
              }
            }
            .illustration {
              width: 100%;
              height: 100vh;
              top: 50%;
              left: 50%;
              right: auto;
              margin-top: -20px;
              transform: translate(-50%, -50%);
              display: none;

              img {
                height: 100%;
                opacity: 0.2;
              }
            }

            .chatContent {
              margin-bottom: 90px;
              background: none !important;
              padding: 20px 15px;

              header {
                table {
                  margin-left: 0;
                }
                background: #fff;
                padding-top: 0;
                width: 100% !important;
                left: 0;
                padding: 10px 20px;
                border-bottom: none;

                td:nth-child(1) {
                  display: table-cell;
                  cursor: pointer;
                }
              }

              .received {
                background: #fcbe59 !important;
                color: #fff;

                .content {}
              }

              .sent {
                background: #fff !important;
              }

              .newMessageHolder {
                position: fixed;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 20px;
                background-color: #fff;

                .newMessage {
                  margin-bottom: 0;
                  margin-top: 0;
                  background: #fff !important;
                  border: 1px solid #253761 !important;
                  width: calc(100% - 80px);
                }

                .btn {
                  top: 20px;
                  right: 15px;
                }
              }
            }
          }
        }
      }

      .chatContent {
        background: #fff;
        border-radius: 10px;

        header {
          td:nth-child(2) {
            vertical-align: middle !important;
          }
        }
      }
    }

    &.stress .holder .right .content .stress {
      .holderGraph {
        padding: 20px 20px 0;

        h1 {
          font: 500 22px/25px Sfizia !important;
          margin-bottom: 20px !important;
        }
      }

      .choose {
        padding: 20px 20px 0;

        h1 {
          font: 500 20px/22px Sfizia !important;
          margin-bottom: 20px !important;
        }
      }
    }

    &.lessons  {
      .holder .right .content .intro h2{
        font:500 20px/22px Sfizia !important;
      }
      .content {

        .lessons {
          #typed_1{
            max-width: 60%;
          }
          h1 {
            margin-bottom: 40px !important;
          }
          .illustration{
            width: 33% !important;
            right: 0 !important;
            margin-top: -43px;
          }

          .lessonsHolder,
          .optional-lessons {
            background: none !important;
            padding: 20px 0 20px 0;
            display: block !important;

            .items{
              padding:20px 5px 10px 45px;
              background: #fff;
              margin-left: 0;
              border-radius: 20px;
            }

            .items_alt {

              .item_alt {
                width: 100%;
              }
            }

          }

          .lessonsHolder {
            margin-bottom: 40px;
          }

          .items {
            margin-bottom: 0 !important;
            margin-left: 20px;
            margin-top: 20px !important;

            .item {
              table {
                  span {
                    padding: 0px 0;
                  }
              }
              td:nth-child(1) {
                width: 50px !important;

                img {
                  width: 50px;
                  top: 13px !important;
                }
              }

              span {
                font-size: 17px !important;
                margin-right: 0 !important;
                padding: 0px 0 !important;
              }
              margin-left: -18px;
              width: calc(100% + 18px);
              margin-bottom: 20px !important;
            }
          }
        }
      }
    }

    &.goals {
      .top {
        h1 {
          margin-bottom: 20px !important;
        }
      }

      .holder {
        .left {
          .content {
            .menu {
              box-shadow: -22px 0 22px #A1BDCA !important;
            }
          }
        }

        .right {
          background-color: #FFE7D2;
          .illustration img{
            width: 125px !important;
          }
          .items {

            .item {
              font:300 18px/24px Sfizia !important;
              width: calc(100% - 20px);
            }
          }
        }
      }
    }

    &.goal-edit {

      .holder .right {
        padding: 0 !important;
        background: #fff !important;
        /*min-height: calc(100vh - 60px) !important;*/
        min-height: 100vh;
          position: relative;
          z-index: 10;
        .content {
          margin-top: 0 !important;
          border-radius: 0 !important;
          background: #fff;
          box-shadow: none;
          z-index: 100 !important;
          padding: 30px 20px !important;
          border-radius: 10px;
          max-width: initial;
          .lessoncontent{
            margin-top: 0;
          }

        }
      }
    }
    &.goal {
      #goal_24 {
        .items,
        .customRight{
          clear: both;
          width: 100%;
          margin-top: 40px;

        }
      }
      .holderItems{
        margin-left: 0 !important;
      }
      .holder {
          h2{
            font-size: 22px !important;
            margin-bottom: 20px !important;
          }
        .left {
          .menu {
            box-shadow: -22px 0 22px #A1BDCA !important;
          }
        }

        .right {
          background: rgba(161,189,202,.41);

          .content {
            background: none;

            .goal {
              .doIt {
                padding: 30px;
                box-shadow: 0 5px 35px #e9e9e9;
                /*box-shadow: 0 5px 35px #A2BDCA33;*/
                border-radius: 10px;
                background: #fff;
              }

              .items {
                margin-top: 60px;

                .item {
                  background: #fff;
                  width: 100% !important;
                  margin-right: 0;
                  margin-left: 0;
                }
              }
            }

            h2 {
              font-size: 24px;
            }
          }
        }
      }
    }

    &.stress {
      .holder {
        .left {
          .content {
            .menu {
              box-shadow: -22px 0 22px #f8d3d4 !important;
              box-shadow: -22px 0 22px #E76D714D !important;
            }
          }
        }

        .right {
          background-color: #FFE7D2;
          .imageScale {
            div {
              img {
                width: 30px !important;
              }
            }
          }

          .graph {
            padding-left: 40px !important;
          }
        }
      }
    }

    .holder {
      .left {
        width: 0;
        min-width: 0;
        z-index: 10;
        overflow: visible;

        .shapes {
          display: none;
        }

        .content {
          .menu {
            .logo {
                display: none;
            }
            position: fixed;
            bottom: 0;
            width: 100%;
            left: 0;
            /*box-shadow: 0px -22px 22px #F6CDA0 !important;*/
            max-width: 100% !important;
            z-index: 100;
            border-radius: 0 !important;
            padding: 20px 10px !important;
            transition: all 0.5s;
            &.hide_mobile{
              bottom: -150px;
            }
            .title {
              display: none;
            }

            .items {
              width: 100%;
              margin-top: 0;
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              align-items: end;

              .menuItem {
                display: inline-block;
                text-align: center;
                background-color: #fff;
                border: none;
                text-align: center !important;
                width: auto;
                padding-left: 5px;
                padding-right: 5px;
                img {
                    display: block;
                    margin: auto;   
                    margin-bottom: 10px;
                    height: 20px;
                }
                .Desktop {
                    display: none;
                }
                .Phone {
                    display: inline-block;
                }
                span {
                    display: block;
                    font-size: 10px;
                    font-weight: normal;
                    background-color: #FFF;
                    border-radius: 20px;
                    color: #000;
                    padding: 2px 8px;
                    line-height: 12px;
                }
              }
              .menuItem:hover {
                background-color: #FFF;
              }
              .active {
                span {
                    background-color: #FFC200;
                }
              }

              table {
                width: 100% !important;
                max-width: initial;
              }

              tr {
                float: none !important;
                display: table-cell !important;
                text-align: center !important;
                padding: 0 !important;
                width: 20vw;
                font-size: 11px !important;

                &.desktop,
                &.spacer {
                  display: none !important;
                }

                td {
                  padding: 0 !important;
                  border-radius: 0 !important;

                  &:nth-child(1) {
                    display: none;
                  }

                  &:nth-child(2) {
                    width: inherit !important;
                    min-width: initial !important;
                    .newBadges,.newMessage{
                      display: none;
                    }
                  }
                }

                img {
                  margin-right: 0 !important;
                  width: 25px !important;
                }

                &:nth-child(10) {
                  img {
                    margin-top: 3px;
                  }
                }

                &:nth-child(7),
                &:nth-child(9) {
                  img {
                    margin-top: 5px;
                  }
                }

                &:nth-child(1) {
                  img {
                    margin-top: 5px;
                  }

                  span {
                    margin-top: 18px;
                  }
                }

                &:nth-child(3) span {
                  margin-top: 13px;
                }

                &:nth-child(5) span {
                  margin-top: 6px;
                }

                &:nth-child(10) span {
                  margin-top: 10px;
                }

                &:nth-child(7) span,
                &:nth-child(9) span {
                  margin-top: 10px;
                }

                &.active {
                  td {
                    background: none !important;

                    span {
                      background: $orange;
                      color: #fff;
                      border-radius: 44px;
                    }
                  }
                }

                span {
                  display: block;
                  padding: 2px 10px !important;
                }

                img {
                  position: relative !important;
                  left: auto !important;
                  top: auto !important;

                  &.desktop {
                    display: none;
                  }

                  &.phone {
                    display: inline !important;
                  }
                }
              }
            }
          }
        }
      }

      .right {
        width: 100%;
        margin-left: 0;
        padding: 20px 20px 160px;
        background-color: #FFE7D2;
        min-height: calc(100vh - 90px);

        .shapes {
          position: absolute;
          z-index: 0;
          display: none;
        }

        .notification {
          font-size: 18px !important;

          img {
            width: 10px !important;
            top: -2px !important;
            margin-right: 10px !important;
          }
        }

        .content {
          margin-left: 0 !important;
          padding-left: 0 !important;
          width: 100% !important;
          margin-top: 40px;
          position: relative;
          /* z-index: 2; */

          h1 {
            font: bold 26px/32px Sfizia !important;
            margin-bottom: 40px;
            hyphens: auto;
          }

          .top {
            margin-top: 0;

            h1 {
              margin-bottom: 0;
            }

            td {
              vertical-align: middle;
            }

            td.empty {
              display: none;
            }

            td:nth-child(2) {
              padding-left: 20px;
              width: 40%;
              text-align: center;

              &.only {
                padding-left: 0;
              }

              .only.illustration {
                max-width: 200px;
                margin: 0 auto;
              }

              img {
                position: relative;
                width: 100%;
                margin-top: 0;
              }
            }
          }
        }
      }
    }

    .shapes.phone {
      display: block;
      width: 100%;
      position: relative !important;
      opacity: 0.6;

      .shape {
        &:nth-child(1) {
          top: 200px;
          left: auto;
          right: -123px;
        }

        &:nth-child(2) {
          top: -50px;
          right: -100px;
          left: auto;
        }

        &:nth-child(3) {
          left: -73px;
        }

        &:nth-child(4) {
          top: -50px;
          left: -40px;
        }
      }
    }

    &.stress .holderGraph .gradientEffect {
      margin-left: 44px;
      width: calc(100% - 77px);
      bottom: 0;
    }
  }
}
@media (max-width:485px) {
  .base_theme .dashboard.goal .holder .right .content .goal .doIt.hasLogOption .btn{
    padding-left: 15px;
    padding-right: 15px;
    :not(.btn-primary){
      padding-right: 0 !important;
    }
  }
}
@media (max-width:430px) {
  .base_theme .dashboard.goal .holder .right .content .goal .doIt.hasLogOption .btn{
    font-size: 14px;
    line-height: 25px;
  }
}

@media (max-width:390px) {
  .base_theme .dashboard.goal .holder .right .content .goal .doIt.hasLogOption .btn{
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width:380px) {
  .base_theme {
    .stress #options {
      text-align: center;

      img {
        width: 32px !important;
        margin: 0 4px 20px !important;
      }
    }
  }
}
@media (max-width:380px) {
  .base_theme {
    .dashboard.goal .holder .right .content .goal .doIt.hasLogOption .btn{
      font-size: 11px;
      line-height: 27px;
      padding-left: 7px;
      padding-right: 7px;
    }
  }
}
/*
Wat css stylinh voor mensen met rare schermverhoudingen
  - menu naar boven
  - minder cruciale dingen verbergen lees logo en meer info
  - bij hoogte 450px
*/
@media screen and (max-height: 700px) and (min-width:$breakpoint) {
  .base_theme {
    .dashboard .holder .left .content .menu {
      margin-top: 0;
      position: relative;
      z-index: 2;
    }
  }
}
@media screen and (max-height: 610px) and (min-width:$breakpoint) {
  .base_theme {
    .leftBottom {
      .logo {
        display: none !important;
      }
    }
  }
}
@keyframes cloud_1 {
  20%,
  60% {
    margin-left: -10px;
  }

  40%,
  80% {
    margin-left: 10px;
    transform: scale(0.85);
  }
}
@keyframes cloud_2 {
  20%,
  60% {
    margin-top: 20px;
    margin-right: 10px;
    transform: scale(1.05);
  }

  40%,
  80% {
    margin-top: -20px;
    margin-left: 10px;
  }
}
@keyframes cloud_3 {
  20%,
  60% {
    margin-top: 10px;
    margin-right: 2px;
  }

  40%,
  80% {
    margin-top: -10px;
    margin-left: 2px;
    transform: scale(1.25);
  }
}
@keyframes flyInFromLeft {
  from {
    margin-left: -100%;
  }

  to {
    margin-left: 0;
  }
}
