.base_theme {
  .btn{
    line-height: 1.6;
  }
  .btn-primary.next{
    background: $black !important;
    border-color:$black !important;;
    color:#fff !important;
    font-weight: bold;
    font-size: 17px;
    height: 60px;
    line-height: 56px;
    padding-top: 0px;
    text-align: right;
    padding-left: 15px;
    padding-right: 15px;
    img {
        height: 30px;
    }
  }
  .btn-primary.prev{
    float: left;
    background: #fff !important;
    border: 1px solid rgba(0, 0, 0, 0.20);
    color: #CCC;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    height: 60px;
    line-height: 56px;
    padding-top: 0px;
    text-align: right;
    padding-left: 15px;
    padding-right: 15px;
  }
  .btn.prev {
        float: left;
        background: #fff !important;
        border: 1px solid rgba(0, 0, 0, 0.20);
        color: #CCC;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        height: 60px;
        line-height: 56px;
        padding-top: 0px;
        text-align: right;
        padding-left: 15px;
        padding-right: 15px;
  }
  .btn.red{
    background: $red !important;
    border-color:$red !important;;
    color:#fff !important;
    font-weight: bold;
    line-height: 1.6;
    &:hover{
      background: #fff !important;
      color:$red !important;
    }
  }
}
@media (max-width:$breakpoint) {
  .base_theme{
    .btn-primary.prev,
    .btn-primary.next{
      font-size: 16px;
      line-height: 1.7;
    }
  }
}
