.mediaLibrary{
  &.hide{
    display: none;
  }

  .overlay{
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    background: rgba(0,0,0,0.2);
    cursor: pointer;
    z-index: 1000000;
    display: block !important;
  }
  .popup{
    position: fixed;
    width:80%;
    background: #f5f5f5;
    padding: 20px 30px 30px;
    height: 80%;
    top: 10%;
    left: 10%;
    z-index: 1000001;
    overflow:hidden;
    .fa-times{
      cursor: pointer;
      float: right;
    }
    input[type='file']{
      display: none;
    }
    .btn{
      margin:15px 0 25px;
    }
    .items{
      overflow-x: hidden;
      overflow-y: auto;
      height: 80%;
      margin-left: -10px;
      padding-bottom: 10px;
      background: #fff;
      width: calc(100% + 20px);
      .image{
        .notAnImage{
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width:100%;
          padding: 20px;
          hyphens: auto;
          text-align: center;
          i{
            padding: 20px;
            display: block;

            font-size: 28px;
            color: #5dc2f5;
          }
          font-size: 0.6em;
          overflow: hidden;
        }
        float: left;
        margin: 10px;
        border:4px solid #f5f5f5;
        width: calc(16.6% - 20px);
        display: inline-block;
        background-size: cover !important;
        position: relative;
        cursor: pointer;
        &.big{
          width: calc(33.3% - 20px);
        }
        &:before{
          content: "";
          display: block;
          padding-top: 100%;
        }
        &:hover{
          border:4px solid #4fbdf4;
        }
        .fas.fa-trash{
          background: #dc3545;
          color:#fff;
          border-radius: 50px;
          padding: 8px 9px;
          position: absolute;
          right: 3px;
          top:3px;
          display: none;
          font-size: 12px;
        }
        &:hover{
          .fas.fa-trash{
            display: block;
          }
        }
      }
    }
  }
}
@media (max-width:900px) {
  .mediaLibrary{
    .image{
      width:calc(20% - 20px) !important;
    }
  }
}
@media (max-width:680px) {
  .mediaLibrary{
    .image{
      width:calc(25% - 20px) !important;
    }
  }
}
@media (max-width:480px) {
  .mediaLibrary{
    .image{
      width:calc(50% - 20px) !important;
    }
  }
}
