.smt-spinner-circle {
   width: 50px;
   height: 50px;
   margin: auto;
   margin-top: 50px;
   position: relative;
   /* margin: 20px; */
   border-radius: 50%;
 }
 .smt-spinner {
   height: 100%;
   width: 100%;
   border-radius: 50%;
   border-right: 2px solid rgba(255,255,255,0.6);
   border-top: 2px solid #ccc;
   border-left: 2px solid #ccc;
   border-bottom: 2px solid #ccc;
   animation: rotate--spinner 1.6s infinite;
 }
 
 @keyframes rotate--spinner {
   from {
     transform: rotate(0);
   }
   to {
     transform: rotate(360deg);
   }
 }
/*  .spinner-container {
   border: 1px solid #000;
   text-align: center;
 } */