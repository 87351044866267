@import "./styles/variabeles.scss";
@import "./styles/alert.scss";
@import "./styles/base.scss";
@import "./styles/leftMenu.scss";
@import "./styles/dashboardStudent.scss";
@import "./styles/inputs.scss";
@import "./styles/mediaLibrary.scss";
@import "./styles/lessoncontent.scss";
@import "./styles/lessonContentEdit.scss";
@import "./styles/transitions.scss";
@import "./styles/buttons.scss";
@import "./styles/dashboardStudent.scss";
@import "./styles/dashboardAdmin.scss";
@import "./styles/dashboard.scss";
@import "./styles/coach/chat.scss";
@import "./styles/coach/journal.scss";
@import "./styles/coach/lessons.scss";
@import "./styles/coach/stress.scss";
@import "./styles/coach.scss";
@import "./styles/navBar.scss";
@import "./styles/interventionSettings.scss";
@import "./styles/errorPage.scss";
@import "./styles/loadScreen.scss";
@import "./styles/crumb.scss";
@import "./styles/modal.scss";
@import "./styles/registration.scss";
@import "./styles/questionnaire.scss";
@import "./styles/uitranslations.scss";
@import "./styles/data.scss";
@import "./styles/sleepdiary.scss";
@import "./styles/progressBarModuleDesktop.scss";
@import "./styles/progressBarLessonsDesktop.scss";
@import "./styles/coachesOverview.scss";
@import "./styles/registrationTexts.scss";
@import "./styles/ssi.scss";
@import "./styles/pagination.scss";

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800&display=swap');
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");

@font-face {
    font-family: "Europa";
    src: url("./fonts/Europa-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Europa";
    src: url("./fonts/Europa-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: "Sfizia";
    src: url("./fonts/Sfizia-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Sfizia";
    src: url("./fonts/Sfizia-RegularItalic.otf") format("opentype");
    font-weight: normal;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Sfizia";
    src: url("./fonts/Sfizia-RegularItalicNo.2.otf") format("opentype");
    font-weight: bold;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Sfizia";
    src: url("./fonts/Sfizia-Bold.otf") format("opentype");
    font-weight: bold;
    font-style: normal;
  }

body{
  font-family: 'Europa';
}
/* general */
.hidden {
  display: none;
}
.alignRight {
  text-align: right;
}
.alignCenter {
  text-align: center;
}
.center{
  max-width: 1200px;
  margin: 0 auto;
}
.pointer {
  cursor: pointer;
}
.rowHover:hover,
.table-striped tbody tr:nth-of-type(odd):hover {
  /*border-top: 1px solid #ccc;*/
  /*border-bottom: 2px solid #ccc;*/
  background-color: #d9effb;
}
.whiteWrapper {
  background-color: #fff;
  min-height: calc(100vh - 80px);
  /* padding-top: 80px; */
}
.transWrapper {
  min-height: calc(100vh - 80px);
  padding-top: 80px;
}
/* color */
.color_orange {
  color: #fb9708;
}
.color_purple {
  color: #e311f6;
}
.color_green {
  color: #20d242;
}
.color_red {
  color: #f80952;
}
.color_logo_orange {
  color: #f8a161;
}
.color_logo_yellow {
  color: #f7ecd3;
}
.color_logo_blue {
  color: #2bbcd9;
}
/* navBar component */
#profile-badge {
  margin-right: 10px;
}
/* Dashboard */
.dashboard_container {
  width: 100%;
  max-width: 960px;
  padding: 20px;
}
.dashboard_container h1 {
  color: #757575;
  margin-bottom: 20px;
}
.dashboard_card {
  width: 230px;
  cursor: pointer;
  display: inline-block;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  box-shadow: 0 6px 19px rgba(0, 0, 0, 0.19);
}
.dashboard_card:hover {
  -webkit-animation-name: dashboard_card_animation; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s; /* Safari 4.0 - 8.0 */
  animation-name: dashboard_card_animation;
  animation-duration: 0.7s;
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes dashboard_card_animation {
  0% {
    margin-top: 0px;
  }
  50% {
    margin-top: -6px;
  }
  100% {
    margin-top: 0px;
  }
}

/* Standard syntax */
@keyframes dashboard_card_animation {
  0% {
    margin-top: 0px;
  }
  50% {
    margin-top: -6px;
  }
  100% {
    margin-top: 0px;
  }
}
.dashboard_card_header {
  height: 130px;
}
.dashboard_card_header i {
  font-size: 4em;
  display: block;
  margin-bottom: 20px;
}
.dashboard_card_body {
  background-color: #fafafa;
  height: 200px;
}
.dashboard_card_body h5 {
  font-size: 1.03rem;
}

/* Linker menu */
#menu_left {
  position: fixed;
  top: 80px;
  width: 450px;
  height: calc(100vh - 80px);
  margin-left: -406px;
}
#nav-icon {
  width: 24px;
  height: 31px;
  display: inline-block;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  float: right;
  margin-top: 10px;
  margin-right: 10px;
}
#nav-icon span {
  display: block;
  height: 2px;
  position: absolute;
  /*margin-bottom: 4px;*/
  width: 100%;
  background: #333;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.open #nav-icon span {
  background: #f31942;
}
#nav-icon span:nth-child(1) {
  top: 0px;
}
#nav-icon span:nth-child(2),
#nav-icon span:nth-child(3) {
  top: 8px;
}
#nav-icon span:nth-child(4) {
  top: 16px;
}
.open #nav-icon span:nth-child(1) {
  top: 15px;
  width: 0%;
  left: 50%;
}
.open #nav-icon span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.open #nav-icon span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.open #nav-icon span:nth-child(4) {
  top: 15px;
  width: 0%;
  left: 50%;
}
.menu_left_inner {
  padding-left: 70px;
  padding-top: 70px;
  width: 400px;
  font-size: 1.5rem;
}
.menu-left-link {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 10px;
}
