.base_theme.theme_2 {
  .btn-primary.next{
    font-size: 16px;
    line-height: 1.7;
  }
  .btn-primary.prev{
    font-size: 16px;
    line-height: 1.7;
  }
}
@media (max-width:$breakpoint) {
  .base_theme{
    .btn-primary.prev,
    .btn-primary.next{
      font-size: 16px;
      line-height: 1.7;
    }
  }
}
