.btn{
  height: 41px;
  cursor: pointer;
  &.delete{
    background: #dc3545;
    color:#fff;
    border-radius: 50px;
    padding: 6px 10px;
    width: 40px;
    height: 40px;
  line-height: 1.7;
  text-align: center;
    i{
      margin-left: 0;
    }
  }
  i{
    margin-left: 10px;
  }
  &.lightgrey{
    background: rgba(49, 53, 55, 0.4);
    color: #fff;
    border-radius: 50px;
    padding: 6px 9px;
    width: 40px;
    height: 40px;
    line-height: 1.5;
    text-align: center;
    i{
      margin-left: 0;
    }
  }
  &.grey{
    background: rgba(49, 53, 55, 0.6);
    color: #fff;
    border-radius: 50px;
    padding: 6px 9px;
    width: 40px;
    height: 40px;
    line-height: 1.7;
    text-align: center;
    i{
      margin-left: 0;
    }
  }
  &.yellow_light{
    background: #f6de7c;
    border-color:#f6de7c;
    color:#000;
    cursor: pointer;
    &:hover{
      background: none;
      color:#000;
      border-color:#f6de7c;
    }
  }
  &.yellow{
    background: #fcbe59;
    color: #fff;
    border-radius: 50px;
    padding: 6px 9px;
    width: 40px;
    height: 40px;
    line-height: 1.7;
    text-align: center;
    i{
      margin-left: 0;
    }
  }
  &.lightgrey{
    background: #eaeaea;
    color: #fff;
    border-radius: 50px;
    padding: 6px 9px;
    width: 40px;
    height: 40px;
    line-height: 1.7;
    text-align: center;
    i{
      margin-left: 0;
    }
  }

  &.edit{
    background: #28a745;
    color: #fff;
    border-radius: 50px;
    padding: 6px 9px;
    width: 40px;
    height: 40px;
    line-height: 1.7;
    text-align: center;
    i{
      margin-left: 0;
    }
    &.disabled{
      background: rgba(49, 53, 55, 0.6);
    }
  }
  &.roundWhite{
    background: none;
    border:1px solid rgba(49, 53, 55, 0.2);
    color: rgba(49, 53, 55, 0.4);
    border-radius: 50px;
    padding: 6px 9px;
    width: 40px;
    height: 40px;
    line-height: 1.7;
    text-align: center;
    i{
      margin-left:0;
    }
  }
  &.round.bleu,
  &.handle{
    background: #4fbdf4;
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 1.7;
    padding: 6px 9px;
    text-align: center;
    i{
      font-size: 20px;
      margin-left: 0;

    }
  }
  &.btn-success{
  border-color: #28a745;
  background: #28a745;
  color: #fff;
  &:hover{
    border-color: #28a745;
    background: #28a745;
    color: #fff;
  }
}
  &.red{
    background: #000;
    border-color: #000;
    color:#fff;
    padding-top: 8px;
    &:focus,
    &:active,
    &:hover{
      color:#000 !important;
      background: #fff;
      
    }
  }
  &.correct{
    background: rgba(49, 53, 55, 0.6);
    opacity: 0.65;
    color: #fff;
    border-radius: 50px;
    padding: 6px 9px;
    width: 40px;
    height: 40px;
    line-height: 1.7;
    text-align: center;
    i{
      margin-left: 0;
    }
    &.right{
      opacity: 1;
      background: #28a745;
    }
  }
}
