.progress-bar-desktop {
   height: 15px;
   width: 100%;
   background-color: rgba(0, 0, 0, 0.10);;
   border-radius: 10px;
   margin-bottom: 60px;
   .progress {
      height: 100%;
      background-color: #FFC200;
      border-radius: 10px;
      transition: width 1s ease;
   }
}
@media (max-width: 768px) {
   .onlyondesktop {
      display: none;
   }
}
