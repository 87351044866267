.base_theme .lessoncontent{
  &.front{
    display: flex;
    margin-top: 0;
    margin-bottom: 0;
    color:$title_color;
    overflow: hidden;
    .feedback_questionnaire{
      .the_feedback{
        padding: 20px;
        background: rgba(255,236,214,0.5);
        margin-top: 30px;
      }
      .my_score{
        font-size: 20px;
        background: rgba(255,236,214,0.7);
        padding: 20px 20px;
        font-size: 20px;
        position: relative;
      }
    }
    label{
      font: bold 18px/24px Europa;
      color: #000;
    }
    .lessonMenu{
      display: none;
    }

    .leftBottom{
      padding: 0 0 20px 40px;
      max-width: 300px;
      margin-top: 200px;
      z-index: 1;
      font: 300 16px/24px Montserrat;
      bottom: 0;
      .logo{
        margin-bottom: 0;
      }
    }
    .backToGoal{
      margin-bottom: 40px;
      height: 60px;
    padding: 15px 20px 15px 20px;
    border-radius: 60px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background-color: #fff;
    color: #CCC;
    font-family: Europa;
    font-size: 17px;
    font-style: bold;
    line-height: 24px;
    }
    &.included{
      overflow: visible;
      .holder{
        background: none;
        overflow: visible;
        .right{
          margin-left: 0;
          padding-top: 0;
          padding-left: 0 !important;
          padding-right: 0 !important;
          overflow: visible;
        }
      }
    }
    h2:not(.question),
    h3:not(.question),
    h4:not(.question){
      color: #000;
      font: Bold 20px/34px Europa;
    }
    h5:not(.question),
    h6:not(.question){
      color: #000;
      font: Bold 24px/30px Europa;
    }
    .question{
      color: #000;
        font-family: Europa;
        font-size: 18px;
        font-style: bold;
        font-weight: 700;
        line-height: 24px;
    }
    .holder{
      background: $background;
      overflow: hidden;
    }
    .shapes{
      position: absolute;
    }
    .left{
      background: $background;
      height: initial;
      position: fixed;
      .shapes{
        position: fixed;
      }
      .btn.back{
        margin-top: 16px;
        margin-left: -5px;
        font-size: 14px;
        color:$title_color;
        padding-right:0;
        padding-left:0;
        width:100%;
        &:hover{
          color:$title_color;
        }
        line-height: 31px;
        text-align: right;
        img {
            float: right;
            margin-left: 20px;
            margin-right: 20px;
        }
      }
      .content{
        max-width: 337px;
        height: auto;

        right: 0;
      }
      a{
        color:$title_color;
      }
      .menu{
        position: relative;
        z-index: 2;
        float: right;
        margin-top: 16px !important;
        color: #253761;
        -webkit-border-top-left-radius: 10px;
        -webkit-border-bottom-left-radius: 10px;
        -moz-border-radius-topleft: 10px;
        -moz-border-radius-bottomleft: 10px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 30px 20px 0 20px !important;
        /* box-shadow: -22px 0px 22px #F6CDA0; */
        background-color: #ffffff;
        max-height: calc(100vh - 70px);

overflow: auto;
        div.part:last-child{
          margin-bottom: 30px;
        }
        span{
          /* padding: 5px 20px; */
          display: block;
          &.not_done{
            opacity: 0.41;
            cursor: auto;
          }
          table{
            width:100%;
            td:nth-child(2){
              width:40px;
              text-align: right;
              img{
                width:20px;
              }
            }
          }
        }
        .parent{
          font: normal 24px/27px Europa;
          margin-bottom: 20px;
          cursor: pointer;
        }
        .part{
          padding-top: 8px;
          padding-bottom: 10px;
          cursor: pointer;
          .lessonTitle {
            color: #000;
            font: normal 14px/20px Europa;
            display: block;
            margin-left: 28px;
            margin-top: -20px;
          }
          td{
            width:calc(100% - 10px);
            padding: 0;
            border:none;
            padding: 5px 0;
            word-break: break-word;
            -webkit-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            .title{
              margin-left: 20px !important;
              padding: 5px 20px;
              border-radius: 20px;
              width:100%;
                opacity:0.5;
            }
          }
          &.closed{
            cursor: auto;
          }
          .progress{
            width:10px !important;
            height: 100%;
            display: table-cell;
            background: rgba(230, 109, 113, 0.2);
            border-radius: 0;

          }
          &.done{
            cursor: pointer;
            .progress{
              background: $orange;
            }
            .title{
              opacity: 1;
            }
          }

          &.active{

            border-radius: 10px;
            background: #FFC200;
            margin-left: -10px; 
            padding-left: 10px;
            padding-right: 10px;
/* 
            .progress{
              position: relative;
              background: $orange;
              overflow: visible;
              .circle{
                width:30px;
                height:30px;
                border-radius: 40px;
                background: $orange;
                 transform: translate(-35%, -15px);
                position: absolute;
              }
            }
            .title{
              background: $orange;
              color:#fff;
              font-weight: bold;
            } */
          }

        }
        [data-nested="2"]{
          padding-left: 20px;
        }
        [data-nested="3"]{
          padding-left: 40px;
        }
      }
    }
    .right{
      width:100%;
      background: #fff;
      padding-top: 30px;
      padding-left: 0 !important;

      z-index: 1;
      min-height: 100vh;
      .download_file{
        .btn{
          height: auto;
          text-decoration: none;
          background-color: #FFC200;
          color: #000;
          border-color: #FFC200;
        }
      }
      .tabs{
        .item{
          background: #fff;
          -webkit-border-top-left-radius: 20px;
          -moz-border-radius-topleft: 20px;
          border-top-left-radius: 20px;
          -webkit-border-top-right-radius: 20px;
          -moz-border-radius-topright: 20px;
          border-top-right-radius: 20px;
          border-bottom:none;
          &.content_visible{
            background: $title_color;
            .title{
              color:#fff;
            }
          }
        }
      }
      .tabContent{
        .item{
          background: $title_color;
          border-radius: 20px;
          -webkit-border-top-left-radius: 0;
          -moz-border-radius-topleft: 0;
          border-top-left-radius: 0;
          /*-webkit-border-top-right-radius: 0;
          -moz-border-radius-topright: 0;
          border-top-right-radius: 0;*/
          border-color:$title_color;
          .content{
            color:#fff !important;

          }
        }
      }
      .accordion{
        .items{
          border-radius: 20px;
          overflow: hidden;
          border:1px solid;
          border-color: rgba(0, 0, 0, 0.20) !important;
          background: rgba(255, 231, 210, 0.2);
          .item {
            /* background: rgba(255, 231, 210, 0.5); */

            color:#000;
            border:none;
            border-bottom:1px solid;
            border-color: rgba(0, 0, 0, 0.2) !important;
            .title{
              font-weight: 600;
              font-size: 18px;
              line-height: 28px;
              min-height: 40px;
              padding-top: 14px;
              padding-bottom: 14px;
              span {
                display: inline-block !important;
                width: calc(100% - 50px) !important;
              }
            }
            &:last-child{
              border:none;
            }
            &.content_visible{
              ul{
                margin-bottom: 1.5em;
              }
            }
          }
        }
        .items:hover {
            border: 1px solid rgba(0, 0, 0, 0.60) !important; 
        }
        .content{
          padding-left: 20px !important;
        }
        .content_visible{
          .content{
            padding-bottom: 20px !important;
          }
        }
      }
      
      .content{
        margin-top: 0 !important;
        padding-left: 0 !important;
        max-width: $max-width;
      }
      .center:not(.audio):not(.carousel){
        max-width: $max-width;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin: 0 !important;
        &.pageControls{
          /* margin-top: 80px !important;
          padding-bottom: 80px; */
          margin-top: 40px !important;
          padding-right: 0;
          .next{
            float: right;
            line-height: 56px;
          }
        }
        .openQuestion {
            display: table;
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0.20);
            border-radius: 10px;
            .icon {
                width: 45px;
                display: table-cell;
                vertical-align: top;
                padding-left: 12px;
                padding-top: 17px;
                height: 100%;
            }
            .input {
                min-height: 54px;
                display: table-cell;
                width: calc(100% - 46px);
                /* border: none !important; */
                border-left: 1px solid rgba(0, 0, 0, 0.20);
                padding-top: 15px;
                padding-bottom: 5px;
            }

        }
        .openQuestion:hover {
            border: 1px solid rgba(0, 0, 0, 0.60) !important; 
        }

      }
      .must.empty .center{
        padding-left: 20px !important;
      }
      #title{
        margin-bottom: 0;
        font: normal 40px Sfizia;
        line-height: 40px;
      }
      .component .list ul li .before,
      .component .special_table table thead{
        background: $orange;
        color:$title_color;
      }
      .component {
        border:none;
        font: normal 18px/20px Europa ;
        color: rgba(0, 0, 0, 0.8);

        .header{
          color: #270071;
          font: Bold 24px Europa;
          line-height: normal;
        }
        .cards{
            .front .front{
              //background:$background !important;
              .btn-primary{
                background: #000 !important;
                color: #fff;
                border: none;
                height: 60px;
                line-height: 47px;
              }
            }
        }
        .quote{
          border:none;
          font: normal Italic 20px/30px Europa;
          color:#24386198;
          /* background:
transparent url("/static/media/Path 3133.ecead868.svg") center center no-repeat;
          padding: 34px 0; */
          text-align: center;
          font-style: normal;
          .quoter{
            font-size: 16px;

            font-weight: normal;
          }
          &.omkaderd{
            &:before{
              content: '“';
              font: Bold 53px/7px Europa;
              letter-spacing: -1.22px;
              color:
              #A1BDCA;
              opacity: 1;
              position: relative;
              margin-left: -20px;
            }
            &:after{
              content: '“';
              font: Bold 53px/7px Europa;
              letter-spacing: -1.22px;
              color:
              #A1BDCA;
              opacity: 1;
              position: relative;
              margin-left: -20px;
              top: 27px;
            }
          }
        }
        ol {
            counter-reset:li; /* Initiate a counter */
            margin-left:14px; /* Remove the default left margin */
            padding-left:0; /* Remove the default left padding */
            li {
                position:relative; /* Create a positioning context */
                margin:0 0 6px 2em; /* Give each list item a left margin to make room for the numbers */
                padding:4px 8px; /* Add some spacing around the content */
                list-style:none; /* Disable the normal item numbering */
                /* border-top:2px solid #666; */
                /* background:#f6f6f6; */
                min-height: 40px;
                line-height: 20px;
                margin-bottom: 20px;
            }
            li:before {
                content:counter(li); /* Use the counter as content */
                counter-increment:li; /* Increment the counter by 1 */
                /* Position and style the number */
                position:absolute;
                top:-2px;
                left:-2em;
                -moz-box-sizing:border-box;
                -webkit-box-sizing:border-box;
                box-sizing:border-box;
                width:2em;
                /* Some space between the number and the content in browsers that support
                   generated content but not positioning it (Camino 2 is one example) */
                margin-right:8px;
                padding:4px;
                width: 40px;
                height: 40px;
                flex-shrink: 0;
                border-radius: 50px;
                border: 1px solid rgba(0, 0, 0, 0.20);
                background: #FFC200;
                text-align: center;
                font-family: Europa;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
            }
            li ol,
            li ul {margin-top:6px;}
            ol li:last-child {margin-bottom:0;}
        }
        ul {
            padding-left: 10px;
            list-style-type: none;
          }
        ul li {
            min-height: 40px;
            list-style: none;
            padding-left: 46px;
            background-image: url('../../../images/course/standard/svg/unordered_bullit_yellow.svg');
            background-position: 0 0;
            background-size: 1.6rem 1.6rem;
            background-repeat: no-repeat;
            line-height: 20px;
            margin-bottom: 20px;
          }
        .options {
            li {
                background-image: none;
                padding: 0px 25px 0px 15px;
                border-radius: 10px;
                border: none;
                margin-left: -10px;
                position: relative;
                .other {
                    border: none;
                    width: auto;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.20);
                    float: left;
                    left: 20px;
                    top: -50px;
                    position: relative;
                    margin-left: 30px;
                }
                .radiocheck {
                    min-height: 60px;
                    line-height: 60px;
                    border-radius: 10px;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    width: 100%;
                    display: inline-block;
                    padding: 20px;
                    padding-bottom: 16px;
                }
            }
        }
      }
    }
  }
}
@media (max-width:850px) {
  .base_theme .lessoncontent.front .left .btn.back{
    margin-left: 0;
  }
}
@media (max-width:$breakpoint) {
  .base_theme .lessoncontent{
    
    &.front{
        .question{
            color: #000;
              font-family: Europa;
              font-size: 18px;
              font-style: bold;
              font-weight: 700;
              line-height: 24px;
          }
      /*margin-top: 20px;*/
      .component{
        padding-top: 30px;
      }
      .text{
        &.afbeelding{
          .text,
          .image{
            float: none;
            width: 100%;
            margin: 0;
          }
          &.links{
            .image{
              margin-bottom: 40px;
            }
          }
          &.rechts{
            .text{
              margin-bottom: 40px;
            }
          }
        }
      }
      .btn.back{
        display: none;
      }
      .lessonMenu{
        display: block;
        position: fixed;
        overflow: auto;
        overflow-x: hidden;
        height: calc(100vh - 64px);
        width: 100%;
        top: 64px;
        left: 0;
        transition: all 0.5s;
        background: #fff;
        z-index: 10;
        padding: 30px;
        &.hide{
          left:-100%;
          transition: all 0.5s;
        }
        i{
          padding: 20px;
          cursor: pointer;
          font-size: 40px;
          margin-right: -20px;
          margin-top: -20px;
          float: right;
        }
        .lesson{
          cursor: pointer;
          margin-top: 50px;
          margin-bottom: 30px;
          font-size: 25px;
          font-weight: 500;
          line-height: 1.3;
        }
        .items{
          .item{
            display: flex;
            margin-left: -10px;
            &.active{
              font-size: 22px;
              font-weight: 600;
            }
            .progress{
              width: 70px;
              height: 100%;
              display: block;
              background: none;
              margin-right: 20px;
            }
            .title{
              padding: 20px 0px 20px 0px;
              font-size: 18px;
              width: 80%;
            }
          }
        }
      }
      header.phone{
        &.animate{
          transition: all 0.5s;
          top:-64px;
        }
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 4;
        background: #fff;
        transition: all 0.5s;
        table{
          width: 100%;
          td{
            background: #f4f4f4;
            &:nth-child(1){
              width: 83px;
              text-align: center;
              padding-right: 7px;

              i{
                cursor: pointer;
                padding: 10px 5px;
              }
            }
            &:nth-child(2){
              border-left: 2px solid #666;
              padding: 10px;
              cursor: pointer;
              position: relative;
              .title{
                position: relative;
                z-index: 10;
                display: block;
                text-align: center;
              }
            }
          }
        }
      }
      .titleHolder {
        #title{

        }
        td:nth-child(2){
          width:175px;
          img{
            top: -73px;
            margin-left: -20px;
          }
        }
      }
      .right{
        width:100%;
        margin-left:0;
        padding-bottom: 60px;
        padding-left: 20px !important;
        padding-top: 70px;
        #title{
          font: Bold 28px/32px Montserrat;
        }
        h2,h3,h4,h5,h6 {
          font: Bold 22px/26px Montserrat;
        }
        .center{
          padding:0 !important;
        }
        .cards{
          .list ul{
            display: block !important;
            li{
              &:not(:last-child){
                margin-bottom: 60px;
              }
            }
          }
        }
      }
    }
  }
  .base_theme .lessoncontent.front .right .center:not(.audio):not(.carousel).pageControls {
    margin-top: 60px !important;
  }
  
}
