.questionnaireContainer {

    .radiocheck {
        min-height: 60px;
        /* line-height: 60px; */
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        width: 100%;
        display: inline-block;
        padding: 20px;
        padding-bottom: 16px;
    }

  margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
  min-height: 100vh;
  background-color: #fff;

  font: 300 16px/24px Europa;
  .btn.prev{
    padding-left: 54px;
    padding-top: 20px;
  }
  header {
    background-color: #FFE7D2;
    /* flex: 1; */
    padding-left: 0;
    h1{
      font-size:38px;
    }
  }
  .form-label-group {
    position: relative;
    margin-bottom: 1rem;
  }
  .form-label-group > input,
  .form-label-group > label {
    padding: var(--input-padding-y) var(--input-padding-x);
  }

  .select-group{
    margin-top: 20px;
  }
  .form-label-group > label {
    position: absolute;
    top: 4px;
    left: 5px;
    display: block;
    width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
    font-size: 1em;
    pointer-events: none;
  }
  .form-label-group input::-webkit-input-placeholder {
    color: transparent;
  }
  .form-label-group input:-ms-input-placeholder {
    color: transparent;
  }
  .form-label-group input::-ms-input-placeholder {
    color: transparent;
  }
  .form-label-group input::-moz-placeholder {
    color: transparent;
  }
  .form-label-group input::placeholder {
    color: transparent;
  }
  .form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3);
  }
  .form-label-group input:not(:placeholder-shown) ~ label {
    margin-top: -20px;
    font-size: 12px;
    color: #777;
  }
  .maxWidth{
    max-width: 600px;
    margin: 0 auto;
    &.marginBottom{
      margin-bottom: 50px;
    }
  }
  h2,
  h1{
    font: Bold 26px/47px Montserrat;
    line-height: 1.23;
    margin-bottom: 20px;
  }

  .lessoncontent {
    flex: 1;
    margin-bottom: 0;
    .pageControls{
      text-align: center;
      position: initial;
      .prev{
        position: absolute;
        top: 110px;
        left: 10px;
        padding-left: 54px;
      }
    }
    form .component_holder{
      &:first-child{
        .component{
          padding-top: 0;
        }
      }
      .component{
        border: none;
        .matrix{
          th{
            background: #FFC200;
            color:#000;
            font-weight: 300;
            font-size: 16px;
          }
        }
        .center{
          padding-left: 0;
          padding-right: 0;
          .question{
            color: #000;
            font-size: 18px;
            font-style: bold;
            line-height: 20px;
          }
          .textarea {
            border: 1px #212529 solid;
          }
        }
      }
    }
  }
  header {
    width: 100%;
    background-color: #FFE7D2;
    margin: auto;
    position: relative;
    overflow: hidden;
    padding: 20px;
    text-align: center;
    .login{
      background: #253660;
      color: #fff;
      float: right;
      margin-right: 20px;
      position: relative;
      z-index: 10;
      margin-top: 9px;
    }
    .parallax{
      top: auto;
      height: 695px;
      position: absolute;
      width: 100%;
      z-index: 0;
      /*transform: translate3d(0px, 240px, 0px);*/
      bottom: 0;
      left: 0;
      .inner{

        background-repeat: no-repeat;
        background-image: url(https://caring-universities.com/wp-content/uploads/2020/04/Oranje-blobs.jpg);
        background-attachment: scroll;
        background-position: top center;
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
      }
    }
    .logo {
      float: left;
      height: 60px;
      position: relative;
    }
    .container {

      position: relative;
      z-index: 2;
      padding-top: 100px;

      .languageSwitch {
        display: inline;
        float: right;
        font-size: 18px;
        span{
          margin-right: 3px;
          margin-left: 3px;
          &:last-child{
            margin-right: 0;
          }
        }
        .active {
          color: #e76c70;
        }
      }
    }
  }

  .container {
    max-width: 1000px;
    padding: 50px;
    padding-top: 80px;
  }

  .footer {
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: #253660;
    margin: auto;
    color: #fff;
    font-size: 18px;
    a {
      color: #fff;
      text-decoration: underline;
    }
    .container {
      .title {
        font-size: 1.75rem;
        line-height: 1.5;
        margin-bottom: 20px;
        font-size: 28px;
        text-align: center;
        font-weight: bold;
        line-height: 1.5em;
      }
      .row {
        margin:0.85em 0 0  0;
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        font-weight: 400;
        .column {
          flex-grow: 1;
          width: calc(100% / 3);
          a{
            margin:0 0 0.85em 0;
            display: inline-block;
          }
          span{
            margin-right:3px;
            margin-left:3px;
            &.active{
              color: #e76c70;
            }
            &:first-child{
              margin-left: 0;
            }
            &:last-child{
              margin-right: 0;
            }
          }
        }
      }
    }
  }

 .pageControls{
    &.center{
      padding: 0;
      .next {
        height: 60px;
        padding: 15px 20px 15px 20px;
        border-radius: 32px;
        background: #000;
        border-color: #000;
        img {
            float: right;
            margin-left: 20px;
        }
        color: #FFF;
        font-size: 17px;
        font-style: bol;
        line-height: 28px;
      }
      .prev {
        height: 60px;
        padding: 15px 20px 15px 20px;
        border-radius: 60px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        img {
            margin-right: 10px;
        }
        color: #CCC;
        font-size: 17px;
        font-style: bold;
        line-height: 28px;
      }
    }
  }
  .navigation{
    margin-top: 50px;
  }
  .popup {
    position:absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    margin: auto;
    max-width: 400px;
    width: 95%;
    min-height: 200px;
    height: 300px;
    -webkit-box-shadow: 1px 6px 23px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 6px 23px -10px rgba(0,0,0,0.75);
    box-shadow: 1px 6px 23px -10px rgba(0,0,0,0.75);
    padding: 20px;
    background-color: #fff;
    z-index: 101;
  }
  .form-control{
    font-size: 1rem;
    font-family: Montserrat;
  }
  input[type="checkbox"] + label::before, input[type="radio"] + label::before{
    top:2px;
  }
  .navigation{
    text-align: center;
  }

  .bigLabel{
    label{
      font-size: 1rem;
      color:#000;
      margin-bottom: 0;
    }
  }


}
@media (max-width:768px) {
  .questionnaireContainer {

    .btn.prev{
      padding-left: 20px;
      padding-top: 15px;
    }
    .container{
      padding-bottom: 40px;
      padding-top: 40px;
      padding-left: 20px;
      padding-right: 20px;
    }
    header {
      padding-left: 0;
      h1{
        font-size:28px;
      }
      .logo {
        padding-left: 20px;
      }
    }

    .footer .container .row{
      display: block;
      .column{
        width: 100%;
        text-align: center;
        margin-bottom: 40px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    .form-label-group{
      margin-bottom: 30px;
    }
  }
}
@media (max-width:380px) {
  .questionnaireContainer {
    header {
      padding-left: 0;
      .logo {
        height: 49px;
      }
      .login{
        margin-top: 5px;
      }
    }
    .container{
      padding: 100px 40px;
    }
  }
}
