.navbar{
  z-index: 2;
  .logo{
    /*-webkit-filter: grayscale(100%);
    filter: grayscale(100%);*/
    height: 60px;
  }
  .loggedInAs {
    display: inline-block;
    color: #aaa;
    font-size: 0.7em;
    margin-left: 50px;
  }
  &.overRule{
    background: #fff;
    position: fixed;
    height: 80px;
    width:100%;
    z-index: 11;
    top: 0;
    h2{
      font-size: 20px;
      padding-left: 25px;
      color: #505050;
    }
  }
}
