.base_theme {
  #nav-icon-wrapper{

    #nav-icon{
      right: -200px !important;
      transition: all 0.5s;
    }
  }
  #menu_left{
    color:$dark_bleu;
    overflow: scroll;
    overflow-x: hidden;
    height: calc(100vh - 93px);
    z-index: 15;
    width: 85% !important;
    .menu_left_inner{
      padding-bottom: 70px;
      padding-left: 0 !important;
      width:100% !important;
      .linkbox{
        position: absolute;
        bottom: 40px;
        width: 80%;
        margin-left: 20px;
        font-weight: normal;
        font-size: 20px;
      }
      .logo{
        max-width: 175px;
        margin-bottom: 40px;
        margin-left: 20px;
      }
      .item{
        font-size: 20px;
        line-height: 1.5;
        cursor: pointer;
        padding: 10px 20px;
        border-bottom: 1px solid #eaeaea;
        &:last-child{
          border-bottom:0;
        }
        .menu-left-link{
          margin-bottom: 0;
        }
      }
    }
    .menu-left-link,
    span{
      display: inline-block;
      color:$dark_bleu;
      padding: 5px 0;
    }
    i{
      display: none;
    }

  }
}

@media (max-width:$breakpoint) {
  .menuIsVisible {
    .base_theme {
      #menu_left .menu_left_inner .item{
        font-size: 18px;
      }
      #nav-icon-wrapper{

        #nav-icon{
          right: 20px !important;
          transition: all 0.5s;
        }
      }

    }
  }
}
@media (max-width:$breakpoint) {
  .base_theme #menu_left{
    width: 90% !important;
  }
}
