.registrationContainer {
  background-color: #fff;
  margin: 0;
  min-height: 100vh;
  font: 300 16px/24px Europa;
  .btn.prev{
    padding-left: 20px;
    padding-top: 0;
    padding-bottom: 20px;
    margin-top: 20px;
    margin-left: 20px;
    line-height: 50px;
    border: 1px solid rgba(0,0,0,0.2);
    /* &:hover{
      border: 1px #efefef solid;
      box-shadow: 3px 0 20px #A1BDCA58;
    } */
     height: 50px;
  }
  header {
    padding-left: 0;
    h1{
      font-size:38px;
    }
  }
  .form-label-group {
    position: relative;
    margin-bottom: 1rem;
  }
  .form-label-group > input,
  .form-label-group > label {
    padding: var(--input-padding-y) var(--input-padding-x);
  }
  .step2 {
    .step2Image {
      display: inline-block;
      width: 45%;
      margin-bottom: 40px;
    }
    .step2Image:nth-child(2n) {
      margin-left: 10%;
    }
    header {
      background-color: #fff;
      padding-top: 0px;
      overflow: visible;
      text-align: left;
    }
    .container {
        .btn {
            background-color: #000;
            border-color: #000;
            color: #fff;
            height: 50px;
        }
    }
  }
  .step3{
    .form-label-group > label{
      left: 0;
    }
  }
  .select-group{
    margin-top: 20px;
  }
  .form-label-group > label {
    position: absolute;
    top: 4px;
    left: 5px;
    display: block;
    width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
    font-size: 1em;
    pointer-events: none;
  }
  .form-label-group input::-webkit-input-placeholder {
    color: transparent;
  }
  .form-label-group input:-ms-input-placeholder {
    color: transparent;
  }
  .form-label-group input::-ms-input-placeholder {
    color: transparent;
  }
  .form-label-group input::-moz-placeholder {
    color: transparent;
  }
  .form-label-group input::placeholder {
    color: transparent;
  }
  .form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3);
  }
  .form-label-group input:not(:placeholder-shown) ~ label {
    margin-top: -20px;
    font-size: 12px;
    color: #777;
  }
  .step2,.step3,.step4,.step5,.step6{
    .languageSwitch{
      position: absolute;
      right: 20px;
      top:15px;
    }
  }
  .maxWidth{
    max-width: 600px;
    margin: 0 auto;
    &.marginBottom{
      margin-bottom: 50px;
    }
  }
  .step4{
    padding-top: 40px;
    .container{
      margin-top: -8px;
      .next {
        border-radius: 32px;
        height: 50px;
        background-color: #000;
        color: #fff;
        line-height: 50px;
        padding-top: 0;
      }
    }
    .prevHolder{
      position: absolute;
      top: 0;
      .prev{
        margin-top:30px;
      }
    }
  }
  h2,
  h1{
    font: Bold 26px/47px Sfizia;
    line-height: 1.23;
    margin-bottom: 20px;
  }
  .universities{
    margin-top: 80px;
    span{
      font: Bold 26px/47px Sfizia;
      line-height: 1.23;
      margin-bottom: 20px;
      display: block;
    }
    p{
      font-size: 0.9em;
    }
    .logos{
      /* display: flex; */
      /* margin-left: calc(10% - 10px); */
      margin-top: 40px;
      div{
        display: inline-block;
        background-size:100%;
        background-repeat: no-repeat;
        width: 20%;
        height: 59px;
        margin: 10px;
        background-position: center;
        margin-right: 5%;
        img {
          display: inline-block;
          width: 100%;
          height: auto;
        }
      }
      div:nth-child(4) {
        margin-right: 0;
      }
      div:nth-child(8) {
        margin-right: 0;
      }
      div:nth-child(12) {
        margin-right: 0;
      }
      div:nth-child(16) {
        margin-right: 0;
      }
    }


  }
  .lessoncontent {
    margin-bottom: 0;
    .pageControls{
      text-align: center;
      position: initial;
    }
    form .component_holder{
      &:first-child{
        .component{
          padding-top: 0;
        }
      }
      .component{
        border: none;
        .matrix{
          th{
            background: $background;
            color:#000;
            font-weight: 300;
            font-size: 14px;
          }
        }

        .center{
          padding-left: 0;
          padding-right: 0;
          .question{
            font-size: 18px;
          }
        }
      }
    }
  }
  header {
    width: 100%;
    background-color: #FFE7D2;
    margin: auto;
    position: relative;
    overflow: hidden;
    padding-top:20px;
    padding-left:20px;
    text-align: center;
    .login{
      background: #000;
      color: #fff;
      float: right;
      margin-right: 20px;
      position: relative;
      z-index: 10;
      margin-top: 20px;
      border-radius: 32px;
      height: 50px;
      font-family: Europa;
      font-size: 17px;
      font-style: normal;
      font-weight: bold;
        line-height: 32px;
    }
    
    /* .languageswitchheader {
      float: right;
      margin-right: 20px;
      position: relative;
      z-index: 10;
      margin-top: 9px;

      width: auto;
      margin: auto;
      text-align: center;
      padding: 17px;
      font-size: 1.2em;
      cursor: pointer;
      .active {
        font-weight: bold;
      }
    } */
    .parallax{
      top: auto;
      height: 695px;
      position: absolute;
      width: 100%;
      z-index: 0;
      /*transform: translate3d(0px, 240px, 0px);*/
      bottom: 0;
      left: 0;
      .inner{

        background-repeat: no-repeat;
        background-image: url(https://caring-universities.com/wp-content/uploads/2020/04/Oranje-blobs.jpg);
        background-attachment: scroll;
        background-position: top center;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
      }
    }
    .logo {
      float: left;
      height: 60px;
      position: relative;
    }
    .container {

      position: relative;
      z-index: 2;
      padding-top: 100px;

      .languageSwitch {
        display: inline;
        float: right;
        font-size: 18px;
        span{
          margin-right: 3px;
          margin-left: 3px;
          &:last-child{
            margin-right: 0;
          }
        }
        .active {
          color: #e76c70;
        }
      }
    }
  }

  .container {
    max-width: 1000px;
    padding: 50px;
  }

  .footer {
    width: 100%;
    background-color: #253660;
    margin: auto;
    color: #fff;
    font-size: 18px;
    a {
      color: #fff;
      text-decoration: underline;
    }
    .container {
      .title {
        font-size: 1.75rem;
        line-height: 1.5;
        margin-bottom: 20px;
        font-size: 28px;
        text-align: center;
        font-weight: bold;
        line-height: 1.5em;
      }
      .row {
        margin:0.85em 0 0  0;
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        font-weight: 400;
        .column {
          flex-grow: 1;
          width: calc(100% / 3);
          a{
            margin:0 0 0.85em 0;
            display: inline-block;
          }
          span{
            margin-right:3px;
            margin-left:3px;
            &.active{
              color: #e76c70;
            }
            &:first-child{
              margin-left: 0;
            }
            &:last-child{
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .step1 .container{
    background-color: rgba(255, 231, 210, 0.20);
    padding-top: 20px;
    text-align: center;
    .registration_waitinglist {
      width: 100%;
      /*max-width: 500px;*/
      /* box-shadow: 8px 0 20px rgba(161,189,202,.35); */
      /*background-color: #efefef;*/
      border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.20);


      padding: 20px;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      .form-control {
        padding-left: 20px;
      }
      h2 {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
      }
    }
    .registration_boozebuster {
      width: calc(100% - 20px);
      /*max-width: 500px;*/
      box-shadow: 8px 0 20px rgba(161,189,202,.35);
      /*background-color: #efefef;*/
      padding: 20px;
      margin: auto;
      margin-top: -20px;
      margin-left: 0px;
      margin-bottom: 20px;
      text-decoration: none;
      .form-control {
        padding-left: 20px;
      }
      h2 {
        font-size: 1rem;
      }
      &:hover{
        background: #253660;
        color:#fff;
      }
    }
    .languageswitch {
      width: auto;
      margin: auto;
      text-align: center;
      padding: 20px;
      font-size: 1.2em;
      i {
        color: red;
      }
      cursor: pointer;
      .active {
        font-weight: bold;
      }
    }
    .languageswitchheader {
        margin: auto !important;
        float: none;
    }
  }
  /* .step5 .text{
    text-align: center;
  } */
  .step5 .intro {
    text-align: center;
    margin-bottom: 40px;
  }
  .step6 .__react_component_tooltip {
    width: 200px;
  }
  .coach_holder{
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* margin:40px 0 50px -20px; */
    width: 100%;
    .coach {
      /* flex-grow: 1; */
      width: 100%;
      margin: 20px 10px 20px 0;
      padding: 20px 20px;
      /* box-shadow: 8px 0 20px #A1BDCA58; */
      border-radius: 20px;
      border: 1px solid rgba(0, 0, 0, 0.20);
      box-sizing: border-box;
      font-size: 14px;
      &.active,
      &:hover{
        background: #FFE7D2;
      }
      h2{
        margin-bottom: 10px;
        font-size: 24px;
      }
      .image{
        display: inline-block;
        height: 200px;
        background-size: cover !important;
        width: 200px;
        border-radius: 1400px;
        /* margin:0 auto 20px auto; */
      }
      .info {
        display: inline-block;
        width: calc(100% - 220px);
        margin-left: 20px;
        text-align: left;
      }
      .btn_holder{
        text-align: center;
        margin-top: 30px;

      }
    }
  }
  .intervention_holder{
    display: flex;
    flex-wrap: wrap;
    margin:40px 0 60px -20px;
    .intervention{
      position: relative;
      font-size: 14px;
      flex-grow: 1;
      width: calc(33% - 40px);
      margin: 0 20px 20px;
      padding: 20px 20px;
      /* box-shadow: 8px 0 20px #A1BDCA58; */
      border-radius: 42px;
      border: 1px solid #fff;
      box-sizing: border-box;
      background: #fff;
      &:hover{
        background: #FFE7D2;
      }
      .tempNotAvailable {
        position: absolute;
        top: 20px;
        left: -20px;
        background-color: #efefef;
        padding: 5px 20px 5px 20px;
        border-radius: 3px;
        font-weight: bold;
        color: #212529;
      }

      h2{
        margin-bottom: 10px;
        font-size: 20px;
        text-align: center;
      }
      .image{
        height: 200px;
        background-size: cover !important;
        width: 200px;
        border-radius: 28px;
        margin:0 auto 20px auto;
      }
      .description {
        height: 70px;
      }
      .btn_holder{
        text-align: center;
        margin-top: 30px;
      }
    }
  }
  .thirdpartyprograms {
    width: 55%;
    margin: auto;
    margin-top: 20px;
    text-align: left;
    cursor: pointer;
    .title {
      margin-bottom: 10px;
      font-weight: 500;
      .table-cell {
        display: table-cell;
      }
      .marginLeft {
        padding-left: 10px;
      }
    }
    a {
      text-decoration: none;
      display: none;
      cursor: pointer;
      margin-bottom: 10px;
      margin-left: 23px;
      color: #000;
    }
    a:hover {
      color: #000;
    }
    .display-block {
      display: block;
    }
    .btn-primary {
        background-color: #000;
        border-color: #000;
        color: #fff;
        height: 50px;
        line-height: 40px;
    }
    .btn-primary:hover {
        color: #fff;
    }
  }
  .step{
    display: inline-block;
    cursor: pointer;
    background: #FFE7D2;
    transition: .3s;
    color: #000;
    position: relative;
    padding: 0.8rem 2rem;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin-bottom: 50px;
    b{
      font-weight: bold;
      margin-right: 10px;
    }
    &:after {
      content: '';
      position: absolute;
      right: -23px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 24px solid transparent;
      border-bottom: 24px solid transparent;
      border-left: 24px solid $background;
      clear: both;
    }
  }
  .pageControls{
    &.center{
      padding: 0;
    }
  }
  .navigation{
    margin-top: 50px;
  }
  .popup {
    position:absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    margin: auto;
    max-width: 400px;
    width: 95%;
    min-height: 200px;
    height: 300px;
    -webkit-box-shadow: 1px 6px 23px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 6px 23px -10px rgba(0,0,0,0.75);
    box-shadow: 1px 6px 23px -10px rgba(0,0,0,0.75);
    padding: 20px;
    background-color: #fff;
    z-index: 101;
  }
  .form-control{
    font-size: 1rem;
    font-family: sans-serif;
  }
  input[type="checkbox"] + label::before, input[type="radio"] + label::before{
    top:2px;
  }
  .navigation{
    text-align: center;
  }

  .step2{
    .intro{
      max-width: 680px;
      margin: 0 auto;
      h2{
        text-align: center;
        margin-bottom: 40px;
      }

    }
    .intervention-description {
      display: inline-block;
      width: 60%;
    }
    .intervention-image {
      margin-top: -40px; 
      display: inline-block;
      width: 37%;
      float: right;
      img {
        width: 100%;
      }
    }
    .student-reviews {
      display: flex;
      .student-review {
        display: flex;
        align-items: center;
        padding: 10px;
        /* width: calc(33% - 20px); */
        margin: auto;
        border: #ccc 1px solid;
        border-radius: 10px;
        height: 120px;
        overflow: hidden;
        font-style: italic;
        font-size: 15px;
        line-height: 16px;
      }
      .student-review:nth-child(n+1), .student-review:nth-child(n+2) {
        margin-left: 1%;
      }
    }
    .youtube-container {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

  }
  .informedConsent{
    .content{
      height: 400px;
      overflow: auto;
      margin-bottom: 40px;
      background: #fff;
      padding: 20px;
    }
    padding: 20px;
    background: #f5f5f5;
    margin: 0 0 40px 0;
  }

  .bigLabel{
    label{
      font-size: 1rem;
      color:#000;
      margin-bottom: 0;
    }
    .col {
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        height: 60px;
        margin-right: 4px;
        margin-top: -15px;
        margin-left: 5px;
    }
  }


  .step6 {
    .container {
      .content{
        max-width: 680px;
        margin: 0 auto;
        .intro{
          text-align: center;
        }
        .blokker {
            color: rgba(0, 0, 0, 0.8);
        }
      }
      .form-row{
        margin-bottom: 10px;
      }
      .button_holder{
        text-align: center;
        margin-top: 50px;
        .btn {
            background-color: #000;
            border-color: #000;
            color: #fff;
            height: 50px;
        }
      }
      form {

        margin-top: 40px;
        select {
            border: 1px solid rgba(0, 0, 0, 0.3) !important;
            border-radius: 10px;
            height: 60px;
        }
        input{
          width: 100%;
          border: 1px solid rgba(0, 0, 0, 0.3) !important;
        border-radius: 10px;
        height: 60px;
        /* line-height: 60px; */
        padding: 10px;
        &:not(:placeholder-shown) ~ label {
            margin-top: -35px;
        }
        }
        input[type="radio"] {
          width: auto;
        }
        
        label {
            top: 16px;
            left: 15px;
            color: rgba(0,0,0,0.2);
        }
        .col-40{
          width: 40%;
        }
        .col-20{
          width: 20%;
        }
        .col-50{
          width: 50%;
        }
        .col-100{
          width: 100%;
        }
      }
    }
  }

  .confirmation {
    width: 100%;
    .container {
      max-width: 1016px;
      padding: 50px;
    }
  }
  .extra_banners {
    display: table-row;
    width: 100%;
    .registration_heyhetisoke {
      position: relative;
      width: 49%;
      display: table-cell;
      padding: 40px;
      border-right: 1px solid #ccc;
    }
    .registration_herstel {
      position: relative;
      width: 50%;
      display: table-cell;
      vertical-align: top;
      padding: 40px;
    }
    .btn {
      position: absolute;
      bottom: 0;
      margin-left: -40px;
    }
  }

  
}

.languageswitchheader {
    float: right;
    margin: auto;
      position: relative;
      z-index: 10;
      margin-top: 20px;
      margin-right: 20px;
    width: 142px;
    height: 50px;
    flex-shrink: 0;
    
    text-align: center;
    /* 
    padding: 17px;
    font-size: 1.2em;
    cursor: pointer;
    .active {
      font-weight: bold;
    } */
    border-radius: 32px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    
    div {
        margin-top: 4px;
        display: inline-block;
        width: 65px;
        height: 40px;
        flex-shrink: 0;
        border-radius: 40px;
        
        line-height: 37px;
        
    }
    .active {
        background: #FFC200;
        border: 1px solid rgba(0, 0, 0, 0.20);
    }
  }
@media (max-width:768px) {
  
  .registrationContainer {
    .step2 {
      .intervention-description {
        display: block;
        width: 100%;
      }
      .intervention-image {
        display: block;
        width: 100%;
        margin-top: 10px;
      }
      .student-reviews {
        display: flex;
        flex-direction: column;
        .student-review {
          font-size: 12px;
          width: 100%;
          margin-bottom: 10px;
          margin-left: 0%;
        }
      }
    }
    
    .step4 .prevHolder .prev {
        margin-top: 20px;
    }
    .step6{
      .form-row{
        margin-bottom: 0 !important;
      }
      .col-50,
      .col-40,
      .col-auto{
        width: 100% !important;
      }
      .col-50 {
        margin-top: 20px;
        i {
          display: none;
        }
      }
    }
    .coach_holder{
      display: block;
      .coach{
        text-align: center;
        /* width: calc(100% - 20px) !important; */
        width: 100% !important;
        box-sizing: border-box;
        margin-bottom: 40px;
        &:last-child{
          margin-bottom: 0;
        }
        .info {
          display: block;
          width: 100%;
          margin-left: 10px;
          .btn_holder {
            width: calc(100% - 20px);
          }
          h2 {
            width: 100%;
            text-align: center;
          }
          text-align: center;
        }
        
        .image {
          margin-left: 20px;
          margin-bottom: 10px;
        }
      }
      
    }
    .btn.prev{

    }
    
    .container{
      padding-bottom: 40px;
      padding-top: 40px;
      padding-left: 20px;
      padding-right: 20px;
    }
    header {
      padding-left: 0;
      h1{
        font-size:28px;
      }
      .logo {
        padding-left: 20px;
      }
    }
    .universities .logos{
      margin: 0;
      
      div{
        display: inline-block;
        width: 45%;
        margin: 40px auto 0;
        &:nth-child(odd){
          height: auto;
          margin-right: 10%;
        }
        img {
          display: inline-block;
          width: 100%;
          height: auto;
        }
      }
    }
    .intervention_holder{
      display: block;
      margin-left: 0;
      .intervention{
        width: 100%;
        margin: 0 0 40px;
        padding: 20px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    .footer .container .row{
      display: block;
      .column{
        width: 100%;
        text-align: center;
        margin-bottom: 40px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    .form-label-group{
      margin-bottom: 30px;
    }
    .intervention_blank {
      display: none;
    }
    .extra_banners {
      .registration_heyhetisoke {
        width: 100%;
        display: inline-block;
        border-right: none;
      }
      .registration_herstel {
        width: 100%;
        display: inline-block;
      }
    }
    .thirdpartyprograms {
      width: 100%;
    }
    .step2 {
      .step2Image {
        width: 100%;
      }
      .step2Image:nth-child(2n) {
        margin-left: 0%;
      }
    }
  }
  .min_max {
    font-size: 11px;
  }

}
@media (max-width:380px) {
  .registrationContainer {
    header {
      padding-left: 0;
      .logo {
        height: 49px;
      }
      .login{
        margin-top: 5px;
      }
    }
    .container{
      padding: 50px 40px;
    }
  }
}

@media (max-width:550px) {
    .container {
        .intro {
            margin-top: 50px;
        }
    }
}